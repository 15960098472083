<template>
  <!-- <ion-list class="list"> -->
  <div>
    <ion-item>
      <ion-label>Show Reported Games</ion-label>
      <ion-toggle
        :checked="gameStore.showReportedGames"
        @ion-change="filtersChanged($event, 'showReportedGames')"
        >Show Games with Score Reported</ion-toggle
      >
    </ion-item>
    <ion-item>
      <ion-label position="stacked">Teams</ion-label>
      <team-filter />
    </ion-item>
    <ion-item v-if="filterCounts.division_id > 1">
      <ion-label position="stacked">Division</ion-label>
      <ion-button-group>
        <ion-button
          :fill="gameStore.gameFilters.division_id === null ? 'solid' : 'outline'"
          @click="filtersChanged({ value: null }, 'division_id')"
        >
          All Divisions
        </ion-button>
        <ion-button
          v-for="division in gameStore.filterOptions.division_id"
          :key="division"
          :fill="gameStore.gameFilters.division_id === division ? 'solid' : 'outline'"
          @click="filtersChanged({ value: division }, 'division_id')"
        >
          {{ division }}
        </ion-button>
      </ion-button-group>
    </ion-item>
   
    <ion-item v-if="filterCounts.park_name > 1">
      <ion-label position="stacked">Location</ion-label>
      <ion-button-group>
        <ion-button
          :fill="gameStore.gameFilters.park_name === null ? 'solid' : 'outline'"
          @click="filtersChanged({ detail: { value: null } }, 'location')"
        >
          All Locations
        </ion-button>
        <ion-button
          v-for="location in gameStore.filterOptions.park_name"
          :key="location"
          :fill="gameStore.gameFilters.park_name === location ? 'solid' : 'outline'"
          @click="filtersChanged({ detail: { value: location } }, 'location')"
        >
          {{ location }}
        </ion-button>
      </ion-button-group>
    </ion-item>
    
    <ion-item v-if="filterCounts.diamond_signifier > 1">
      <ion-label position="stacked">Diamond</ion-label>
      <ion-button-group>
        <ion-button
          :fill="gameStore.gameFilters.diamond_signifier === null ? 'solid' : 'outline'"
          @click="filtersChanged({ detail: { value: null } }, 'diamond')"
        >
          All Diamonds
        </ion-button>
        <ion-button
          v-for="diamond in gameStore.filterOptions.diamond_signifier"
          :key="diamond"
          :fill="gameStore.gameFilters.diamond_signifier === diamond ? 'solid' : 'outline'"
          @click="filtersChanged({ detail: { value: diamond } }, 'diamond')"
        >
          {{ diamond }}
        </ion-button>
      </ion-button-group>
    </ion-item>
    <ion-item v-if="filterCounts.start_time > 1">
      <ion-label position="stacked">Timeslot</ion-label>
      <ion-button-group>
        <ion-button
          :fill="gameStore.gameFilters.start_time === null ? 'solid' : 'outline'"
          @click="filtersChanged({ detail: { value: null } }, 'timeslot')"
        >
          All Timeslots
        </ion-button>
        <ion-button
          v-for="timeslot in gameStore.filterOptions.start_time"
          :key="timeslot"
          :fill="gameStore.gameFilters.start_time === timeslot ? 'solid' : 'outline'"
          @click="filtersChanged({ detail: { value: timeslot } }, 'timeslot')"
        >
          {{
            timeslot !== "TBA"
              ? format(
                  set(new Date(), {
                    hours: timeslot.split(":")[0],
                    minutes: timeslot.split(":")[1],
                  }),
                  "h:mm aa"
                )
              : "TBA"
          }}
        </ion-button>
      </ion-button-group>
    </ion-item>
    
    <ion-item v-if="filterCounts.month > 1">
      <ion-label position="stacked">Month</ion-label>
      <ion-button-group>
        <ion-button
          :fill="gameStore.gameFilters.month === null ? 'solid' : 'outline'"
          @click="filtersChanged({ detail: { value: null } }, 'month')"
        >
          All Months
        </ion-button>
        <ion-button
          v-for="month in gameStore.filterOptions.month"
          :key="month"
          :fill="gameStore.gameFilters.month === month ? 'solid' : 'outline'"
          @click="filtersChanged({ detail: { value: month } }, 'month')"
        >
          {{ month }}
        </ion-button>
      </ion-button-group>
    </ion-item>
    <ion-item v-if="filterCounts.day > 1">
      <ion-label position="stacked">Day of Week</ion-label>
      <ion-button-group>
        <ion-button
          :fill="gameStore.gameFilters.day === null ? 'solid' : 'outline'"
          @click="filtersChanged({ detail: { value: null } }, 'day')"
        >
          All Days
        </ion-button>
        <ion-button
          v-for="day in gameStore.filterOptions.day"
          :key="day"
          :fill="gameStore.gameFilters.day === day ? 'solid' : 'outline'"
          @click="filtersChanged({ detail: { value: day } }, 'day')"
        >
          {{ day }}
        </ion-button>
      </ion-button-group>
    </ion-item>
  <!-- </ion-list> -->
</div>
</template>
<script setup>
// Some of the filter reactive code is in App.vue && updateFiltersOnLoad.js
// When changing stuff here, change in those files appropriately

import { defineComponent, computed } from "vue";
import {
  // IonSelect,
  // IonSelectOption,
  IonItem,
  // IonList,
  IonLabel,
  // IonIcon,
  IonToggle,
  IonButton,
  // IonButtonGroup
} from "@ionic/vue";
// import { caretDownOutline } from "ionicons/icons";
import { useLeagueStore } from "@/stores/leagueStore";
import { useGameStore } from "@/stores/gameStore";
import { usePreferenceStore } from "@/stores/preferenceStore";
import { format, set } from "date-fns";
import { useRouter, useRoute } from "vue-router";
// import { createPopper } from "@popperjs/core";
// import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import TeamFilter from "@/components/schedule/filters/TeamFilter.vue";

defineComponent({
  name: "FiltersComponent",
});
const leagueStore = useLeagueStore();
const gameStore = useGameStore();
console.log('gameStore', gameStore.gameFilters);
const preferenceStore = usePreferenceStore();
const router = useRouter();
const route = useRoute();

function filtersChanged(ev, filterName) {
  //to stop update when leagues switch
  if (route.params.leagueSlug === leagueStore.league.slug) {
    if (filterName === "showReportedGames") {
      const changedValue = ev.detail.checked;
      gameStore.showReportedGames = changedValue;

      router.replace({
        query: {
          ...route.query,
          showReportedGames: gameStore.showReportedGames,
        },
      });
    }

    if (filterName === "division_id") {
      gameStore.gameFilters[filterName] = ev.value;
      router.replace({
        query: {
          ...route.query,
          division_id: gameStore.gameFilters.division_id,
        },
      });
    }

    if (filterName === "location") {
      const changedValue = ev.detail.value;
      if (changedValue) {
        gameStore.gameFilters.park_name = changedValue;
      } else {
        gameStore.gameFilters.park_name = null;
      }

      router.replace({
        query: {
          ...route.query,
          park_name: changedValue,
        },
      });
    }
    if (filterName === "diamond") {
      const changedValue = ev.detail.value;
      if (changedValue) {
        gameStore.gameFilters.diamond_signifier = changedValue;
      } else {
        gameStore.gameFilters.diamond_signifier = null;
      }

      router.replace({
        query: {
          ...route.query,
          diamond_signifier: changedValue,
        },
      });
    }

    if (filterName === "timeslot") {
      const changedValue = ev.detail.value;
      if (changedValue) {
        gameStore.gameFilters.start_time = changedValue;
      } else {
        gameStore.gameFilters.start_time = null;
      }

      router.replace({
        query: {
          ...route.query,
          timeslot: changedValue,
        },
      });
    }

    if (filterName === "month") {
      const changedValue = ev.detail.value;
      if (changedValue) {
        gameStore.currentShownDate = "all";
        gameStore.gameFilters.month = changedValue;
      } else {
        gameStore.gameFilters.month = null;
      }

      router.replace({
        query: {
          ...route.query,
          month: changedValue,
        },
      });
    }
    if (filterName === "day") {
      const changedValue = ev.detail.value;
      if (changedValue) {
        gameStore.currentShownDate = "all";
        gameStore.gameFilters.day = changedValue;
      } else {
        gameStore.gameFilters.day = null;
      }

      router.replace({
        query: {
          ...route.query,
          day: changedValue,
        },
      });
    }

    preferenceStore.setFilters({
      ...gameStore.gameFilters,
      showReportedGames: gameStore.showReportedGames,
    });
  }
}


const filterCounts = computed(() => ({
  division_id: gameStore.filterOptions.division_id?.length || 0,
  park_name: gameStore.filterOptions.park_name?.length || 0,
  diamond_signifier: gameStore.filterOptions.diamond_signifier?.length || 0,
  start_time: gameStore.filterOptions.start_time?.length || 0,
  month: gameStore.filterOptions.month?.length || 0,
  day: gameStore.filterOptions.day?.length || 0,
}));

// function withPopper(dropdownList, component) {
//   dropdownList.style.width = "20rem";
//   const popper = createPopper(component.$refs.toggle, dropdownList, {
//     modifiers: [
//       {
//         name: "offset",
//         options: {
//           offset: [0, -1],
//         },
//       },
//       {
//         name: "toggleClass",
//         enabled: true,
//         phase: "write",
//         fn({ state }) {
//           component.$el.classList.toggle("drop-up", state.placement === "top");
//         },
//       },
//     ],
//   });

//   return () => popper.destroy();
// }
</script>
<style scoped>
.list ion-item {
  --padding-start: 0px;
}

.v-select {
  --vs-controls-color: var(--ion-color-step-300);
  --vs-border-width: 0;

  --vs-dropdown-bg: var(--ion-background-color);
  --vs-dropdown-option-color: var(--ion-text-color);

  --vs-selected-bg: var(--ion-color-light);
  --vs-selected-color: var(--ion-text-color);

  --vs-search-input-color: var(--ion-color-step-300);

  --vs-dropdown-option--active-bg: #664cc3;
  --vs-dropdown-option--active-color: #eeeeee;
  --vs-dropdown-min-width: 1rem;
  --vs-dropdown-option--deselect-color: red;

  --vs-disabled-bg: var(--ion-color-light);
  --vs-disabled-color: var(--ion-color-light);
  --vs-disabled-controls-color: var(--ion-color-light);
}
</style>
