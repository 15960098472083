<template>
  <base-modal modal-title="Edit Event Locations">
    <!-- <template #headerStart>
      <ion-button @click="close"> Close </ion-button>
    </template> -->

    <ion-card>
      <ion-list>
        <ion-item>
          <p>
            Merge multiple locations by selecting a set of locations and
            renaming them.
          </p>
        </ion-item>
        <ion-item>
          <p>
            A single location can be renamed by selecting only one location and
            renaming it.
          </p>
        </ion-item>
      </ion-list>
    </ion-card>

    <ion-segment :scrollable="true" v-model="segment" mode="md">
      <ion-segment-button value="locations">
        <ion-label>Locations</ion-label>
      </ion-segment-button>
      <ion-segment-button value="diamonds">
        <ion-label>Diamonds</ion-label>
      </ion-segment-button>
    </ion-segment>

    <ion-card v-if="segment === 'locations'">
      <ion-card-content>
        <ion-grid>
          <ion-row>
            <ion-col
              class="ion-align-self-center label-left-col"
              size-md="5"
              size="12"
              ><ion-label>Select Locations to Merge</ion-label></ion-col
            >
            <ion-col
              ><ion-select
                style="
                  border-bottom: 3px solid var(--ion-color-medium);
                  text-align: center;
                "
                :multiple="true"
                v-model="selectedParks"
                @ion-change="parksChanged()"
              >
                <ion-select-option
                  v-for="park in parks"
                  :key="park"
                  :value="park"
                  >{{ park }}</ion-select-option
                >
              </ion-select></ion-col
            >
          </ion-row>
          <ion-row style="margin-top: 2rem">
            <ion-col
              class="ion-align-self-center label-left-col"
              size-md="5"
              size="12"
              ><ion-label>Name of Merged Location</ion-label></ion-col
            >
            <ion-col
              ><ion-input
                style="
                  border-bottom: 3px solid var(--ion-color-medium);
                  text-align: center;
                "
                v-model="mergeParkName"
              >
              </ion-input
            ></ion-col>
          </ion-row>
        </ion-grid>
      </ion-card-content>
    </ion-card>

    <ion-card v-if="segment === 'diamonds'">
      <ion-card-content>
        <ion-grid>
          <ion-row>
            <ion-col
              class="ion-align-self-center label-left-col"
              size-md="5"
              size="12"
              ><ion-label>Select Diamonds to Merge</ion-label></ion-col
            >
            <ion-col
              ><ion-select
                style="
                  border-bottom: 3px solid var(--ion-color-medium);
                  text-align: center;
                "
                :multiple="true"
                v-model="selectedDiamonds"
                @ion-change="diamondsChanged"
              >
                <ion-select-option
                  v-for="diamond in diamonds"
                  :key="diamond"
                  :value="diamond"
                  >{{ diamond }}</ion-select-option
                >
              </ion-select></ion-col
            >
          </ion-row>
          <ion-row style="margin-top: 2rem">
            <ion-col
              class="ion-align-self-center label-left-col"
              size-md="5"
              size="12"
              ><ion-label>Name of Merged Diamond</ion-label></ion-col
            >
            <ion-col
              ><ion-input
                style="
                  border-bottom: 3px solid var(--ion-color-medium);
                  text-align: center;
                "
                v-model="mergeDiamondName"
              >
              </ion-input
            ></ion-col>
          </ion-row>
        </ion-grid>
      </ion-card-content>
    </ion-card>

    <template #footer>
      <ion-button
        expand="full"
        @click="mergeParks"
        v-if="segment === 'locations'"
        >Merge Locations</ion-button
      >
      <ion-button
        expand="full"
        @click="mergeDiamonds"
        v-if="segment === 'diamonds'"
        >Merge Diamonds</ion-button
      >
    </template>
  </base-modal>
</template>
<script setup>
import BaseModal from "@/components/layouts/BaseModal.vue";
import {
  IonButton,
  modalController,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonSelect,
  IonSelectOption,
  IonInput,
  IonCard,
  IonCardContent,
  IonItem,
  IonList,
} from "@ionic/vue";
import { defineComponent, ref } from "vue";
import {
  showToast,
  //   showLoading,
} from "@/utils/useIonicComponents.js";
import { api } from "@/utils/axios";
import { useRouter, useRoute } from "vue-router";
import { useLeagueStore } from "@/stores/leagueStore";

defineComponent({
  name: "EditLocationsModal",
});
const segment = ref("locations");
const parks = ref();
const diamonds = ref();
const selectedParks = ref();
const mergeParkName = ref();
const selectedDiamonds = ref();
const mergeDiamondName = ref();

const router = useRouter();
const route = useRoute();
const leagueStore = useLeagueStore();

router.replace({
  query: {
    ...route.query,
    open_modal: "EditLocations",
  },
});

getLocations();

async function getLocations() {
  const response = await api.post("/api/event-locations", {
    event_id: leagueStore.league.id,
  });
  parks.value = response.data.parks;
  diamonds.value = response.data.diamonds;
}

function parksChanged() {
  mergeParkName.value = selectedParks.value[0];
}
function diamondsChanged() {
  mergeDiamondName.value = selectedDiamonds.value[0];
}

async function mergeParks() {
  if (mergeParkName.value && selectedParks.value) {
    const response = await api.post("/api/merge-parks", {
      event_id: leagueStore.league.id,
      parks: selectedParks.value,
      mergeParkName: mergeParkName.value,
    });
    if (response.status === 200) {
      showToast("Merge Successfull", "success", 2000);
      leagueStore.reloadEverything(leagueStore.league.slug);
      close();
    } else {
      showToast("Merge Failed, Please try again", "danger", 4000);
    }
  } else {
    showToast("Please fill both the fields", "danger", 4000);
  }
}

async function mergeDiamonds() {
  if (selectedDiamonds.value && mergeDiamondName.value) {
    const response = await api.post("/api/merge-diamonds", {
      event_id: leagueStore.league.id,
      diamonds: selectedDiamonds.value,
      mergeDiamondName: mergeDiamondName.value,
    });
    if (response.status === 200) {
      showToast("Merge Successfull", "success", 2000);
      leagueStore.reloadEverything(leagueStore.league.slug);
      close();
    } else {
      showToast("Merge Failed, Please try again", "danger", 4000);
    }
  } else {
    showToast("Please fill both the fields", "danger", 4000);
  }
}

async function close() {
  router.replace({
    query: {
      ...route.query,
      open_modal: "",
    },
  });
  await modalController.dismiss();
}
</script>
