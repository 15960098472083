<template>
  <base-modal :modal-title="`Edit Appearance of ${capitalizedType}`">
    <!-- <template #headerStart>
      <ion-button @click="close"> Close </ion-button>
    </template> -->
    <appearance-component
      @primaryColorChanged="primaryColorChanged"
      @secondaryColorChanged="secondaryColorChanged"
      @logoFilePicked="logoFilePicked"
      @backgroundFilePicked="backgroundFilePicked"
      @resetLogo="logoFile = null"
      @resetBackground="backgroundFile = null"
    />
    <!-- <ion-item>
      <ion-select @ionChange="toggleTheme" :value="currentTheme">
        <ion-select-option value="light">Light</ion-select-option>
        <ion-select-option value="dark">Dark</ion-select-option>
        <ion-select-option value="auto">Auto</ion-select-option>
      </ion-select>
    </ion-item> -->
    <template #footer>
      <ion-button expand="full" @click="saveAppearance">Save</ion-button>
    </template>
  </base-modal>
</template>

<script setup>
import { defineComponent, ref, computed } from "vue";
import BaseModal from "@/components/layouts/BaseModal.vue";
import AppearanceComponent from "@/components/info/AppearanceComponent.vue";
import { useLeagueStore } from "@/stores/leagueStore";
import { IonButton, modalController } from "@ionic/vue";
import { showToast, showLoading } from "@/utils/useIonicComponents.js";
import { api } from "@/utils/axios";
import tinycolor from "tinycolor2";
import { useRouter, useRoute } from "vue-router";



defineComponent({
  name: "AppearanceModal",
});
const leagueStore = useLeagueStore();
const router = useRouter();
const route = useRoute();
router.replace({
  query: {
    ...route.query,
    open_modal: "EditAppearance",
  },
});
const logoFile = ref();
const backgroundFile = ref();
const primaryColor = ref(leagueStore.league.primary_color || "#7d38f0");
const secondaryColor = ref(leagueStore.league.secondary_color || "#3dc2ff");
const currentTheme = ref(leagueStore.league.theme || 'auto');
async function saveAppearance() {
  const loading = await showLoading("Saving. Please Wait...");
  try {
    let logoUrl, backgroundUrl = null;
    if (logoFile.value) {
      logoUrl = await uploadFile(logoFile.value, "logo");
    }
    if (backgroundFile.value) {
      backgroundUrl = await uploadFile(backgroundFile.value, "background");
    }
    // Include the currentTheme in the API call
    await api.post("/api/save-league-appearance", {
      league_id: leagueStore.league.id,
      primaryColor: primaryColor.value,
      secondaryColor: secondaryColor.value,
      logoUrl,
      backgroundUrl,
      theme: currentTheme.value, // Save the current theme preference
    });
    loading.dismiss();
    // Optionally, update the local state if necessary
    leagueStore.league.theme = currentTheme.value;
    await leagueStore.reloadEverything(leagueStore.league.slug);
    showToast("Save Success.", "success", 6000);
    modalController.dismiss();
  } catch (err) {
    console.log(err);
    loading.dismiss();
    showToast("Upload Failed. If this persists, please contact us.", "danger", 6000);
  }
}
const capitalizedType = computed(() => {
  return leagueStore.league.type.charAt(0).toUpperCase() + leagueStore.league.type.slice(1);
});
function primaryColorChanged(color) {
  primaryColor.value = color;
  const c = tinycolor(color);
  if (c.isLight()) {
    showToast(
      "Lighter colors will be made darker for readability",
      "warning",
      6000
    );
    primaryColor.value = c.darken(30).desaturate(10).toString();
  }
  window.document.body.style.setProperty(
    "--ion-color-primary",
    primaryColor.value
  );
  window.document.body.style.setProperty(
    "--ion-color-primary-tint",
    c.lighten().toString()
  );
  window.document.body.style.setProperty(
    "--ion-color-primary-shade",
    c.darken().toString()
  );
}
function secondaryColorChanged(color) {
  secondaryColor.value = color;
  const c = tinycolor(color);
  if (c.isLight()) {
    showToast(
      "Lighter colors will be made darker for readability",
      "warning",
      6000
    );
    secondaryColor.value = c.darken(30).desaturate(10).toString();
  }
  window.document.body.style.setProperty(
    "--ion-color-secondary",
    secondaryColor.value
  );
  window.document.body.style.setProperty(
    "--ion-color-secondary-tint",
    c.lighten().toString()
  );
  window.document.body.style.setProperty(
    "--ion-color-secondary-shade",
    c.darken().toString()
  );
}
function logoFilePicked(file) {
  logoFile.value = file;
}
function backgroundFilePicked(file) {
  backgroundFile.value = file;
}

async function uploadFile(file, type) {
  const access_key = "6720c8ce-1e73-4f38-be0c52815ece-593f-4260";
  const base_url = "https://la.storage.bunnycdn.com";
  const storageName = "blacktiecollab-la";
  const path = "/slopitchcentral/appearance";
  const file_name = `${leagueStore.league.id}-${type}-${file.name}`;
  const cdn_url = "cdn.blacktiecollab.com";

  await fetch(`${base_url}/${storageName}${path}/${file_name}`, {
    method: "PUT",
    headers: {
      AccessKey: `${access_key}`,
    },
    body: file,
  });
  return `https://${cdn_url}${path}/${file_name}`;
}

// async function close() {
//   router.replace({
//     query: {
//       ...route.query,
//       open_modal: "",
//     },
//   });
//   await modalController.dismiss();
// }
</script>
