<template>
  <base-modal :modal-title="`Edit ${capitalizedType} Details`" @close="close">
    <!-- <template #headerStart>
      <ion-button @click="close"> Close </ion-button>
    </template> -->
    <ion-card style="min-width: 20rem; padding: 0; margin: 0">
      <ion-card-content>
        <ion-list lines="full">
          <ion-item>
            <ion-label position="stacked">Name</ion-label>
            <ion-input v-model="league.name" placeholder="Enter league name"></ion-input>
          </ion-item>
          <ion-item>
            <ion-label position="stacked">Shortcode</ion-label>
            <ion-input v-model="league.shortcode" placeholder="Enter shortcode"></ion-input>
          </ion-item>
          <ion-item>
            <ion-label position="stacked">Points for Win</ion-label>
            <ion-input type="number" v-model="league.points_for_win" placeholder="Enter points for win"></ion-input>
          </ion-item>
          <ion-item>
            <ion-label position="stacked">Points for Loss</ion-label>
            <ion-input type="number" v-model="league.points_for_loss" placeholder="Enter points for loss"></ion-input>
          </ion-item>
          <ion-item>
            <ion-label position="stacked">Points for Tie</ion-label>
            <ion-input type="number" v-model="league.points_for_tie" placeholder="Enter points for tie"></ion-input>
          </ion-item>
          <ion-item>
            <ion-label position="stacked">Max Game Run Diff</ion-label>
            <ion-input type="number" v-model="league.max_game_run_diff" placeholder="Enter points for max game run diff"></ion-input>
          </ion-item>
          <ion-item>
            <ion-label position="stacked">Show Away / Home</ion-label>
            <ion-select v-model="league.show_home_away" placeholder="Select option">
              <ion-select-option value="null">No</ion-select-option>
              <ion-select-option value="1">Yes</ion-select-option>
            </ion-select>
          </ion-item>
          <ion-item>
            <ion-label position="stacked">Deposit Owed</ion-label>
            <ion-input type="number" v-model="league.deposit_amount" placeholder="Enter deposit amount"></ion-input>
          </ion-item>
        </ion-list>
      </ion-card-content>
    </ion-card>
    <template #footer>
      <ion-button expand="full" @click="saveleague"
        >Save {{ capitalizedType }} Details</ion-button
      >
    </template>
  </base-modal>
</template>
<script setup>
import BaseModal from "@/components/layouts/BaseModal.vue";
import {
  // IonCol,
  // IonRow,
  // IonGrid,
  IonLabel,
  IonCard,
  IonCardContent,
  IonInput,
  IonButton,
  modalController,
  IonSelect,
  IonSelectOption,
} from "@ionic/vue";
import { defineComponent, ref, computed } from "vue";
import useVuelidate from "@vuelidate/core";
import { required, numeric } from "@vuelidate/validators";
import { showToast, showLoading } from "@/utils/useIonicComponents.js";
import { api } from "@/utils/axios";
import { useLeagueStore } from "@/stores/leagueStore";
import { useRouter, useRoute } from "vue-router";

defineComponent({
  name: "InfoModal",
});
const leagueStore = useLeagueStore();
const league = ref({ ...leagueStore.league });

const router = useRouter();
const route = useRoute();
router.replace({
  query: {
    ...route.query,
    open_modal: "EditInfo",
  },
});

const rules = {
  name: { required },
  shortcode: { required },
  points_for_win: { required, numeric },
  points_for_loss: { required, numeric },
  points_for_tie: { required, numeric },
  max_game_run_diff: { required, numeric },
  show_home_away: { numeric },
  deposit_amount: { numeric },
};

const v$ = useVuelidate(rules, league);

async function saveleague() {
  const isFormValid = await v$.value.$validate();
  if (isFormValid) {
    await saveleagueRequest();
  } else {
    showToast("Invalid Inputs!", "danger", 6000);
  }
}

async function saveleagueRequest() {
  const loading = await showLoading("Saving leagues. Please Wait...");
  const result = await api.post("/api/save-league-details", {
    event: leagueStore.league.id,
    league: league.value.name,
    convenor_id: league.value.convenor_id,
    name: league.value.name,
    shortcode: league.value.shortcode,
    points_for_win: league.value.points_for_win,
    points_for_loss: league.value.points_for_loss,
    points_for_tie: league.value.points_for_tie,
    max_game_run_diff: league.value.max_game_run_diff,
    show_home_away: league.value.show_home_away,
    deposit_amount: league.value.deposit_amount,
  });
  loading.dismiss();

  if (result.data.type === "success") {
    await leagueStore.reloadEverything(leagueStore.league.slug);
    await close();
    showToast("League Details successfully Saved.", "success", 6000);
  } else {
    showToast(
      "Something went wrong. If this persists, please contact us.",
      "danger",
      6000
    );
  }
}

const capitalizedType = computed(() => {
  return league.value.type.charAt(0).toUpperCase() + league.value.type.slice(1);
});

async function close() {
  router.replace({
    query: {
      ...route.query,
      open_modal: "",
    },
  });
  await modalController.dismiss();
}
</script>
<style scoped>
.details-input {
  border-bottom: 3px solid var(--ion-color-medium);
  text-align: center;
}
</style>
