<template>
    <page-wrapper>
      <sitewide-notice />
      <ion-header>
        <ion-toolbar class="header-toolbar">
          <ion-buttons slot="start">
            <ion-menu-button color="dark"></ion-menu-button>
          </ion-buttons>
          <ion-title>All Guides</ion-title>
        </ion-toolbar>
      </ion-header>
      <card-wrapper
        cardTitle="Ultimate Guide to Running a Slo-Pitch Softball Tournament"
        id="featured"
        cardSubtitle="Check out our ultimate guide to improve your tournament experience."
      >
        <ion-row class="ion-no-padding">
          <ion-col size-sm="6" size="12">
            <ion-card class="ion-no-padding ion-no-margin">
              <ion-card-header>
                <ion-card-title style="font-size: 22px; text-transform: uppercase; text-align: center;">
                  Ultimate Guide to Running a Slo-Pitch Softball Tournament
                </ion-card-title>
              </ion-card-header>
              <ion-card-content>
                <ion-button
                  size="small"
                  expand="full"
                  target="_blank"
                  :href="`/guides/running-a-slopitch-tournament-ultimate-guide`"
                >
                  View Now
                </ion-button>
              </ion-card-content>
            </ion-card>
          </ion-col>
        </ion-row>
      </card-wrapper>
      <footer-component></footer-component>
    </page-wrapper>
</template>
  
<script setup>
import { defineComponent } from "vue";
import {
    IonRow,
    IonCol,
    IonTitle,
    IonToolbar,
    IonHeader,
    IonButtons,
    IonMenuButton,
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonCardTitle,
    IonButton,
    // onIonViewWillEnter,
} from "@ionic/vue";
import { useHead } from "@unhead/vue";
import FooterComponent from "@/components/landing/FooterComponent.vue";

defineComponent({
    name: "AllGuidesPage",
});



useHead({
    title: "All Guides - Slo-Pitch Central",
    meta: [
        {
            name: "description",
            content: "Explore all guides available to improve your Slo-Pitch experience.",
        },
        {
            name: "keywords",
            content: "Slo-Pitch, Guides, Softball, Resources",
        },
    ],
});
</script>