import { createRouter, createWebHistory } from "@ionic/vue-router";
// import { rampOperations } from '@/utils/rampOperations';

import { useAuthStore } from "@/stores/authStore";
import { showToast } from "@/utils/useIonicComponents.js";
import { useLeagueStore } from "@/stores/leagueStore";
import { useGameStore } from "@/stores/gameStore";
import LandingPage from "../views/LandingPage.vue";
import TabsPage from "../views/TabsPage.vue";
import SchedulePage from "@/views/SchedulePage.vue";

import ScheduleGeneratorPage from "@/views/ScheduleGeneratorPage.vue";
import StandingsPage from "@/views/StandingsPage.vue";
import TeamsPage from "@/views/TeamsPage.vue";
import InfoPage from "@/views/InfoPage.vue";
import AccountPage from "@/views/AccountPage.vue";
import DiamondsPage from "@/views/DiamondsPage.vue";
// import ParkPage from "@/views/ParkPage.vue";
import NewParkPage from "@/views/NewParkPage.vue";
import BallparkChangeLog from "@/views/BallparkChangeLog.vue";
import ResetPassword from "@/views/ResetPassword.vue";
import BlogPage from "@/views/BlogPage.vue";
import PostsPage from "@/views/PostsPage.vue";
import EventDirectoryPage from "@/views/EventDirectoryPage.vue";
import FrequentlyAskedQuestionsPage from "@/views/FrequentlyAskedQuestionsPage.vue";
import ContactUsPage from "@/views/ContactUsPage.vue";
import FeaturesPage from "@/views/FeaturesPage.vue";
import BudgetGeneratorPage from "@/views/BudgetGeneratorPage.vue";
import TutorialPage from "@/views/TutorialPage.vue";
import UserDashboardPage from "@/views/UserDashboardPage.vue";
import LeagueDashboardPage from "@/views/LeagueDashboardPage.vue";
import NewsPostPage from "@/views/NewsPostPage.vue";
import InviteToRoster from "@/views/InviteToRoster.vue";
import ConfirmEmail from "@/views/ConfirmEmail.vue";
import RegisterForEventPage from "@/views/RegisterForEventPage.vue";
import DemosPage from "@/views/DemosPage.vue";
import ScoreboardControllerPage from "@/views/ScoreboardControllerPage.vue";
import PrivacyPolicyPage from "@/views/PrivacyPolicyPage.vue";
import ScorekeeperDashboard from "@/views/ScorekeeperDashboard.vue";
import ScorekeeperPage from "@/views/ScorekeeperPage.vue";
import DiamondsByCityPage from "@/views/DiamondsByCityPage.vue";
import LeaguesByCityPage from "@/views/LeaguesByCityPage.vue";
import TournamentsByCityPage from "@/views/TournamentsByCityPage.vue";
import MajorEventsPage from "@/views/MajorEventsPage.vue";
import RoundRobinPage from "@/views/RoundRobinPage.vue";
import AllGuidesPage from "@/views/AllGuidesPage.vue";
import SloPitchTournamentGuidePage from "@/views/SloPitchTournamentGuidePage.vue";
import HomePage from "@/views/HomePage.vue";

const routes = [
  {
    path: "/",
    component: LandingPage,
    name: "home",
  },
  {
    path: "/privacy-policy",
    component: PrivacyPolicyPage,
    name: "privacy",
  },
  {
    path: '/controller',
    component: ScoreboardControllerPage,
    name: "controller"
  },
  {
    path: "/budget-generator/:eventType?/:numberOfTeams?",
    component: BudgetGeneratorPage
  },
  {
    path: '/major-events',
    component: MajorEventsPage
  },
  {
    path: "/event-directory",
    component: EventDirectoryPage,
  },
  {
    path: "/frequently-asked-questions",
    component: FrequentlyAskedQuestionsPage,
  },
  {
    path: "/features",
    component: FeaturesPage,
  },
  {
    path: "/contact-us",
    component: ContactUsPage,
  },
  {
    path: "/account",
    component: AccountPage,
    meta: { requiresLogin: true },
  },
  {
    path: "/posts",
    component: PostsPage,
  },
  {
    path: "/post/:slug",
    component: BlogPage,
  },
  {
    path: "/blog/:slug",
    component: BlogPage,
  },
  {
    path: "/ballparks",
    component: DiamondsPage,
  },
  {
    path: "/ballparks-by-city/:city",
    component: DiamondsByCityPage,
  },
  {
    path: '/leagues-by-city/:city',
    component: LeaguesByCityPage
  },
  {
    path: '/tournaments-by-city/:city',
    component: TournamentsByCityPage
  },
  {
    path: '/round-robin-brackets/:teams?',
    component: RoundRobinPage
  },
  {
    path: '/demos',
    component: DemosPage
  },
  {
    path: '/guides',
    component: AllGuidesPage
  },
  {
    path: '/guides/running-a-slopitch-tournament-ultimate-guide',
    component: SloPitchTournamentGuidePage
  },
  // {
  //   path: "/ballparks/:slug",
  //   component: ParkPage,
  //   name: "parkDetails",
  // },
  {
    path: "/ballparks/:slug",
    component: NewParkPage,
    name: "ballparkv2",
  },
  
  {
    path: "/ballpark-change-log",
    component: BallparkChangeLog,
    meta: { requiresLogin: true },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: UserDashboardPage,
    meta: { requiresLogin: true },
  },
  {
    path: "/confirm-email/:token",
    name: "confirmEmail",
    component: ConfirmEmail,
  },
  {
    path: '/scorekeep',
    name: 'scorekeep',
    component: ScorekeeperDashboard
  },
  {
    path: '/scorekeep/:game_id',
    name: 'scorekeepGame',
    component: ScorekeeperPage
  },
  {
    path: "/league/:leagueSlug/",
    alias: ["/tournament/:leagueSlug", "/series/:leagueSlug"],
    component: TabsPage,
    children: [
      {
        path: "",
        redirect: (to) => {
          return `${to.fullPath}/dashboard`;
        },
      },
      {
        path: "admin",
        name: "admin",
        component: LeagueDashboardPage,
      },
      {
        path: "dashboard",
        name: "dashboard",
        component: HomePage,
      },
      {
        path: "teams",
        name: "teams",
        component: TeamsPage,
      },
      {
        path: "standings",
        name: "standings",
        component: StandingsPage,
      },
      {
        path: "schedule",
        name: "schedule",
        component: SchedulePage,
      },
      {
        path: "generator",
        name: "scheduleGenerator",
        component: ScheduleGeneratorPage,
      },
      {
        path: "news/:newsSlug",
        name: "newsPost",
        component: NewsPostPage,
      },
      
      {
        path: "info",
        name: "info",
        component: InfoPage,
      },
      {
        path: "register",
        name: "register",
        component: RegisterForEventPage,
      },
      {
        path: "rosters/invite",
        name: "InviteToRoster",
        component: InviteToRoster,
      },
    ],
    beforeEnter: async (to, from, next) => {
      const leagueStore = useLeagueStore();
      const gameStore = useGameStore();
      const authStore = useAuthStore();
      if (
        !leagueStore.league ||
        leagueStore.league.value?.slug !== to.params.leagueSlug
      ) {
        try {
          await Promise.all([
            leagueStore.getLeague(to.params.leagueSlug),
            gameStore.getGames(to.params.leagueSlug),
          ]);
          if (leagueStore.league.public === 1 || authStore.isConvenor) {
            next(); // Proceed with the navigation
          } else {
            showToast(
              "This event is not public at this moment.(router)",
              "danger",
              5000
            );
            next("/");
          }
        } catch (err) {
          console.log("err", err);
          // next('/'); // Redirect to home page on error
        }
      } else {
        next(); // Proceed with the navigation if league is already loaded
      }
    },
  },
  {
    path: "/reset-password",
    component: ResetPassword,
  },
  {
    path: "/tutorial",
    component: TutorialPage,
  },
  {
    path: "/:catchAll(.*)",
    name: "404",
    redirect: "/",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: "smooth", // optional for smooth scrolling
      };
    }
  },
});

router.beforeEach(async (to, from, next) => {
  // rampOperations.reinitialize(to.path, from.path);
  const authStore = useAuthStore();
  if (!authStore.isLoggedIn) {
    // const loading = await showLoading("Please wait...");
    await authStore.refreshToken();
    // loading.dismiss();
  }
  if (to.matched.some((record) => record.meta.requiresLogin)) {
    if (!authStore.isLoggedIn) {
      showToast("Please Login", "warning");
      next({
        path: "/login",
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
