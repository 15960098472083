<template>
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="end">
        <slot name="headerStart">
          <ion-button color="danger" @click="close"><ion-icon :icon="closeOutline"></ion-icon> </ion-button>
        </slot>
      </ion-buttons>
      <ion-title class="modal-title">{{ props.modalTitle }}</ion-title>
      <ion-buttons slot="start">
        <slot name="headerEnd"></slot>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <slot name="headerBelow"></slot>
  <ion-content 
    class="ion-padding ion-content" 
    @paste="$emit('paste', $event)"
    @drop.prevent="$emit('drop', $event)"
    @dragover.prevent
    
  >
    <slot></slot>
  </ion-content>
  <slot name="footer"></slot>
</template>

<script setup>
import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  modalController,
  IonButtons,
  IonButton,
  IonIcon
} from "@ionic/vue";
import { closeOutline } from "ionicons/icons";
import { defineComponent, defineEmits, defineProps } from "vue";
import { useRoute, useRouter } from "vue-router";
const router = useRouter();
const route = useRoute();

defineComponent({
  name: "BaseModal",
});

const props = defineProps({
  modalTitle: String,
  fullscreen: {
    type: Boolean,
    default: false
  }
});


const emit = defineEmits(["close", "paste", "drop", "dragover"]);

async function close() {
  emit("close");
  router.replace({
  query: {
    ...route.query,
    open_modal: "",
  },
});
  await modalController.dismiss();
}
</script>

<style scoped>
.ion-content {
  --background: #121212;
}

.modal-title {
  font-size: 16px; /* Default font size for smaller screens */
  text-transform: none; /* Default text transform for smaller screens */
}

@media (min-width: 701px) {
  .modal-title {
    font-size: 22px;
    text-transform: uppercase;
  }
}

ion-toolbar {
  --background: #090909;
  border-top: 2px solid #7438e9;
}
</style>