<template>
    <ion-page>
      <ion-content>
        <sitewide-notice />
        <ion-header>
          <ion-toolbar class="header-toolbar">
            <ion-buttons slot="start">
              <ion-menu-button color="dark"></ion-menu-button>
            </ion-buttons>
            <ion-title>Round Robin Generator</ion-title>
          </ion-toolbar>
        </ion-header>
        <div class="header-text">
          <div class="text-center" style="padding: 20px">
            <h1 style="text-transform: uppercase; text-align: center;">
              {{ teams.length }}-Team Round Robin Bracket
            </h1>
          </div>
        </div>
        <div class="team-input">
          
          <ion-list>
            <ion-item v-for="(team, index) in teams" :key="index">
              <ion-input v-model="teams[index]" @ionChange="generateSchedule"></ion-input>
              <ion-button slot="end" @click="removeTeam(index)">Remove</ion-button>
            </ion-item>
          </ion-list>
          <ion-item>
            <ion-input v-model="newTeam" placeholder="Enter team name"></ion-input>
            <ion-button @click="addTeam">Add Team</ion-button>
          </ion-item>
        </div>
        <div class="schedule" v-if="schedule.length">
          <div v-for="(round, roundIndex) in schedule" :key="roundIndex">
            <h2>Round {{ roundIndex + 1 }}</h2>
            <ion-grid>
              <ion-row v-for="match in round" :key="`${match[0]}-${match[1]}`">
                <ion-col size="6" class="ion-text-right">
                  <div class="match-container">
                    <div class="match-name">{{ match[0] }}</div>
                    <div class="score-box"></div>
                  </div>
                </ion-col>
                <ion-col size="6">
                  <div class="match-container">
                    <div class="score-box"></div>
                    <div class="match-name">{{ match[1] }}</div>
                    
                  </div>
                </ion-col>
              </ion-row>
            </ion-grid>
          </div>
        </div>
        <ion-spinner v-if="loading"></ion-spinner>
        <div class="qa-wrapper">
    <div class="question">What is a Round Robin Bracket?</div>
    <div class="answer">
        <p>
            A round-robin bracket is a tournament format in which each team competes against every other team at least once. Unlike single-elimination or knockout formats where a team is out after one loss, a round-robin ensures that all teams play multiple games, creating a fairer and more comprehensive competition.
        </p>
        <p>
            In the context of slo-pitch, a round-robin bracket is particularly useful as it allows teams to have a full experience, playing various opponents and improving their skills in a non-pressurized environment. The format is great for promoting camaraderie and sportsmanship, as teams get to interact with a wide range of competitors.
        </p>
    </div>
</div>

<div class="qa-wrapper">
    <div class="question">Who Uses a Round Robin Bracket?</div>
    <div class="answer">
        <p>
            A round-robin bracket is used by slo-pitch league organizers, tournament directors, and teams themselves. It’s especially popular in recreational leagues, where the focus is more on participation and enjoyment rather than cutthroat competition. This format is also used by competitive slo-pitch tournaments that want to ensure every team has an equal opportunity to showcase their abilities, regardless of initial match outcomes.
        </p>
    </div>
</div>

<div class="qa-wrapper">
    <div class="question">When Should You Use a Round Robin Bracket?</div>
    <div class="answer">
        <p>
            A round-robin bracket is ideal for slo-pitch tournaments or leagues when:
        </p>
        <ul>
            <li><strong>There are a limited number of teams:</strong> This format works best when the number of teams is manageable, typically between 4 and 10.</li>
            <li><strong>You want to maximize playtime:</strong> Every team plays multiple games, ensuring that participants get the most out of the event.</li>
            <li><strong>You’re looking to create a balanced competition:</strong> Since all teams face each other, this format is excellent for determining the true skill level of each team.</li>
            <li><strong>When the goal is to build a community:</strong> Round robin encourages social interaction and team bonding, making it perfect for leagues or events focused on fun and community engagement.</li>
        </ul>
    </div>
</div>

<div class="qa-wrapper">
    <div class="question">Where is a Round Robin Bracket Used?</div>
    <div class="answer">
        <p>
            Round-robin brackets are used in various slo-pitch settings, including:
        </p>
        <ul>
            <li><strong>Local leagues:</strong> Recreational leagues often use this format to ensure everyone has fun and gets to play a lot.</li>
            <li><strong>Regional and National Tournaments:</strong> Even at higher levels, a round-robin can be used in the preliminary stages to seed teams for knockout rounds.</li>
            <li><strong>Company or Corporate Tournaments:</strong> In casual, company-organized tournaments, round-robin formats are favored to keep the event inclusive and fun.</li>
        </ul>
    </div>
</div>

<div class="qa-wrapper">
    <div class="question">Why Would You Want to Create a Round Robin Bracket?</div>
    <div class="answer">
        <p>
            Creating a round-robin bracket for slo-pitch is beneficial for several reasons:
        </p>
        <ul>
            <li><strong>Fair Competition:</strong> Every team gets an equal chance to prove themselves, reducing the luck factor involved in single-elimination formats.</li>
            <li><strong>Increased Playtime:</strong> Teams play more games, which is often more enjoyable and fulfilling for participants.</li>
            <li><strong>Better Assessment of Skills:</strong> By playing multiple teams, organizers and players can get a better understanding of the relative strengths and weaknesses of each team.</li>
            <li><strong>Camaraderie and Engagement:</strong> The round-robin format allows for more interaction between teams, fostering a sense of community and making the event more enjoyable for everyone involved.</li>
            <li><strong>Flexibility in Scheduling:</strong> Round-robin formats allow for flexible scheduling, which can accommodate unexpected changes, like weather delays or team availability.</li>
        </ul>
    </div>
</div>

<div class="qa-wrapper">
    <div class="question">How Do You Create a Round Robin Bracket?</div>
    <div class="answer">
        <p>
            Creating a round-robin bracket for a slo-pitch tournament involves several steps:
        </p>
        <ul>
            <li><strong>Determine the Number of Teams:</strong> The number of teams will influence the total number of games and the structure of the schedule. For 4 teams, each team will play 3 games. For 5 teams, each team will play 4 games, and so on.</li>
            <li><strong>Design the Schedule:</strong> Use a round-robin scheduler or software to create the matchups. The goal is to ensure that each team plays every other team. For odd numbers, one team will have a bye each round.</li>
            <li><strong>Set the Rules:</strong> Clearly define how points are awarded (e.g., 2 points for a win, 1 for a tie) and any tiebreakers that might be needed at the end of the round-robin stage.</li>
            <li><strong>Communicate with Teams:</strong> Make sure all teams know the schedule, rules, and how the round-robin format works. This is especially important if teams are used to other formats.</li>
            <li><strong>Prepare for Adjustments:</strong> In case of unforeseen circumstances like rain delays, have a contingency plan that might involve shortening the number of innings or adjusting the schedule.</li>
        </ul>
    </div>
</div>
        
      </ion-content>
    </ion-page>
  </template>
  
  <script setup>
import { ref, computed, watch, onMounted } from "vue";
import { useRoute } from "vue-router";
import SitewideNotice from "@/components/header/SiteNotice.vue";
import { useHead } from "@unhead/vue";
import { IonSpinner, IonContent, IonPage, IonButton, IonTitle, IonButtons, IonToolbar, IonHeader, IonMenuButton, IonItem, IonInput, IonList, IonGrid, IonRow, IonCol } from "@ionic/vue";
import roundrobin from "roundrobin";

const route = useRoute();
const teams = ref([]);
const newTeam = ref("");
const loading = ref(false);
const schedule = ref([]);

const generateSchedule = () => {
  if (teams.value.length > 1) {
    schedule.value = roundrobin(teams.value.length, teams.value);
  } else {
    schedule.value = [];
  }
};

const addTeam = () => {
  if (newTeam.value.trim()) {
    teams.value.push(newTeam.value.trim());
    newTeam.value = "";
    generateSchedule();
  }
};

const removeTeam = (index) => {
  teams.value.splice(index, 1);
  generateSchedule();
};

const metaTitle = computed(() => {
  return `${teams.value.length}-Team Round Robin Schedule - Slo-Pitch Central`;
});

const metaDescription = computed(() => {
  return `Generate a ${teams.value.length}-team round-robin schedule for your Slo-Pitch tournament. Easy-to-use bracket generator by Slo-Pitch Central.`;
});

const initializeTeams = (count) => {
  teams.value = Array.from({ length: count }, (_, i) => `Team ${i + 1}`);
  generateSchedule();
};

watch(() => teams.value, () => {
  useHead({
    title: metaTitle.value,
    meta: [
      {
        name: "description",
        content: metaDescription.value,
      },
    ],
  });
}, { deep: true });

onMounted(() => {
  const teamCount = parseInt(route.params.teams, 10);
  if (!isNaN(teamCount) && teamCount > 0) {
    initializeTeams(teamCount);
  } else {
    // Fallback to default if no valid team count in URL
    initializeTeams(3);
  }
});
</script>
  
  <style scoped>
  .team-input {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .schedule {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
  }
  
  h2 {
    margin-top: 20px;
    margin-bottom: 10px;
  }


.match-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 40px;
  background: #562da6;
  font-weight: 700;
}

.ion-col:nth-child(2) .match-container {
  justify-content: flex-start;
}

.match-name {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 10px;
  text-align: center;
}

.score-box {
  width: 40px;
  height: 40px;
  border: 1px solid #ccc;
  background-color: #f0f0f0;
}

.ion-col:nth-child(1) .match-container {
  flex-direction: row-reverse;
}

.ion-col:nth-child(1) .match-name {
  text-align: right;
}

.ion-col:nth-child(2) .match-name {
  text-align: left;
}
.qa-wrapper {
  padding: 10px 0;
  line-height: 1.5;
}

.question {
  padding-bottom: 5px;
  font-weight: 700;
  background: #7d38f0;
  padding: 10px;
  color: #fff;
}

.answer {
  padding: 10px;
  font-size: 18px;
  line-height: 1.5;

  
}

.answer p { 
  margin-bottom: 10px;    
  font-size: 18px;
}
  </style>