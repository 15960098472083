<template>
  <base-modal modal-title="Create New League/Tournament" @close="close(false)">
    <!-- <template #headerStart>
      <ion-button @click="close(false)"> Close </ion-button>
    </template> -->
    <div class="notice"><b>If you simply want to see a schedule you do NOT need to create an event.</b></div>
      
        <ion-item>
          <ion-label position="stacked">City</ion-label>
          <v-select
            placeholder="Select City"
            :options="[...cities.map((city) => `${city[0]} ${city[1]}`)]"
            :clearable="false"
            append-to-body
            style="width: 100%"
            :calculate-position="withPopper"
            v-model="league.city"
          >
            <template #open-indicator="{ attributes }">
              <ion-icon
                :icon="caretDownOutline"
                style="font-size: 0.8rem"
                v-bind="attributes"
              ></ion-icon>
            </template>
            <template v-slot:no-options="{ search }">
              <div v-on:click="newCity(search)">
                Sorry, City not found, Create <b>{{ search }}</b
                >?
              </div>
            </template>
          </v-select>
        </ion-item>
        <ion-item>
          <ion-label position="stacked">Type of Event</ion-label>
          <div class="event-type-buttons">
            <div class="organization-buttons">
              <ion-button
                size="medium"
                :fill="league.type === 'league' ? 'solid' : 'outline'"
                @click="league.type = 'league'"
              >
                League
              </ion-button>
              <ion-button
                size="medium"
                :fill="league.type === 'tournament' ? 'solid' : 'outline'"
                @click="league.type = 'tournament'"
              >
                Tournament
              </ion-button>
              <ion-button
                size="medium"
                :fill="league.type === 'series' ? 'solid' : 'outline'"
                @click="league.type = 'series'"
              >
                Series
              </ion-button>
            </div>
          </div>
        </ion-item>
      <ion-item>
        <ion-label position="stacked">Organization</ion-label>
        <div class="organization-buttons">
          <ion-button
            :fill="league.organization === 'SPN' ? 'solid' : 'outline'"
            @click="league.organization = 'SPN'"
            color="danger"
            size="medium"
          >
            <img src="https://slo-pitch.com/static/media/SPNLogo2019-White.7f71accc.png" width="40" alt="">
            &nbsp;&nbsp;&nbsp;SPN
          </ion-button>
          <ion-button
            :fill="league.organization === 'SPO' ? 'solid' : 'outline'"
            @click="league.organization = 'SPO'"
            color="tertiary"
            size="medium"
          >
            <img src="https://cdn.blacktiecollab.com/playslopitch.com/PLAYSLOPITCH%20Grunge%20hoodie%20logo%20with%20paint%20Left%20Chest%20copy.png" width="40" alt="">
          
            &nbsp;&nbsp;&nbsp;PlaySlopitch.com
          </ion-button>
          <ion-button
            :fill="league.organization === 'Other' ? 'solid' : 'outline'"
            @click="league.organization = 'Other'"
          >
            Other
          </ion-button>
        </div>
      </ion-item>
      <div class="notice-purple" v-if="league.organization === 'SPN'">
        <p>
          SPN Leagues and Tournaments will have automatic features to beef up
          your event site! This includes:
        </p>
        <p>
          - Ability to import your Teams, Divisions and other league details
        </p>
        <p>- Automatically created links for SPN Rulebook and Bat Policy</p>
        <p>
          - Additional free features like Bracket Display and first access to
          beta features
        </p>
      </div>
      <div class="notice-purple" v-if="league.organization === 'SPO'">
        <p>
          PlaySlopitch.com Leagues and Tournaments will have automatic features
          to beef up your event site! This includes:
        </p>
        <p>- Automatically created links for Rulebook and Bat Policy</p>
        <p>
          - Additional free features like Bracket Display and first access to
          beta features
        </p>
      </div>
      <div class="event-wrapper">
        <ion-row v-for="(leagueItem, index) in leagueItems" :key="index">
          <ion-col size="12" size-sm="6" class="ion-no-padding">
            <ion-item>
              <ion-label position="stacked">Event Name</ion-label>
              <ion-input
                v-model="leagueItem.name"
                @ion-input="updateLeagueName(index, $event.target.value)"
                :placeholder="`Name of this ${league.type === 'series' ? 'tournament' : league.type}`"
                :class="{ 'ion-invalid': vLeagueItems$.$errors.find(e => e.$property === 'name') }"
              ></ion-input>
            </ion-item>
          </ion-col>
          <ion-col size="12" size-sm="4.8" class="ion-no-padding">
            <ion-item>
              <ion-label position="stacked">Shortcode</ion-label>
              <ion-input
                v-model="leagueItem.shortcode"
                @ion-input="updateLeagueShortcode(index, $event.target.value)"
                placeholder="Something like MLB, for Major League Baseball"
              ></ion-input>
            </ion-item>
          </ion-col>
          <ion-col size="1.2" v-if="league.type === 'series' && leagueItems.length > 1">
            <ion-button size="small" color="danger" @click="removeLeagueItem(index)">
              <ion-icon :icon="trashOutline" slot="icon-only"></ion-icon>
            </ion-button>
          </ion-col>
        </ion-row>
        <ion-row v-if="league.type === 'series'">
          <ion-col size="12">
            <ion-button color="success" size="small" expand="block" @click="addLeagueItem">
              <ion-icon :icon="addOutline" slot="start"></ion-icon>
              Add League
            </ion-button>
          </ion-col>
        </ion-row>
      </div>

    <template #footer>
      <ion-button expand="full" @click="saveleague"
        >CREATE NEW {{ league.type.toUpperCase() }}</ion-button
      >
    </template>
  </base-modal>
</template>
<script setup>
import BaseModal from "@/components/layouts/BaseModal.vue";
import {
  // IonCol,
  // IonRow,
  // IonGrid,
  IonLabel,
  // IonCard,
  // IonCardContent,
  // IonList,
  IonItem,
  IonInput,
  IonButton,
  modalController,
  // IonSelect,
  // IonSelectOption,
  IonIcon,
} from "@ionic/vue";
import { caretDownOutline, trashOutline } from "ionicons/icons";
import { defineComponent, ref } from "vue";
import useVuelidate from "@vuelidate/core";
import { required, numeric, helpers } from "@vuelidate/validators";
// import { debounce } from "lodash";
import { showToast, showLoading } from "@/utils/useIonicComponents.js";
import { api } from "@/utils/axios";
import { createPopper } from "@popperjs/core";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import cities from "./cities.json";
import { useRouter } from "vue-router";

defineComponent({
  name: "NewEventModal",
});
const router = useRouter();
const { withAsync, withMessage } = helpers;
const league = ref({
  type: "league",
  points_for_win: 2,
  points_for_loss: 0,
  points_for_tie: 1,
  organization: "",
  city: "",
});

const leagueItems = ref([
  {
    name: "",
    shortcode: "",
  },
]);

const rules = {
  type: { required: withMessage("Event Type Required", required) },
  points_for_win: {
    required: withMessage("Points for win Required", required),
    numeric: withMessage("Points for win must be numeric", numeric),
  },
  points_for_loss: {
    required: withMessage("Points for loss Required", required),
    numeric: withMessage("Points for loss must be numeric", numeric),
  },
  points_for_tie: {
    required: withMessage("Points for tie Required", required),
    numeric: withMessage("Points for tie must be numeric", numeric),
  },
  city: {
    required: withMessage("City Required", required),
  },
  organization: {
    required: withMessage("Organization Required", required),
  },
};

const leagueItemRules = {
  name: {
    required: withMessage("Event Name Required", required),
    uniqueCheck: withMessage(
      "Unique Event Name Required",
      withAsync(uniqueEventNameCheck)
    ),
  },
  shortcode: {
    required: withMessage("Event Shortcode Required", required),
    uniqueCheck: withMessage(
      "Unique Event Shortcode Required",
      withAsync(uniqueShortCodeCheck)
    ),
  },
};


function updateLeagueName(index, value) {
  if (value) {
    leagueItems.value[index].name = value;
    leagueItems.value[index].shortcode = value
      .toUpperCase()
      .replace(/[^A-Z0-9]+/gi, "")
      .substring(0, 20);
  } else {
    leagueItems.value[index].name = "";
    leagueItems.value[index].shortcode = "";
  }
}

function updateLeagueShortcode(index, value) {
  if (value) {
    leagueItems.value[index].shortcode = value.toUpperCase();
  } else {
    leagueItems.value[index].shortcode = "";
  }
}

function addLeagueItem() {
  leagueItems.value.push({ name: "", shortcode: "" });
}

function removeLeagueItem(index) {
  leagueItems.value.splice(index, 1);
}


async function uniqueEventNameCheck(name) {
  if (name) {
    const result = await api.post("/api/check-event-name", { name });
    return !result.data.exists;
  }
  return false;
}

async function uniqueShortCodeCheck(shortcode) {
  if (shortcode) {
    const result = await api.post("/api/check-event-shortcode", {
      shortcode: shortcode.toUpperCase(),
    });
    return !result.data.exists;
  }
  return false;
}
// function updateName(ev) {
//   debounce(() => {
//     const name = ev.detail.target.value;
//     league.value.name = name;
//     league.value.shortcode = name
//       .toUpperCase()
//       .replace(/[^A-Z0-9]+/gi, "")
//       .substring(0, 20);
//   }, 1000)();
// }
// function updateShortcode(ev) {
//   debounce(() => {
//     league.value.shortcode = ev.detail.target.value;
//   }, 1000)();
// }


const v$ = useVuelidate(rules, league);
const vLeagueItems$ = useVuelidate(() => ({
  leagueItems: leagueItems.value.map(() => leagueItemRules)
}), { leagueItems });

async function saveleague() {
  const isFormValid = await v$.value.$validate();
  const isLeagueItemsValid = await vLeagueItems$.value.$validate();
  
  if (isFormValid && isLeagueItemsValid) {
    await saveleagueRequest();
  } else {
    const errors = [...v$.value.$errors, ...vLeagueItems$.value.$errors];
    showToast(errors[0].$message, "danger", 6000);
  }
}
async function saveleagueRequest() {
  const loading = await showLoading(
    `Creating ${league.value.type}. Please Wait...`
  );
  
  const payload = {
    ...league.value,
    leagues: league.value.type === 'series' ? leagueItems.value : [leagueItems.value[0]],
  };

  const result = await api.post("/api/create-event", payload);
  loading.dismiss();

  if (result.status === 201) {
    showToast(
      `${league.value.type}: ${leagueItems.value[0].name} successfully created.`,
      "success",
      6000
    );
    await close(true);
    setTimeout(() => {
      console.log(result.data.type, result.data.slug);
      router.push(`/${result.data.type}/${result.data.slug}/schedule`);
    }, 600);
  } else {
    showToast(
      "Something went wrong. If this persists, please contact us.",
      "danger",
      6000
    );
  }
}

async function close(success = false) {
  await modalController.dismiss(success);
}

async function newCity(name) {
  cities.push([name, ""]);
  league.value.city = name;
}

function withPopper(dropdownList, component) {
  dropdownList.style.width = "20rem";
  const popper = createPopper(component.$refs.toggle, dropdownList, {
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, -1],
        },
      },
      {
        name: "toggleClass",
        enabled: true,
        phase: "write",
        fn({ state }) {
          component.$el.classList.toggle("drop-up", state.placement === "top");
        },
      },
    ],
  });

  return () => popper.destroy();
}
</script>
<style scoped>
.details-input {
  border-bottom: 3px solid var(--ion-color-medium);
  text-align: center;
}

.v-select {
  --vs-controls-color: var(--ion-color-step-300);
  --vs-border-width: 0;

  --vs-dropdown-bg: var(--ion-background-color);
  --vs-dropdown-option-color: var(--ion-text-color);

  --vs-selected-bg: var(--ion-color-primary);
  --vs-selected-color: var(--ion-text-color);

  --vs-search-input-color: var(--ion-color-step-300);

  --vs-dropdown-option--active-bg: #664cc3;
  --vs-dropdown-option--active-color: #eeeeee;
  --vs-dropdown-min-width: 1rem;
  --vs-dropdown-option--deselect-color: red;

  --vs-disabled-bg: var(--ion-color-light);
  --vs-disabled-color: var(--ion-color-light);
  --vs-disabled-controls-color: var(--ion-color-light);
}
/* .vs__selected-options {
  flex-direction: row-reverse;
}
.vs--open .vs__selected-options {
  flex-direction: row;
}
.vs--open .vs__selected {
  flex-grow: 0;
} */

 .notice {
  padding: 10px;
  margin: 5px;
  border: 1px solid rgb(255, 78, 78);
  background: rgb(105, 31, 31);
  font-size: 15px;
  text-align: center;
  border-radius: 5px;
  margin-bottom: 10px;
 }

 .notice-purple {
  padding: 10px;
  margin: 5px;
  border: 1px solid rgb(164, 78, 255);
  background: rgb(74, 31, 105);
  font-size: 15px;
  text-align: center;
  border-radius: 5px;
  margin-bottom: 10px;
 }

 .event-type-buttons {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.event-type-buttons ion-button {
  flex: 1;
}
.event-wrapper {
  margin-top: 20px;
}

ion-item {
  --border-color: transparent;
}
</style>
