<template>
  <ion-page>
    <span style="margin-top: 2.8rem"></span>
    <div
      style="
        display: flex;
        width: 100%;
        height: 100%;
        /* background-color: var(--ion-background-color); */
      "
    >
      <div style="flex: 100%">
        <ion-content
          :style="{
            '--keyboard-offset': keyboardHeight + 'px',
            '--background': '#00000000',
          }"
        >
          <!-- <ion-segment
            mode="md"
            @ionChange="segmentChanged($event)"
            :value="currentSegment"
            v-if="authStore.isConvenor"
          >
            <ion-segment-button value="convenor" v-if="authStore.isConvenor">
              <ion-label>Admin Dashboard</ion-label>
            </ion-segment-button>
            <ion-segment-button
              value="player"
              v-if="leagueStore.league.hide_player_dashboard !== 1"
            >
              <ion-label>Player Home Page</ion-label>
            </ion-segment-button>
          </ion-segment> -->
          
            <!-- Convenor segment content goes here -->
            <!-- <h1
              style="
                color: var(--ion-color-dark);
                text-align: center;
                text-transform: uppercase;
              "
            >
              <span
                style="
                  background-color: var(--ion-color-primary);
                  padding: 0 10px;
                "
              >
                Having issues? Contact Us
              </span>
            </h1> -->
            
            <!-- Convenor segment content goes here -->
             
            
           
            
            <h1
              style="
                color: var(--ion-color-dark);
                text-align: center;
                text-transform: uppercase;
              "
            >
              <span
                style="
                  background-color: var(--ion-color-primary);
                  padding: 0 10px;
                "
              >
                Setup Guide
              </span>
            </h1>
            <ion-card>
              <ion-accordion-group :value="expandedAccordion">
                <ion-accordion value="setupGuide">
                  <ion-item slot="header">
                    <ion-icon
                      :icon="setupComplete ? checkboxOutline : squareOutline"
                      slot="start"
                    ></ion-icon>
                    <ion-label>Step 1 - Add your Teams, Divisions & Games</ion-label>
                  </ion-item>
                  <ion-card-content slot="content">
                    <ion-grid>
                      <ion-row>
                        <ion-col size="6">
                          <ion-card class="ion-text-center">
                            <ion-card-header>
                              <ion-card-subtitle>Option 1</ion-card-subtitle>
                              <ion-card-title style="font-size: 18px">
                                Use Our Schedule Generator
                              </ion-card-title>
                            </ion-card-header>
                            <ion-card-content>
                              <p>Fastest if you don't have a schedule yet.</p>
                              <br />
                              <ion-button
                                size="small"
                                :href="`/league/${leagueStore.league.slug}/generator`"
                                >Go to Schedule Generator</ion-button
                              >
                            </ion-card-content>
                          </ion-card>
                        </ion-col>
                        <ion-col size="6">
                          <ion-card class="ion-text-center">
                            <ion-card-header>
                              <ion-card-subtitle>Option 2</ion-card-subtitle>
                              <ion-card-title style="font-size: 18px">
                                Import a CSV
                              </ion-card-title>
                            </ion-card-header>
                            <ion-card-content>
                              <p>
                                Fastest option if you have a schedule in Excel
                                or Google Sheets.
                              </p>
                              <br />
                              <ion-button @click="uploadCSV" size="small"
                                >Go to Import CSV</ion-button
                              >
                            </ion-card-content>
                          </ion-card>
                        </ion-col>
                      </ion-row>
                      <ion-row>
                        <ion-col size="12" class="ion-text-center">
                          <p style="opacity: 0.7">
                            Or you can manually add your teams, divisions, and
                            games.
                          </p>
                        </ion-col>
                      </ion-row>
                    </ion-grid>
                  </ion-card-content>
                </ion-accordion>
                <!-- Second Accordion for Edit League Appearance -->
                <ion-accordion value="editAppearance">
                  <ion-item slot="header">
                    <ion-icon
                      :icon="
                        appearanceComplete ? checkboxOutline : squareOutline
                      "
                      slot="start"
                    ></ion-icon>
                    <ion-label
                      >Step 2 - Edit Your
                      {{
                        leagueStore.league.type.charAt(0).toUpperCase() +
                        leagueStore.league.type.slice(1)
                      }}
                      Appearance</ion-label
                    >
                  </ion-item>
                  <ion-card-content slot="content">
                    <p>
                      You can fully customize the appearance of your
                      {{ leagueStore.league.type }}. This allows you to fully
                      brand your {{ leagueStore.league.type }} exactly how you
                      want it!
                    </p>
                    <br />
                    <ion-button @click="openAppearanceModal" size="small">
                      Edit your
                      {{
                        leagueStore.league.type.charAt(0).toUpperCase() +
                        leagueStore.league.type.slice(1)
                      }}
                      Appearance
                    </ion-button>
                  </ion-card-content>
                </ion-accordion>

                <!-- Third Accordion for Add Your Rules -->
                <ion-accordion value="addRules">
                  <ion-item slot="header">
                    <ion-icon
                      :icon="rulesComplete ? checkboxOutline : squareOutline"
                      slot="start"
                    >
                    </ion-icon>
                    <ion-label
                      >Step 3 - Add Your
                      {{
                        leagueStore.league.type.charAt(0).toUpperCase() +
                        leagueStore.league.type.slice(1)
                      }}-Specific Rules</ion-label
                    >
                  </ion-item>
                  <ion-card-content slot="content">
                    <p>
                      Make sure your players have the rules at all times. Upload
                      your rules here and they will have access to it anytime
                      they access your {{ leagueStore.league.type }}!
                    </p>
                    <br />
                    <ion-button @click="openRulesModal" size="small">
                      Edit your
                      {{
                        leagueStore.league.type.charAt(0).toUpperCase() +
                        leagueStore.league.type.slice(1)
                      }}
                      Rules
                    </ion-button>
                    <!-- Content for adding rules -->
                  </ion-card-content>
                  br
                </ion-accordion>

                <!-- Fourth Accordion for Add an Announcement -->
                <ion-accordion value="addAnnouncement">
                  <ion-item slot="header">
                    <ion-icon
                      :icon="
                        announcementComplete ? checkboxOutline : squareOutline
                      "
                      slot="start"
                    >
                    </ion-icon>
                    <ion-label>Step 4 - Add an Announcement</ion-label>
                  </ion-item>
                  <ion-card-content slot="content">
                    <p>
                      Announcements can be used for specific announcements, or
                      {{ leagueStore.league.type }} news. Add your first
                      announcement so players can see it when you share your
                      link!
                    </p>
                    <!-- Content for adding an announcement -->
                  </ion-card-content>
                </ion-accordion>

                <!-- Fifth Accordion for Share Your Link -->
                <ion-accordion value="shareLink">
                  <ion-item slot="header">
                    <ion-icon
                      :icon="linkShared ? checkboxOutline : squareOutline"
                      slot="start"
                    >
                    </ion-icon>
                    <ion-label>Step 5 - Share Your Link</ion-label>
                  </ion-item>
                  <ion-card-content slot="content">
                    <p>
                      Click or tap the link below to copy it, and paste it
                      wherever you need!
                    </p>
                    <ion-item>
                      <ion-input
                        style="cursor: pointer"
                        readonly
                        :value="cleanUrl"
                        @click="copyLink"
                      ></ion-input>
                    </ion-item>
                    <!-- Content for sharing the link -->
                  </ion-card-content>
                </ion-accordion>

                <ion-accordion value="setupYourRedirect">
                  <ion-item slot="header">
                    <ion-icon
                      :icon="linkShared ? checkboxOutline : squareOutline"
                      slot="start"
                    >
                    </ion-icon>
                    <ion-label>Step 6 - Setup Your Domain (Optional)</ion-label>
                  </ion-item>
                  <ion-card-content slot="content">
                    <h2>How to Set Up a Domain Redirect</h2>

                    <p>
                      Follow these steps to redirect your domain to
                      <code
                        >https://slopitchcentral.com/{{
                          leagueStore.league.type
                        }}/{{ leagueSlug }}/dashboard</code
                      >.
                    </p>

                    <h4>GoDaddy</h4>
                    <ol>
                      <li>Login to your GoDaddy account.</li>
                      <li>Go to the <strong>My Products</strong> section.</li>
                      <li>
                        Next to your domain, click <strong>Manage</strong>.
                      </li>
                      <li>
                        In the <strong>Additional Settings</strong> section,
                        select <strong>Manage DNS</strong>.
                      </li>
                      <li>
                        Under <strong>Forwarding</strong>, click
                        <strong>Add</strong> next to <strong>Domain</strong> or
                        <strong>Subdomain</strong>.
                      </li>
                      <li>
                        Enter
                        <code
                          >https://slopitchcentral.com/{{
                            leagueStore.league.type
                          }}/{{ leagueSlug }}/dashboard</code
                        >
                        and select your forwarding preferences.
                      </li>
                      <li>Click <strong>Save</strong> to apply changes.</li>
                    </ol>

                    <h4>Namecheap</h4>
                    <ol>
                      <li>Sign in to your Namecheap account.</li>
                      <li>
                        Select <strong>Domain List</strong> from the left
                        sidebar and click <strong>Manage</strong> next to your
                        domain.
                      </li>
                      <li>
                        Go to the <strong>Redirect Domain</strong> section.
                      </li>
                      <li>
                        Add a new redirect and enter
                        <code
                          >https://slopitchcentral.com/league/{{
                            leagueSlug
                          }}/dashboard</code
                        >
                        as the destination URL.
                      </li>
                      <li>
                        Click the <strong>Checkmark</strong> to save your
                        changes.
                      </li>
                    </ol>

                    <h4>Generic Method</h4>
                    <ol>
                      <li>Log in to your domain registrar's control panel.</li>
                      <li>Find the DNS settings or Domain Management area.</li>
                      <li>Look for URL Redirect or Forwarding options.</li>
                      <li>
                        Set the redirect target to
                        <code
                          >https://slopitchcentral.com/league/{{
                            leagueSlug
                          }}/dashboard</code
                        >.
                      </li>
                      <li>Save the changes.</li>
                    </ol>

                    <p>
                      If you're having trouble setting up your domain redirect,
                      or if your domain registrar is not listed here, please
                      <a href="mailto:shane@blacktiecollab.com">contact us</a>
                      for assistance.
                    </p>

                    <!-- Content for sharing the link -->
                  </ion-card-content>
                </ion-accordion>
              </ion-accordion-group>
            </ion-card>
            <ion-row>
              <ion-col size="12" size-md="6">
                <h1
                  style="
                    color: var(--ion-color-dark);
                    text-align: center;
                    text-transform: uppercase;
                  "
                >
                  <span
                    style="
                      background-color: var(--ion-color-primary);
                      padding: 0 10px;
                    "
                  >
                    Share your {{ leagueStore.league.type }} Shortcode
                  </span>
                </h1>
                <div style="text-align: center; max-width: 500px; margin: 0 auto">
                  <p>
                    Use this as an easy way for users to search for your
                    {{ leagueStore.league.type }} on our platform.
                  </p>
                  <ion-input
                    @click="copyShortcodeToClipboard(leagueStore.league.shortcode)"
                    :value="leagueStore.league.shortcode"
                    style="border: 1px solid #333"
                  ></ion-input>
                </div>

              </ion-col>
              <ion-col size="12" size-md="6">
                <h1
                  style="
                    color: var(--ion-color-dark);
                    text-align: center;
                    text-transform: uppercase;
                  "
                >
                  <span
                    style="
                      background-color: var(--ion-color-primary);
                      padding: 0 10px;
                    "
                  >
                    Or your {{ leagueStore.league.type }} Link
                  </span>
                </h1>
                <div style="text-align: center; max-width: 500px; margin: 0 auto">
                  <p>Or share them the full URL!</p>
                  <ion-input
                    @click="
                      copyShortcodeToClipboard(
                        `https://slopitchcentral.com/${leagueStore.league.type}/${leagueStore.league.slug}/schedule`
                      )
                    "
                    :value="`https://slopitchcentral.com/${leagueStore.league.type}/${leagueStore.league.slug}/schedule`"
                    style="border: 1px solid #333"
                  ></ion-input>
                </div>
              </ion-col>
             </ion-row>
            <ion-button
              style="margin-top: 1rem; text-transform: uppercase;"
              target="_blank"
              expand="full"
              color="danger"
              href="https://tally.so/r/w8xXrO"
              >Need help? Click here to Contact Us</ion-button
            >
            <admin-actions
              style="flex-grow: 1"
              v-if="authStore.isConvenor"
              @eventWebsite="currentSegment = 'player'"
              @rulesUpdated="refreshKey++"
            />
          
        </ion-content>
      </div>
    </div>
  </ion-page>
</template>

<script setup>
import {
  IonPage,
  IonContent,
  // IonSegment,
  // IonSegmentButton,
  useKeyboard,
  IonCard,
  IonAccordionGroup,
  IonAccordion,
  IonItem,
  IonIcon,
  IonLabel,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonInput,
  onIonViewWillEnter,
} from "@ionic/vue";
import AdminActions from "../components/info/AdminActions.vue";

import { useLeagueStore } from "@/stores/leagueStore";
import { checkboxOutline, squareOutline } from "ionicons/icons";
import { useRoute } from "vue-router";
import { useRouter } from "vue-router";
import { ref } from "vue";
import RulesModal from "@/components/info/RulesModal.vue";
import AppearanceModal from "@/components/info/AppearanceModal.vue";
import { useAuthStore } from "@/stores/authStore";
import { showToast } from "@/utils/useIonicComponents.js";
import { computed } from "vue";
import { api } from "@/utils/axios";
import { openModal } from "@/utils/useIonicComponents.js";
import UploadScheduleModal from "@/components/schedule/upload-csv/UploadScheduleModal.vue";
const route = useRoute();
const router = useRouter();
const { keyboardHeight } = useKeyboard();
const authStore = useAuthStore();
const currentSegment = ref(authStore.isConvenor ? "convenor" : "player");
const setupComplete = ref(false);
const appearanceComplete = ref(false);
const rulesComplete = ref(false);
const announcementComplete = ref(false);
const refreshKey = ref(0);
const linkShared = ref(false);
const leagueSlug = route.params.leagueSlug;
//   const expandedAccordion = ref('setupGuide');
const currentUrl = window.location.href;
const cleanUrl = computed(() => {
  const url = new URL(currentUrl);
  return url.origin + url.pathname;
});

const leagueStore = useLeagueStore();

const copyLink = async () => {
  try {
    // Copy the clean URL to the clipboard
    await navigator.clipboard.writeText(cleanUrl);
    showToast("Link copied to clipboard!", "success", 2000);
  } catch (err) {
    console.error("Failed to copy: ", err);
  }
};

const fetchAchievements = async () => {
  const leagueSlug = route.params.leagueSlug;
  if (leagueSlug) {
    try {
      const { data } = await api.get(`/api/get-achievements/${leagueSlug}`);
      const achievements = data.achievements;
      setupComplete.value = achievements.games_count > 0;
      appearanceComplete.value =
        achievements.appearance_check.logo &&
        achievements.appearance_check.colours;
      rulesComplete.value = achievements.rules_count > 0;
      announcementComplete.value = achievements.announcements_count > 0;
    } catch (error) {
      console.error("Error fetching achievements:", error);
      showToast("Failed to fetch achievements", "danger", 2000);
    }
  }
};

async function uploadCSV() {
  await openModal(UploadScheduleModal, "uploadScheduleModal");
}

const expandedAccordion = computed(() => {
  if (!setupComplete.value) {
    return "setupGuide";
  } else if (!appearanceComplete.value) {
    return "editAppearance";
  } else if (!rulesComplete.value) {
    return "addRules";
  } else if (!announcementComplete.value) {
    return "addAnnouncement";
  } else {
    return ""; // or the value of the last accordion if there's one after 'addAnnouncement'
  }
});

async function openAppearanceModal() {
  router.replace({
    query: {
      ...route.query,
      open_modal: "EditAppearance",
    },
  });
  await openModal(AppearanceModal, "appearanceModal");
}

async function openRulesModal() {
  router.replace({
    query: {
      ...route.query,
      open_modal: "EditRules",
    },
  });
  await openModal(RulesModal, "rulesModal");
  // if (response === true) {
  //     emit("rulesUpdated");
  // }
}

onIonViewWillEnter(() => {
  fetchAchievements();
});
// watch(() => route.params.leagueSlug, fetchAchievements);

if (leagueStore.league.hide_player_dashboard === 1 && !authStore.isConvenor) {
  // redirect to schedule page
  router.push(`/league/${leagueSlug}/schedule`);
}


const copyShortcodeToClipboard = (shortcode) => {
  navigator.clipboard.writeText(shortcode);
  showToast("Copied to clipboard!", "success", 2000);
};
</script>

<style scoped>
h5 {
  font-size: 15px;
}
</style>
