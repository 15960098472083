<template>
  <span>
    <ion-card style="background: #131313">
      <h1
        style="
          color: var(--ion-color-dark);
          text-align: center;
          text-transform: uppercase;
        "
      >
        <span
          style="background-color: var(--ion-color-primary); padding: 0 10px"
        >
          Your Admin Controls
        </span>
      </h1>
      <span v-if="leagueStore.league.organization === 'SPN'">
        <h3
          style="
            color: var(--ion-color-dark);
            text-align: center;
            text-transform: uppercase;
          "
        >
          <span style="background-color: #555; padding: 0 10px">
            Slo-Pitch National Specific Features-{{ leagueStore.league.type }}
            Features
          </span>
        </h3>
        <div>
          <ion-item>
            <ion-label>
              <h3>Sync SPN Teams to Slo-Pitch Central</h3>
              <p>
                If you want to sync your teams to Slo-Pitch Central, click the
                button to the right.
              </p>
            </ion-label>
            <ion-buttons slot="end">
              <ion-button
                color="tertiary"
                fill="solid"
                @click="openSPNTeamSyncModal"
                ><b>SYNC</b></ion-button
              >
            </ion-buttons>
          </ion-item>
        </div>
      </span>
      <h3
        style="
          color: var(--ion-color-dark);
          text-align: center;
          text-transform: uppercase;
          margin-top: 40px;
        "
      >
        <span style="background-color: #555; padding: 0 10px">
          Pre-{{ leagueStore.league.type }} Features
        </span>
      </h3>
      <ion-item v-if="leagueStore.league.activate_registration !== 1">
        <ion-label>
          <h3>Activate Registration System</h3>
          <p>
            If you want to register teams for this event using our platform, you
            can do so! It's super easy.
          </p>
        </ion-label>
        <ion-buttons slot="end">
          <ion-button
            color="tertiary"
            fill="solid"
            @click="openRegistrationModal"
            ><b>MORE INFO</b></ion-button
          >
        </ion-buttons>
      </ion-item>
      <span v-if="leagueStore.league.activate_registration === 1">
        <ion-item>
          <ion-label>
            <h3>Registration Info</h3>
            <p>
              If you want to register teams for this event using our platform,
              you can do so! It's super easy.
            </p>
          </ion-label>
          <ion-buttons slot="end">
            <ion-button
              color="tertiary"
              fill="solid"
              @click="openRegistrationModal"
              ><b>EDIT</b></ion-button
            >
          </ion-buttons>
        </ion-item>
        <ion-item>
          <ion-label>
            <h3>Set Your Prices & Final Details</h3>
            <p>
              Click the button to the right to set your prices and final details
              for registration.
            </p>
          </ion-label>
          <ion-buttons slot="end">
            <ion-button color="tertiary" fill="solid" @click="openPriceModal"
              ><b>SET PRICES</b></ion-button
            >
          </ion-buttons>
        </ion-item>
        <ion-item
          v-if="
            leagueStore.league.team_registration_price ||
            leagueStore.league.individual_registration_price
          "
        >
          <ion-label>
            <h3>Share Your Registration Page</h3>
            <p>
              Click the button to copy the direct link to your registration
              page.
            </p>
          </ion-label>
          <ion-buttons slot="end">
            <ion-button
              color="tertiary"
              fill="solid"
              @click="copyRegistrationLink"
              ><b>COPY LINK</b></ion-button
            >
          </ion-buttons>
        </ion-item>
      </span>
      <!-- when activation system is activated, link to view their payouts -->
      <h3
        style="
          color: var(--ion-color-dark);
          text-align: center;
          text-transform: uppercase;
          margin-top: 40px;
        "
      >
        <span style="background-color: #555; padding: 0 10px">
          Score Reporting
        </span>
      </h3>
      <ion-item>
        <ion-label>
          <h3>Score Reporting Permissions</h3>
          <p>
            Choose who reports the score for your {{ leagueStore.league.type }}.
          </p>
        </ion-label>
        <ion-buttons slot="end">
          <ion-button
            color="tertiary"
            fill="solid"
            @click="manageScoreReporting"
            ><b>MANAGE</b></ion-button
          >
        </ion-buttons>
      </ion-item>
      <ion-item v-if="leagueStore.league.can_admin_report">
        <ion-label>
          <h3>Admin Code</h3>
          <p>
            Set code for Admin to report the score for your
            {{ leagueStore.league.type }}. Current code:
            <b>{{ leagueStore.league.admin_code }}</b>
          </p>
        </ion-label>
        <ion-buttons slot="end">
          <ion-button color="tertiary" fill="solid" @click="setAdminCode"
            ><b>EDIT</b></ion-button
          >
        </ion-buttons>
      </ion-item>
      <ion-item v-if="leagueStore.league.can_umpire_report">
        <ion-label>
          <h3>Umpire Code</h3>
          <p>
            Set code for Umpire to report the score for your
            {{ leagueStore.league.type }}. Current code:
            <b>{{ leagueStore.league.umpire_code }}</b>
          </p>
        </ion-label>
        <ion-buttons slot="end">
          <ion-button color="tertiary" fill="solid" @click="setUmpireCode"
            ><b>EDIT</b></ion-button
          >
        </ion-buttons>
      </ion-item>
      <ion-item v-if="leagueStore.league.can_admin_report">
        <ion-label>
          <h3>Team Codes</h3>
          <p>
            Set code for all your teams in the team page for your
            {{ leagueStore.league.type }}
          </p>
        </ion-label>
        <ion-buttons slot="end">
          <ion-button color="tertiary" fill="solid" @click="redirectToTeams"
            ><b>GO NOW</b></ion-button
          >
        </ion-buttons>
      </ion-item>
      <h3
        style="
          color: var(--ion-color-dark);
          text-align: center;
          text-transform: uppercase;
          margin-top: 40px;
        "
      >
        <span style="background-color: #555; padding: 0 10px">
          Your {{ leagueStore.league.type }} Settings
        </span>
      </h3>

      <ion-item>
        <ion-label>
          <h3>Event Permissions</h3>
          <p>
            Add or remove additional admins to help you run your
            {{ leagueStore.league.type }}.
          </p>
        </ion-label>
        <ion-buttons slot="end">
          <ion-button color="tertiary" fill="solid" @click="openPermissionModal"
            ><b>MANAGE</b></ion-button
          >
        </ion-buttons>
      </ion-item>

      <ion-item v-if="leagueStore.league.hide_player_dashboard !== 1">
        <ion-label>
          <h3>Event Website</h3>
          <p>
            Click here to see your frontpage website and event headquarters.
            Edit everything there!
          </p>
        </ion-label>
        <ion-buttons slot="end">
          <ion-button color="tertiary" fill="solid" @click="emitEventWebsite"
            ><b>EDIT</b></ion-button
          >
        </ion-buttons>
      </ion-item>
      <ion-item>
        <ion-label>
          <h3>Event Appearance</h3>
          <p>
            Change the logo in the top left, the colours used or a background
            image.
          </p>
        </ion-label>
        <ion-buttons slot="end">
          <ion-button color="tertiary" fill="solid" @click="openAppearanceModal"
            ><b>EDIT</b></ion-button
          >
        </ion-buttons>
      </ion-item>
      <ion-item>
        <ion-label>
          <h3>Edit Rules</h3>
          <p>Edit the rules used in this event.</p>
        </ion-label>
        <ion-buttons slot="end">
          <ion-button color="tertiary" fill="solid" @click="openRulesModal"
            ><b>EDIT</b></ion-button
          >
        </ion-buttons>
      </ion-item>
      <ion-item>
        <ion-label>
          <h3>Event Details</h3>
          <p>Edit the Points Per Win, Points Per Loss or Points Per Tie, as well as run diff per game max.</p>
        </ion-label>
        <ion-buttons slot="end">
          <ion-button color="tertiary" fill="solid" @click="openInfoModal"
            ><b>EDIT</b></ion-button
          >
        </ion-buttons>
      </ion-item>
      <h3
        style="
          color: var(--ion-color-dark);
          text-align: center;
          text-transform: uppercase;
          margin-top: 40px;
        "
      >
        <span style="background-color: #555; padding: 0 10px">
          Schedule Related
        </span>
      </h3>
      <ion-item>
        <ion-label>
          <h3>Games Management</h3>
          <p>Add games to the schedule in a variety of ways.</p>
        </ion-label>
        <ion-buttons slot="end">
          <ion-button color="tertiary" fill="solid" @click="addGame"
            ><b>ADD GAMES</b></ion-button
          >
          <ion-button color="tertiary" fill="solid" @click="uploadCSV"
            ><b>IMPORT CSV</b></ion-button
          >
          <ion-button color="tertiary" fill="solid"><b>GENERATE</b></ion-button>
        </ion-buttons>
      </ion-item>
      <ion-item>
        <ion-label>
          <h3>Merge Event Locations</h3>
          <p>
            Manage and merge event locations if you have typos in your parks or
            diamond names.
          </p>
        </ion-label>
        <ion-buttons slot="end">
          <ion-button color="tertiary" fill="solid" @click="openLocationModal"
            ><b>MANAGE</b></ion-button
          >
        </ion-buttons>
      </ion-item>
      <ion-item>
        <ion-label>
          <h3>Add Announcements</h3>
        </ion-label>
        <ion-buttons slot="end">
          <ion-button
            color="tertiary"
            fill="solid"
            @click="openNewAnnouncementModal"
            ><b>ADD</b></ion-button
          >
        </ion-buttons>
      </ion-item>
      <ion-item>
        <ion-label>
          <h3>Set Default Sort Order</h3>
        </ion-label>
        <ion-buttons slot="end">
          <ion-button color="tertiary" fill="solid" @click="openSortModal"
            ><b>SET</b></ion-button
          >
        </ion-buttons>
      </ion-item>
      <h3
        style="
          color: var(--ion-color-dark);
          text-align: center;
          text-transform: uppercase;
          margin-top: 40px;
        "
      >
        <span style="background-color: #555; padding: 0 10px">
          Standings Related
        </span>
      </h3>
      <ion-item>
        <ion-label>
          <h3>Set Division Sort Order</h3>
        </ion-label>
        <ion-buttons slot="end">
          <ion-button color="tertiary" fill="solid" @click="openDivisionsSortModal"
            ><b>SET</b></ion-button
          >
        </ion-buttons>
      </ion-item>
      <ion-list lines="full">
        <!-- <ion-item v-if="authStore.isAdmin">
          <ion-label>
            <h3>Hide/Show Team IDs</h3>
            <p>
              Showing team IDs can help you recognize the difference between two
              teams with the same name.
            </p>
          </ion-label>
          <ion-buttons slot="end">
            <ion-button @click="preferenceStore.toggleShowTeamIDs">
              {{ preferenceStore.showTeamIDs ? "Hide" : "Show" }} Team IDs
            </ion-button>
          </ion-buttons>
        </ion-item> -->
        <h3
          style="
            color: var(--ion-color-dark);
            text-align: center;
            text-transform: uppercase;
            margin-top: 10px;
            margin-top: 40px;
          "
        >
          <span style="background-color: #555; padding: 0 10px">
            Hide Features
          </span>
        </h3>
        <ion-item v-if="authStore.isAdmin">
          <ion-label>
            <h3 style="text-transform: capitalize">
              Hide {{ leagueStore.league.type }} Publically
            </h3>
            <p>
              When turned ON, the {{ leagueStore.league.type }} will be hidden
              from the public. Users will not be able to access the
              {{ leagueStore.league.type }}.
            </p>
          </ion-label>
          <ion-toggle
            slot="end"
            :checked="!(leagueStore.league.public === 1)"
            @ionChange="hidePublic"
          ></ion-toggle>
        </ion-item>
        <ion-item v-if="authStore.isAdmin">
          <ion-label>
            <h3>Hide Team IDs</h3>
            <p>
              Showing team IDs can help you recognize the difference between two
              teams with the same name.
            </p>
          </ion-label>
          <ion-toggle
            slot="end"
            :checked="preferenceStore.showTeamIDs"
            @ionChange="preferenceStore.toggleShowTeamIDs"
          ></ion-toggle>
        </ion-item>
        <ion-item v-if="authStore.isAdmin">
          <ion-label>
            <h3>Hide Player Dashboard/Home Page</h3>
            <p>
              Hide the Home Page for Players/Player Dashboard page. Players will
              be redirected to the Schedule page in this case.
            </p>
          </ion-label>
          <ion-toggle
            slot="end"
            :checked="leagueStore.league.hide_player_dashboard"
            @ionChange="hidePlayerDashboard"
          ></ion-toggle>
        </ion-item>

        <ion-item v-if="authStore.isAdmin">
          <ion-label>
            <h3>Hide Schedule Tab</h3>
            <p>
              If you want to hide the schedule tab, for example, until game day.
              It will still be accessible by you and all other admins.
            </p>
          </ion-label>
          <!-- <ion-toggle slot="end" :checked="preferenceStore.showTeamIDs" @ionChange="preferenceStore.toggleShowTeamIDs"></ion-toggle> -->
          <ion-toggle
            slot="end"
            :checked="leagueStore.league.hide_schedule_tab"
            @ionChange="hideScheduleTab"
          ></ion-toggle>
        </ion-item>
        <ion-item v-if="authStore.isAdmin">
          <ion-label>
            <h3>Hide Standings Tab</h3>
            <p>
              If you want to hide the standings tab, for example, until game
              day. It will still be accessible by you and all other admins.
            </p>
          </ion-label>
          <!-- <ion-toggle slot="end" :checked="preferenceStore.showTeamIDs" @ionChange="preferenceStore.toggleShowTeamIDs"></ion-toggle> -->
          <ion-toggle
            slot="end"
            :checked="leagueStore.league.hide_standings_tab"
            @ionChange="hideStandingsTab"
          ></ion-toggle>
        </ion-item>
        <ion-item v-if="authStore.isAdmin">
          <ion-label>
            <h3>Hide Teams Tab</h3>
            <p>
              If you want to hide the teams tab, for example, until game day. It
              will still be accessible by you and all other admins.
            </p>
          </ion-label>
          <ion-toggle
            slot="end"
            :checked="leagueStore.league.hide_teams_tab"
            @ionChange="hideTeamsTab"
          ></ion-toggle>
          <!-- <ion-toggle slot="end" :checked="preferenceStore.showTeamIDs" @ionChange="preferenceStore.toggleShowTeamIDs"></ion-toggle> -->
        </ion-item>
        <ion-item v-if="authStore.isAdmin">
          <ion-label>
            <h3>Hide Game Mode</h3>
            <p>Hide things like Round Robin/Season in the schedule.</p>
          </ion-label>
          <ion-toggle
            slot="end"
            :checked="leagueStore.league.hide_game_mode"
            @ionChange="hideGameMode"
          ></ion-toggle>
          <!-- <ion-toggle slot="end" :checked="preferenceStore.showTeamIDs" @ionChange="preferenceStore.toggleShowTeamIDs"></ion-toggle> -->
        </ion-item>
      </ion-list>
    </ion-card>
    <ion-card style="background: #131313">
      <h1
        style="
          color: var(--ion-color-dark);
          text-align: center;
          text-transform: uppercase;
        "
      >
        <span
          style="background-color: var(--ion-color-primary); padding: 0 10px"
        >
          Only Use These If You're Sure!
        </span>
      </h1>
      <ion-list>
        <ion-item color="tertiary">
          <ion-label>
            <h3>Reset Standings</h3>
            <p>This will reset your standings to 0.</p>
          </ion-label>
          <ion-buttons slot="end" @click="resetStandings">
            <!-- TODO: Connect Reset Standings functionality -->
            <ion-button>RESET STANDINGS</ion-button>
          </ion-buttons>
        </ion-item>
        <ion-item color="warning">
          <ion-label>
            <h3>Erase Event Schedule</h3>
            <p>You cannot undo this!</p>
          </ion-label>
          <ion-buttons slot="end">
            <ion-button color="light" @click="eraseSchedule">ERASE</ion-button>
          </ion-buttons>
        </ion-item>

        <ion-item color="danger">
          <ion-label>
            <h3>Delete Event</h3>
            <p>You cannot undo this!</p>
          </ion-label>
          <ion-buttons slot="end">
            <ion-button color="light" @click="deleteEvent">DELETE</ion-button>
          </ion-buttons>
        </ion-item>
      </ion-list>
    </ion-card>
  </span>
</template>

<script setup>
import { defineComponent, ref, onBeforeUnmount, defineEmits } from "vue";
// import {  } from "@ionic/vue";
import { debounce } from "lodash";
import InfoModal from "@/components/info/InfoModal.vue";
import NewAnnouncementModal from "@/components/announcements/NewAnnouncementModal.vue";
import AppearanceModal from "@/components/info/AppearanceModal.vue";
import RulesModal from "@/components/info/RulesModal.vue";
import AddGameModal from "@/components/schedule/new-game/AddGameModal.vue";
import UploadScheduleModal from "@/components/schedule/upload-csv/UploadScheduleModal.vue";
import EditLocationsModal from "@/components/location/EditLocationsModal.vue";
import PermissionModal from "@/components/info/PermissionModal.vue";
import RegistrationModal from "@/components/info/RegistrationModal.vue";
import PricesModal from "@/components/info/PricesModal.vue";
import { useRoute } from "vue-router";
import { api } from "@/utils/axios";
import SortModal from "@/components/schedule/sort-by/SortModal.vue";
import DivisionSortModal from "@/components/schedule/sort-by/DivisionSortModal.vue";

import {
  openModal,
  showAlert,
  showToast,
  showLoading,
} from "@/utils/useIonicComponents.js";
import { useAuthStore } from "@/stores/authStore";
import { useLeagueStore } from "@/stores/leagueStore";
import { useRouter } from "vue-router";
import { usePreferenceStore } from "@/stores/preferenceStore";
import {
  IonList,
  IonCard,
  IonButtons,
  IonButton,
  IonItem,
  IonLabel,
  IonToggle,
} from "@ionic/vue";
import SPNTeamSyncModal from "./SPNTeamSyncModal.vue";

defineComponent({
  name: "AdminActions",
});
const emit = defineEmits(["rulesUpdated"]);
const authStore = useAuthStore();
const preferenceStore = usePreferenceStore();
const leagueStore = useLeagueStore();
const router = useRouter();
const route = useRoute();


async function addGame() {
  router.replace({
    query: {
      ...route.query,
      open_modal: "NewGames",
    },
  });
  await openModal(AddGameModal, "addGameModal");
}
async function uploadCSV() {
  router.replace({
    query: {
      ...route.query,
      open_modal: "UploadCSV",
    },
  });
  await openModal(UploadScheduleModal, "uploadScheduleModal");
}
async function openInfoModal() {
  router.replace({
    query: {
      ...route.query,
      open_modal: "EditInfo",
    },
  });
  await openModal(InfoModal, "infoModal");
}

async function openSortModal() {
  const response = await openModal(SortModal, "sortModal");
  await api.post("/api/set-default-sort-order", {
    eventId: leagueStore.league.id,
    sortOrder: JSON.stringify(response),
  });
}

async function openDivisionsSortModal() {
  const response = await openModal(DivisionSortModal, "divisionSortModal");
  console.log('response', response)
}

async function openNewAnnouncementModal() {
  router.replace({
    query: {
      ...route.query,
      open_modal: "NewAnnouncements",
    },
  });
  await openModal(NewAnnouncementModal, "newAnnouncementModal");
}
async function openAppearanceModal() {
  router.replace({
    query: {
      ...route.query,
      open_modal: "EditAppearance",
    },
  });
  await openModal(AppearanceModal, "appearanceModal");
}
async function openRulesModal() {
  router.replace({
    query: {
      ...route.query,
      open_modal: "EditRules",
    },
  });
  const response = await openModal(RulesModal, "rulesModal");
  if (response === true) {
    emit("rulesUpdated");
  }
}

async function openLocationModal() {
  router.replace({
    query: {
      ...route.query,
      open_modal: "EditLocations",
    },
  });
  await openModal(EditLocationsModal, "editLocationsModal");
}
async function openPermissionModal() {
  router.replace({
    query: {
      ...route.query,
      open_modal: "EditPermissions",
    },
  });
  await openModal(PermissionModal, "permissionModal");
}
async function openRegistrationModal() {
  console.log("route", router, route);
  router.replace({
    query: {
      ...route.query,
      open_modal: "Registration",
    },
  });
  await openModal(RegistrationModal, "registrationModal");
}

async function openSPNTeamSyncModal() {
  router.replace({
    query: {
      ...route.query,
      open_modal: "SPNTeamSyncModal",
    },
  });
  await openModal(SPNTeamSyncModal, "spnTeamSyncModal");
}

async function openPriceModal() {
  router.replace({
    query: {
      ...route.query,
      open_modal: "Prices",
    },
  });
  await openModal(PricesModal, "pricesModal");
}

const emitEventWebsite = () => {
  emit("eventWebsite");
};

const redirectToTeams = () => {
  router.push({
    name: "teams",
    params: { leagueSlug: leagueStore.league.slug },
  });
};

async function manageScoreReporting() {
  await showAlert({
    header: "Choose who can report scores",
    inputs: [
      {
        name: "Team",
        type: "checkbox",
        label: "Team",
        value: "Team",
        checked: leagueStore.league.can_teams_report === 1 ? true : false,
      },
      {
        name: "Umpire",
        type: "checkbox",
        label: "Umpire",
        value: "Umpire",
        checked: leagueStore.league.can_umpire_report === 1 ? true : false,
      },
      {
        name: "Admin",
        type: "checkbox",
        label: "Admin",
        value: "Admin",
        checked: leagueStore.league.can_admin_report === 1 ? true : false,
      },
    ],
    buttons: [
      {
        text: "Cancel",
        role: "cancel",
        handler: () => {
          console.log(" Cancel");
        },
      },
      {
        text: "Ok",
        handler: async (selected) => {
          console.log("Selected values:", selected);
          if (selected.includes("Admin")) {
            await setAdminCode();
          }
          if (selected.includes("Umpire")) {
            await setUmpireCode();
          }

          await api.post("/api/set-reporting-permissions", {
            eventId: leagueStore.league.id,
            can_admin_report: selected.includes("Admin") ? 1 : 0,
            can_umpire_report: selected.includes("Umpire") ? 1 : 0,
            can_teams_report: selected.includes("Team") ? 1 : 0,
          });
          await leagueStore.reloadEverything(route.params.leagueSlug);
        },
      },
    ],
  });
}

async function setAdminCode() {
  const response = await showAlert({
    header: `Set Admin Code`,
    inputs: [
      { placeholder: "Admin Code", value: leagueStore.league.admin_code },
    ],
    buttons: [
      {
        text: "Cancel",
        role: "cancel",
      },
      {
        text: "OK",
        role: "confirm",
      },
    ],
  });
  if (response.data.values[0] && response.role === "confirm") {
    await api.post("/api/set-admin-code", {
      eventId: leagueStore.league.id,
      adminCode: response.data.values[0],
    });
    await leagueStore.reloadEverything(route.params.leagueSlug);
  }
}
async function setUmpireCode() {
  const response = await showAlert({
    header: `Set Umpire Code`,
    inputs: [
      { placeholder: "Umpire Code", value: leagueStore.league.umpire_code },
    ],
    buttons: [
      {
        text: "Cancel",
        role: "cancel",
      },
      {
        text: "OK",
        role: "confirm",
      },
    ],
  });
  if (response.data.values[0] && response.role === "confirm") {
    await api.post("/api/set-umpire-code", {
      eventId: leagueStore.league.id,
      umpireCode: response.data.values[0],
    });
    await leagueStore.reloadEverything(route.params.leagueSlug);
  }
}

async function eraseSchedule() {
  await showAlert({
    header: "Are you sure you want to Erase Schedule, Teams, & Divisions ?",
    buttons: [
      {
        text: "Cancel",
        role: "cancel",
      },
      {
        text: "OK",
        role: "confirm",
        handler: async () => {
          await eraseScheduleRequest();
        },
      },
    ],
  });
}
async function deleteEvent() {
  await showAlert({
    header: `Are you sure you want to DELETE THIS ${leagueStore.league.type.toUpperCase()} ?`,
    buttons: [
      {
        text: "Cancel",
        role: "cancel",
      },
      {
        text: "OK",
        role: "confirm",
        handler: async () => {
          await deleteEventRequest();
        },
      },
    ],
  });
}
async function eraseScheduleRequest() {
  const loading = await showLoading(
    "Erasing Schedule, Teams & Divisions. Please Wait..."
  );
  const result = await api
    .post(`/api/erase-schedule`, {
      event_id: leagueStore.league.id,
    })
    .catch((err) => {
      console.log(err);
      showToast(
        "Something went wrong. If this persists, please contact us.",
        "danger",
        6000
      );
      loading.dismiss();
    });
  loading.dismiss();
  if (result.data.type === "success") {
    await leagueStore.reloadEverything(leagueStore.league.slug);
    showToast(
      "Schedule, Teams, & Divisions successfully Erased.",
      "success",
      6000
    );
  } else {
    showToast(
      "Something went wrong. If this persists, please contact us.",
      "danger",
      6000
    );
  }
}
async function deleteEventRequest() {
  const loading = await showLoading(
    `Deleting this ${leagueStore.league.type}. Please Wait...`
  );
  const result = await api
    .delete(`/api/delete-event/${leagueStore.league.id}`)
    .catch((err) => {
      console.log(err);
      showToast(
        "Something went wrong. If this persists, please contact us.",
        "danger",
        6000
      );
      loading.dismiss();
    });
  loading.dismiss();
  if (result.status === 200) {
    showToast(
      `${
        leagueStore.league.type.charAt(0).toUpperCase() +
        leagueStore.league.type.slice(1)
      } Deleted Successfully.`,
      "success",
      6000
    );
    router.replace("/");
  } else {
    showToast(
      "Something went wrong. If this persists, please contact us.",
      "danger",
      6000
    );
  }
}

async function resetStandings() {
  await showAlert({
    header: "Are you sure you want to reset standings ?",
    buttons: [
      {
        text: "Cancel",
        role: "cancel",
        handler: () => {
          console.log("cancel");
        },
      },
      {
        text: "OK",
        role: "confirm",
        handler: async () => {
          const loading = await showLoading(
            "Resetting in progress. Please Wait..."
          );
          await sendResetRequest();
          loading.dismiss();
        },
      },
    ],
  });
}

async function sendResetRequest() {
  const result = await api
    .post("/api/reset-standings", {
      event_id: leagueStore.league.id,
    })
    .catch((err) => {
      console.log(err);
      showToast("Reset Failed. Please try again!", "danger", 6000);
    });
  if (result.data.success) {
    await leagueStore.reloadEverything(leagueStore.league.slug);
    showToast("Reset Successful!", "success", 6000);
  }
}

const showDesktopMode = ref(window.innerWidth >= 960 ? true : false);

window.addEventListener("resize", debounce(resizeHandler, 1000));

function resizeHandler() {
  if (window.innerWidth >= 960) {
    showDesktopMode.value = true;
  } else {
    showDesktopMode.value = false;
  }
}

onBeforeUnmount(() => {
  window.removeEventListener("resize", resizeHandler);
});

const copyRegistrationLink = () => {
  const el = document.createElement("textarea");
  el.value = `${window.location.origin}/league/${leagueStore.league.slug}/register`;
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
  showToast("Link Copied!", "success", 6000);
};

const hideScheduleTab = async ($event) => {
  console.log("event", $event);
  await api.post("/api/hide-tab/schedule", {
    event_id: leagueStore.league.id,
    tab: $event.detail.checked,
  });
  await leagueStore.reloadEverything(leagueStore.league.slug);
};

const hidePlayerDashboard = async ($event) => {
  console.log("event", $event);
  await api.post("/api/hide-tab/player-dashboard", {
    event_id: leagueStore.league.id,
    tab: $event.detail.checked,
  });
  await leagueStore.reloadEverything(leagueStore.league.slug);
};

const hidePublic = async ($event) => {
  // event publicitiy
  console.log('$event', $event)
  await api.post("/api/set-public", {
    event_id: leagueStore.league.id,
    tab: $event.detail.checked,
  });
  await leagueStore.reloadEverything(leagueStore.league.slug);
};

const hideStandingsTab = async ($event) => {
  console.log("event", $event);
  await api.post("/api/hide-tab/standings", {
    event_id: leagueStore.league.id,
    tab: $event.detail.checked,
  });
  await leagueStore.reloadEverything(leagueStore.league.slug);
};

const hideTeamsTab = async ($event) => {
  console.log("event", $event);
  await api.post("/api/hide-tab/teams", {
    event_id: leagueStore.league.id,
    tab: $event.detail.checked,
  });
  await leagueStore.reloadEverything(leagueStore.league.slug);
};

const hideGameMode = async ($event) => {
  console.log("event", $event);
  await api.post("/api/hide-tab/game-mode", {
    event_id: leagueStore.league.id,
    tab: $event.detail.checked,
  });
  await leagueStore.reloadEverything(leagueStore.league.slug);
};
</script>

<style scoped>
h3 {
  font-size: 18px;
  font-weight: 700;
  color: #fff;
}
</style>
