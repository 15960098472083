<template>
    <base-modal modal-title="Import Your Schedule" @paste="handlePaste" @drop.prevent="handleDrop" @dragover.prevent>
      <template #headerStart>
        <button class="btn" @click="cancel">Cancel</button>
      </template>
      <template #headerEnd>
        <button class="btn" @click="toggleHiddenRows">
          <ion-icon :icon="showHiddenRows ? eyeOutline : eyeOffOutline"></ion-icon>&nbsp;&nbsp;
          {{ showHiddenRows ? 'Hide' : 'Show' }} hidden rows
        </button>
        <button class="btn" @click="undo" :disabled="!canUndo">Undo</button>
        <button class="btn" @click="confirmClearSchedule">
          Clear Schedule
        </button>
        <button class="btn" @click="toggleVerifySchedule">
          {{ showVerifySchedule ? 'Hide' : 'Show' }} Games Checker
        </button>
      </template>
      <template #headerBelow>
        <div v-if="showVerifySchedule" class="verify-schedule-stats">
          <ion-row>
            <ion-col v-for="(division, divisionName) in sortedTeamStats" :key="divisionName" class="division-stats">
              <h6>{{ divisionName }}</h6>
              <div class="team-stats-table">
                <div class="team-stats-header">
                  <span @click="sortTeams(divisionName, 'name')">Team</span>
                  <span @click="sortTeams(divisionName, 'away')">Away</span>
                  <span @click="sortTeams(divisionName, 'home')">Home</span>
                  <span @click="sortTeams(divisionName, 'total')">Total</span>
                </div>
                <div v-for="team in division" :key="`${divisionName}-${team.name}`" 
                    class="team-stats-row" 
                    :style="{ borderLeft: `10px solid ${teamColors[`${team.name}-${divisionName}`]}` }"
                    @click="selectTeam(team.name, divisionName)"
                    :class="{ 'selected': selectedTeam === `${team.name}-${divisionName}` }">
                  <span>{{ team.name }}</span>
                  <span>{{ team.away }}</span>
                  <span>{{ team.home }}</span>
                  <span>{{ team.total }}</span>
                </div>
              </div>
            </ion-col>
          </ion-row>
        </div>
      </template>
      <div class="content" @paste="handlePaste" @drop.prevent="handleDrop" @dragover.prevent>
        <div v-if="scheduleData.length > 0" class="table">
          <!-- Header -->
          <div class="row header">
            <div class="cell toggle-cell"></div>
            <div v-for="(_, index) in Object.keys(scheduleData[0])" :key="index" 
                 class="cell"
                 :style="{ width: columnWidths[index] + 'px' }">
              <select v-model="columnMappings[index]" class="select" @change="validateColumn(index)">
                <option value="">Select column type</option>
                <option v-for="option in columnOptions" :key="option" :value="option">
                  {{ option }}
                </option>
              </select>
              <div class="resizer" @mousedown="startResize($event, index)"></div>
            </div>
          </div>
          <!-- Rows -->
          <div v-for="(row, rowIndex) in scheduleData" :key="rowIndex" 
            class="row"
            :class="{ 
            'ignored-row': ignoredRows[rowIndex],
            'highlighted-row': isTeamHighlighted(row)
            }"
            v-show="showHiddenRows || !ignoredRows[rowIndex]">
            <div class="cell toggle-cell">
              <button class="btn toggle-btn" @click="toggleRowIgnore(rowIndex)" :class="{ 'ignored': ignoredRows[rowIndex] }">
                <ion-icon :icon="ignoredRows[rowIndex] ? eyeOutline : eyeOffOutline"></ion-icon>
              </button>
            </div>
            <div v-for="(cell, cellIndex) in row" :key="cellIndex" 
                class="cell"
                :class="{ 
                    'invalid-cell': !ignoredRows[rowIndex] && !isValidCell(rowIndex, cellIndex),
                    'focused-column': focusedCell.col === cellIndex,
                    'away-team-cell': columnMappings[cellIndex] === 'away_team',
                    'home-team-cell': columnMappings[cellIndex] === 'home_team'
                }"
                :style="{ 
                    width: columnWidths[cellIndex] + 'px',
                    borderLeft: (columnMappings[cellIndex] === 'away_team' || columnMappings[cellIndex] === 'home_team') 
                                ? `5px solid ${teamColors[`${cell}-${row[Object.keys(columnMappings).find(key => columnMappings[key] === 'division_name')]}`] || 'transparent'}` 
                                : 'none'
                }">
              <div class="input-wrapper">
                <input
                    v-model="scheduleData[rowIndex][cellIndex]"
                    @input="validateCell(rowIndex, cellIndex)"
                    @focus="onCellFocus(rowIndex, cellIndex)"
                    @blur="onCellBlur"
                    @keydown="handleInputKeydown($event, rowIndex, cellIndex)"
                    :disabled="ignoredRows[rowIndex]"
                    class="input"
                    :data-row="rowIndex"
                    :data-col="cellIndex"
                    :ref="el => { if (el) setInputRef(el, rowIndex, cellIndex) }"
                />
                <ion-icon
                    v-if="showPickerIcon(columnMappings[cellIndex])"
                    :icon="getPickerIcon(columnMappings[cellIndex])"
                    class="picker-icon"
                    @click="openPicker(rowIndex, cellIndex, columnMappings[cellIndex])"
                ></ion-icon>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="drop-zone">
          <p>Paste your schedule data here or drag and drop a CSV file</p>
        </div>
      </div>
      <ion-popover class="picker-popover">
            <ion-datetime
            :presentation="columnMappings[cellIndex] === 'start_date' ? 'date' : 'time'"
            @ionChange="updateDateTimeValue($event, rowIndex, cellIndex)"
            ></ion-datetime>
        </ion-popover>
    </base-modal>
  </template>
  
  <script setup>
import { ref, defineComponent, onMounted, onUnmounted, watch, computed, onErrorCaptured, reactive } from "vue";
import BaseModal from "@/components/layouts/BaseModal.vue";
import { useRoute, useRouter } from "vue-router";
import { modalController, alertController, IonIcon, IonRow, IonCol, IonDatetime, IonPopover } from "@ionic/vue";
import { eyeOutline, eyeOffOutline, calendar, time } from "ionicons/icons";
import { nextTick } from 'vue';
import uniqolor from 'uniqolor';
import DateTimePicker from './DateTimePicker.vue';


defineComponent({
  name: "UploadScheduleModalv2",
});

const router = useRouter();
const route = useRoute();
const scheduleData = ref([]);
const ignoredRows = ref({});
const cellValidation = ref({});
const showHiddenRows = ref(true);
const columnWidths = ref([]);
const resizingColumn = ref(null);
const focusedCell = ref({ row: null, col: null });
const isScheduleEmpty = ref(true);
const undoStack = ref([]);
const redoStack = ref([]);
const canUndo = computed(() => undoStack.value.length > 0);
const canRedo = computed(() => redoStack.value.length > 0);
const debounceTimer = ref(null);
const isTyping = ref(false);
const inputRefs = ref({});
const showVerifySchedule = ref(false);
const selectedTeam = ref(null);

const teamStats = ref({});
const teamColors = reactive({});
const sortConfig = reactive({
  division: null,
  key: null,
  direction: 'asc'
});


function debounce(func, delay) {
  return (...args) => {
    clearTimeout(debounceTimer.value);
    isTyping.value = true;
    debounceTimer.value = setTimeout(() => {
      isTyping.value = false;
      func(...args);
    }, delay);
  };
}

const debouncedSaveState = debounce(saveState, 500);

const columnOptions = [
  "game_number",
  "division_name",
  "park_name",
  "diamond_signifier",
  "start_date",
  "start_time",
  "end_time",
  "away_team",
  "home_team",
  "best_of"
];

const columnTypeWidths = {
  game_number: 50,
  division_name: 80,
  park_name: 220,
  diamond_signifier: 100,
  start_date: 100,
  start_time: 100,
  end_time: 100,
  away_team: 220,
  home_team: 220,
  best_of: 80
};

const columnMappings = ref({});

function showPickerIcon(columnType) {
  return ['start_date', 'start_time', 'end_time'].includes(columnType);
}

function getPickerIcon(columnType) {
  return columnType === 'start_date' ? calendar : time;
}


async function openPicker(rowIndex, cellIndex, columnType) {
  console.log('Opening picker for:', columnType);
  const currentValue = scheduleData.value[rowIndex][cellIndex];
  console.log('Current value:', currentValue);
  let initialValue = currentValue;

  if (columnType === 'start_date') {
    initialValue = currentValue ? new Date(currentValue).toISOString() : new Date().toISOString();
  } else {
    const today = new Date().toISOString().split('T')[0];
    initialValue = currentValue ? `${today}T${convertTo24Hour(currentValue)}:00` : new Date().toISOString();
  }
  console.log('Initial value for picker:', initialValue);

  const modal = await modalController.create({
    component: DateTimePicker,
    cssClass: 'picker-modal',
    componentProps: {
      presentation: columnType === 'start_date' ? 'date' : 'time',
      modelValue: initialValue,
    },
  });

  modal.onDidDismiss().then(({ data, role }) => {
    console.log('Modal dismissed with role:', role);
    console.log('Modal dismissed data:', data);

    if (role === 'confirm' && data) {
      let formattedValue;
      if (columnType === 'start_date') {
        formattedValue = new Date(data).toISOString().split('T')[0];
      } else {
        formattedValue = new Date(data).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
      }
      console.log('Formatted value:', formattedValue);
      scheduleData.value[rowIndex][cellIndex] = formattedValue;
      validateCell(rowIndex, cellIndex);
    }
  });

  await modal.present();
}

function convertTo24Hour(time12h) {
  const [time, modifier] = time12h.split(' ');
  let [hours, minutes] = time.split(':');
  if (hours === '12') {
    hours = '00';
  }
  if (modifier === 'PM') {
    hours = parseInt(hours, 10) + 12;
  }
  return `${hours}:${minutes}`;
}


function onCellFocus(rowIndex, cellIndex) {
  focusedCell.value = { row: rowIndex, col: cellIndex };
}

function onCellBlur() {
  focusedCell.value = { row: null, col: null };
}

watch(() => ({ ...columnMappings.value }), (newMappings) => {
  Object.entries(newMappings).forEach(([index, columnType]) => {
    if (columnType && columnTypeWidths[columnType]) {
      columnWidths.value[index] = columnTypeWidths[columnType];
    }
  });
}, { deep: true });

function handlePaste(event) {
  event.preventDefault();
  const clipboardData = event.clipboardData.getData('text/plain');
  
  if (isScheduleEmpty.value) {
    processInitialPaste(clipboardData);
  } else {
    saveState(); // Immediate save for paste operations
    redoStack.value = []; // Clear redo stack for paste operations
    processCellPaste(clipboardData);
  }
}

function processInitialPaste(data) {
  const rows = data.split('\n').filter(row => row.trim() !== '');
  const parsedData = rows.map(row => {
    const columns = row.split(/[,\t]/); // Split by comma or tab
    return columns.reduce((acc, col, index) => {
      acc[index] = col.trim();
      return acc;
    }, {});
  });

  // Check if any row has less than 8 columns
  if (parsedData.some(row => Object.keys(row).length < 8)) {
    alert("Invalid schedule format. All rows must have at least 8 columns.");
    return;
  }
  
  scheduleData.value = parsedData;
  
  const firstRow = parsedData[0];
  const hasColumnAssignments = Object.values(firstRow).some(value => 
    columnOptions.includes(value.toLowerCase())
  );

  columnMappings.value = Object.keys(parsedData[0]).reduce((acc, _, index) => {
    if (hasColumnAssignments) {
      const columnValue = firstRow[index].toLowerCase();
      acc[index] = columnOptions.includes(columnValue) ? columnValue : "";
    } else {
      acc[index] = "";
    }
    return acc;
  }, {});

  ignoredRows.value = scheduleData.value.reduce((acc, _, index) => {
    acc[index] = index === 0 && hasColumnAssignments;
    return acc;
  }, {});

  cellValidation.value = scheduleData.value.reduce((acc, _, rowIndex) => {
    acc[rowIndex] = Object.keys(parsedData[0]).reduce((colAcc, _, colIndex) => {
      colAcc[colIndex] = true;
      return colAcc;
    }, {});
    return acc;
  }, {});

  Object.keys(columnMappings.value).forEach(index => validateColumn(index));

  columnWidths.value = Object.keys(parsedData[0]).map(() => 200);
  
  isScheduleEmpty.value = false;

  generateTeamColors();
}

function generateTeamColors() {
  const divisionIndex = Object.keys(columnMappings.value).find(key => columnMappings.value[key] === 'division_name');
  const awayTeamIndex = Object.keys(columnMappings.value).find(key => columnMappings.value[key] === 'away_team');
  const homeTeamIndex = Object.keys(columnMappings.value).find(key => columnMappings.value[key] === 'home_team');

  if (divisionIndex === undefined || awayTeamIndex === undefined || homeTeamIndex === undefined) {
    console.error('Required columns not found in columnMappings');
    return;
  }

  const seedRegex = /seed\s*\d+/i;

  scheduleData.value.forEach((row, index) => {
    if (ignoredRows.value[index]) return;

    const divisionName = row[divisionIndex];
    const awayTeam = row[awayTeamIndex];
    const homeTeam = row[homeTeamIndex];

    if (!divisionName || !awayTeam || !homeTeam) return;

    [awayTeam, homeTeam].forEach(team => {
      const key = `${team}-${divisionName}`;
      if (!teamColors[key]) {
        if (team.toLowerCase().startsWith('winner of')) {
          teamColors[key] = '#FFD700'; // Gold color
        } else if (team.toLowerCase().startsWith('loser of')) {
          teamColors[key] = '#C0C0C0'; // Silver color
        } else if (team.toLowerCase() === 'tba') {
          teamColors[key] = '#808080'; // Gray color for TBA
        } else if (!seedRegex.test(team.toLowerCase())) {
          teamColors[key] = uniqolor(key, { saturation: 80, lightness: [60, 70] }).color;
        }
      }
    });
  });
}

function processCellPaste(data) {
  if (focusedCell.value.row !== null && focusedCell.value.col !== null) {
    const pastedValue = data.trim();
    scheduleData.value[focusedCell.value.row][focusedCell.value.col] = pastedValue;
    validateCell(focusedCell.value.row, focusedCell.value.col);
  }
}
async function confirmClearSchedule() {
  const alert = await alertController.create({
    header: 'Clear Schedule',
    message: 'Are you sure you want to clear the schedule? This action cannot be undone.',
    buttons: [
      {
        text: 'Cancel',
        role: 'cancel',
      },
      {
        text: 'Clear',
        handler: () => {
          clearSchedule();
        },
      },
    ],
  });

  await alert.present();
}

function clearSchedule() {
  scheduleData.value = [];
  ignoredRows.value = {};
  cellValidation.value = {};
  columnMappings.value = {};
  columnWidths.value = [];
  isScheduleEmpty.value = true;
}



function toggleVerifySchedule() {
  showVerifySchedule.value = !showVerifySchedule.value;
  if (showVerifySchedule.value) {
    try {
      teamStats.value = getTeamStats();
    } catch (error) {
      console.error('Error in getTeamStats:', error);
    }
  }
}

function toggleRowIgnore(rowIndex) {
  ignoredRows.value[rowIndex] = !ignoredRows.value[rowIndex];
  Object.keys(columnMappings.value).forEach(index => validateColumn(index));
}

function validateCell(rowIndex, cellIndex) {
  debouncedSaveState();
  const columnType = columnMappings.value[cellIndex];
  if (!columnType) {
    return;
  }

  const cell = scheduleData.value[rowIndex][cellIndex];
  let isValid = true;
  let formattedValue = cell;
  let date, time;

  switch (columnType) {
    case 'start_date':
      date = parseAndFormatDate(cell);
      if (date) {
        formattedValue = date;
        isValid = true;
      } else {
        isValid = false;
      }
      break;
    case 'start_time':
    case 'end_time':
      time = parseAndFormatTime(cell);
      if (time) {
        formattedValue = time;
        isValid = true;
      } else {
        isValid = false;
      }
      break;
    case 'division_name':
    case 'park_name':
    case 'diamond_signifier':
    case 'away_team':
    case 'home_team':
      isValid = cell.trim() !== '';
      break;
    // ... other cases ...
  }

  cellValidation.value[rowIndex][cellIndex] = isValid;
  scheduleData.value[rowIndex][cellIndex] = formattedValue;
}


function validateColumn(columnIndex) {
  scheduleData.value.forEach((_, rowIndex) => {
    if (!ignoredRows.value[rowIndex]) {
      validateCell(rowIndex, columnIndex);
    }
  });

  const columnType = columnMappings.value[columnIndex];
  if (columnType && columnTypeWidths[columnType]) {
    columnWidths.value[columnIndex] = columnTypeWidths[columnType];
  }
}

function isValidCell(rowIndex, cellIndex) {
  return cellValidation.value[rowIndex]?.[cellIndex] !== false;
}

function parseAndFormatDate(dateString) {
  const formats = [
    { regex: /^(\d{4})-(\d{2})-(\d{2})$/, formatter: (y, m, d) => `${y}-${m}-${d}` },
    { regex: /^(\d{4})\/(\d{2})\/(\d{2})$/, formatter: (y, m, d) => `${y}-${m}-${d}` },
  ];

  for (const format of formats) {
    const match = dateString.match(format.regex);
    if (match) {
      const [, year, month, day] = match;
      const date = new Date(year, month - 1, day);
      if (date.getFullYear() == year && date.getMonth() == month - 1 && date.getDate() == day) {
        return format.formatter(year, month, day);
      }
    }
  }

  return null;
}


function handleDrop(event) {
  event.preventDefault();
  const file = event.dataTransfer.files[0];
  if (file && file.type === "text/csv") {
    const reader = new FileReader();
    reader.onload = (e) => {
      const content = e.target.result;
      processInitialPaste(content);
      generateTeamColors(); // Generate colors after processing the drop
    };
    reader.readAsText(file);
  } else {
    alert("Please drop a valid CSV file.");
  }
}

function parseAndFormatTime(timeString) {
  const formats = [
    { regex: /^(\d{1,2}):(\d{2})$/, formatter: (h, m) => formatTimeFor12Hour(h, m) },
    { regex: /^(\d{1,2}):(\d{2}):(\d{2})$/, formatter: (h, m) => formatTimeFor12Hour(h, m) },
    { regex: /^(\d{1,2}):(\d{2})\s*(AM|PM)$/i, formatter: (h, m, ampm) => {
      let hour = parseInt(h);
      if (ampm.toUpperCase() === 'PM' && hour < 12) hour += 12;
      if (ampm.toUpperCase() === 'AM' && hour === 12) hour = 0;
      return formatTimeFor12Hour(hour.toString(), m);
    }},
  ];

  for (const format of formats) {
    const match = timeString.match(format.regex);
    if (match) {
      const [, hours, minutes, seconds] = match;
      return format.formatter(hours, minutes, seconds);
    }
  }

  return null;
}

function formatTimeFor12Hour(hours, minutes) {
  let hour = parseInt(hours);
  const ampm = hour >= 12 ? 'PM' : 'AM';
  hour = hour % 12;
  hour = hour ? hour : 12;
  return `${hour.toString().padStart(2, '0')}:${minutes} ${ampm}`;
}

function toggleHiddenRows() {
  showHiddenRows.value = !showHiddenRows.value;
}

function startResize(event, columnIndex) {
  event.preventDefault();
  resizingColumn.value = columnIndex;
  window.addEventListener('mousemove', handleResize);
  window.addEventListener('mouseup', stopResize);
}

function handleResize(event) {
  if (resizingColumn.value !== null) {
    const newWidth = Math.max(100, event.clientX - event.target.getBoundingClientRect().left);
    columnWidths.value[resizingColumn.value] = newWidth;
  }
}

function stopResize() {
  resizingColumn.value = null;
  window.removeEventListener('mousemove', handleResize);
  window.removeEventListener('mouseup', stopResize);
}

async function cancel() {
  router.replace({
    query: {
      ...route.query,
      open_modal: "",
    },
  });
  await modalController.dismiss();
}

onMounted(() => {
  if (scheduleData.value.length > 0 && columnWidths.value.length === 0) {
    columnWidths.value = Object.keys(scheduleData.value[0]).map(() => 200);
  }
  window.addEventListener('keydown', handleKeyDown);
});

onUnmounted(() => {
  window.removeEventListener('mousemove', handleResize);
  window.removeEventListener('mouseup', stopResize);
  window.removeEventListener('keydown', handleKeyDown);
});

function handleKeyDown(event) {
  if ((event.metaKey || event.ctrlKey) && event.key === 'z') {
    event.preventDefault();
    if (event.shiftKey) {
      redo();
    } else {
      undo();
    }
  }
}


function saveState() {
  undoStack.value.push(JSON.parse(JSON.stringify(scheduleData.value)));
  if (!isTyping.value) {
    redoStack.value = [];
  }
}

function undo() {
  if (canUndo.value) {
    const currentState = JSON.parse(JSON.stringify(scheduleData.value));
    redoStack.value.push(currentState);
    const previousState = undoStack.value.pop();
    scheduleData.value = previousState;
    validateAllCells();
  }
}

function redo() {
  if (canRedo.value) {
    const currentState = JSON.parse(JSON.stringify(scheduleData.value));
    undoStack.value.push(currentState);
    const nextState = redoStack.value.pop();
    scheduleData.value = nextState;
    validateAllCells();
  }
}


function validateAllCells() {
  scheduleData.value.forEach((row, rowIndex) => {
    Object.keys(row).forEach((cellIndex) => {
      validateCell(rowIndex, parseInt(cellIndex), false);
    });
  });
}

function setInputRef(el, rowIndex, cellIndex) {
  if (!inputRefs.value[rowIndex]) {
    inputRefs.value[rowIndex] = {};
  }
  inputRefs.value[rowIndex][cellIndex] = el;
}

function handleInputKeydown(event, rowIndex, cellIndex) {
  if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
    event.preventDefault();
    const direction = event.key === 'ArrowUp' ? -1 : 1;
    const newRowIndex = findNextEditableRow(rowIndex, cellIndex, direction);
    console.log('newRowIndex', newRowIndex); // Debug log
    if (newRowIndex !== null) {
      focusCell(newRowIndex, cellIndex);
    }
  }
}

function findNextEditableRow(currentRowIndex, cellIndex, direction) {
  let newRowIndex = currentRowIndex + direction;
  
  while (newRowIndex >= 0 && newRowIndex < scheduleData.value.length) {
    if (!ignoredRows.value[newRowIndex]) {
      return newRowIndex;
    }
    newRowIndex += direction;
  }
  
  return null;
}

function focusCell(rowIndex, cellIndex) {
  console.log('Focusing cell:', rowIndex, cellIndex); // Debug log
  nextTick(() => {
    const input = inputRefs.value[rowIndex]?.[cellIndex];
    console.log('Found input:', input); // Debug log
    if (input) {
      input.focus();
      input.setSelectionRange(input.value.length, input.value.length); // Move cursor to end
    } else {
      console.log('Input not found, falling back to querySelector');
      const fallbackInput = document.querySelector(`input[data-row="${rowIndex}"][data-col="${cellIndex}"]`);
      if (fallbackInput) {
        fallbackInput.focus();
        fallbackInput.setSelectionRange(fallbackInput.value.length, fallbackInput.value.length);
      } else {
        console.log('Fallback input not found');
      }
    }
  });
}

function getTeamStats() {
  console.log('Getting team stats');
  const stats = {};
  try {
    const divisionIndex = Object.keys(columnMappings.value).find(key => columnMappings.value[key] === 'division_name');
    const awayTeamIndex = Object.keys(columnMappings.value).find(key => columnMappings.value[key] === 'away_team');
    const homeTeamIndex = Object.keys(columnMappings.value).find(key => columnMappings.value[key] === 'home_team');

    if (divisionIndex === undefined || awayTeamIndex === undefined || homeTeamIndex === undefined) {
      console.error('Required columns not found in columnMappings');
      return {};
    }

    const seedRegex = /seed\s*\d+/i;
    const excludedPrefixes = ['winner of', 'loser of', 'tba'];

    scheduleData.value.forEach((row, index) => {
      if (ignoredRows.value[index]) return;

      const divisionName = row[divisionIndex];
      const awayTeam = row[awayTeamIndex];
      const homeTeam = row[homeTeamIndex];

      if (!divisionName || !awayTeam || !homeTeam) return;

      if (!stats[divisionName]) {
        stats[divisionName] = {};
      }

      [
        { team: awayTeam, type: 'away' },
        { team: homeTeam, type: 'home' }
      ].forEach(({ team, type }) => {
        const isExcluded = excludedPrefixes.some(prefix => 
          team.toLowerCase().startsWith(prefix)
        );

        if (!seedRegex.test(team.toLowerCase()) && !isExcluded) {
          const key = `${divisionName}-${team}`;
          if (!stats[divisionName][key]) {
            stats[divisionName][key] = { name: team, away: 0, home: 0 };
          }
          stats[divisionName][key][type]++;
        }
      });
    });

    // Calculate totals
    Object.values(stats).forEach(division => {
      Object.values(division).forEach(team => {
        team.total = team.away + team.home;
      });
    });

    return stats;
  } catch (error) {
    console.error('Error in getTeamStats:', error);
    return {};
  }
}

function sortTeams(division, key) {
  if (sortConfig.division === division && sortConfig.key === key) {
    sortConfig.direction = sortConfig.direction === 'asc' ? 'desc' : 'asc';
  } else {
    sortConfig.division = division;
    sortConfig.key = key;
    sortConfig.direction = 'asc';
  }
}


const sortedTeamStats = computed(() => {
  const stats = getTeamStats();
  if (sortConfig.division && sortConfig.key) {
    const division = stats[sortConfig.division];
    const sortedDivision = Object.values(division).sort((a, b) => {
      if (sortConfig.key === 'name') {
        return sortConfig.direction === 'asc' 
          ? a.name.localeCompare(b.name)
          : b.name.localeCompare(a.name);
      } else {
        return sortConfig.direction === 'asc'
          ? a[sortConfig.key] - b[sortConfig.key]
          : b[sortConfig.key] - a[sortConfig.key];
      }
    });
    return { ...stats, [sortConfig.division]: sortedDivision };
  }
  return stats;
});


onErrorCaptured((err, instance, info) => {
  console.error('Captured error:', err, instance, info);
  return false;
});

function selectTeam(teamName, divisionName) {
  selectedTeam.value = selectedTeam.value === `${teamName}-${divisionName}` ? null : `${teamName}-${divisionName}`;
}

function isTeamHighlighted(row) {
  if (!selectedTeam.value) return false;
  
  const [teamName, divisionName] = selectedTeam.value.split('-');
  const divisionIndex = Object.keys(columnMappings.value).find(key => columnMappings.value[key] === 'division_name');
  const awayTeamIndex = Object.keys(columnMappings.value).find(key => columnMappings.value[key] === 'away_team');
  const homeTeamIndex = Object.keys(columnMappings.value).find(key => columnMappings.value[key] === 'home_team');
  
  return row[divisionIndex] === divisionName && 
         (row[awayTeamIndex] === teamName || row[homeTeamIndex] === teamName);
}

function updateDateTimeValue(event, rowIndex, cellIndex) {
  const columnType = columnMappings.value[cellIndex];
  let formattedValue;
  if (columnType === 'start_date') {
    formattedValue = new Date(event.detail.value).toISOString().split('T')[0];
  } else {
    formattedValue = new Date(event.detail.value).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  }
  scheduleData.value[rowIndex][cellIndex] = formattedValue;
  validateCell(rowIndex, cellIndex);
}

</script>

<style scoped>
@import './UploadScheduleModalv2.css';
</style>