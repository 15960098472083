<template>
  <page-wrapper>
    <div
      style="
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 0;
        opacity: 15%;
        background-position: center;
        background-size: cover;
      "
      :style="{
        'background-image': `url('${leagueStore.leagueBackground}')`,
      }"
    ></div>
    <teams-toolbar style="margin-top: 3rem"  />
    <div id="teamsPage1"></div>
    <teams-table
      v-for="(division, key) in teams"
      :key="key"
      :division="division"
    ></teams-table>
    <div id="teamsPage2"></div>
    <no-data-message v-if="teams.length === 0">
      No teams in the system... yet!
    </no-data-message>
    <!-- <new-team-fab v-if="authStore.isConvenor"></new-team-fab> -->
  </page-wrapper>
</template>

<script setup>
import { defineComponent, computed } from "vue";
import PageWrapper from "@/components/layouts/PageWrapper.vue";
import TeamsTable from "@/components/teams/TeamsTable.vue";
import { useLeagueStore } from "@/stores/leagueStore";
import { orderBy } from "lodash";
// import NewTeamFab from "@/components/teams/NewTeamFab.vue";
import NoDataMessage from "@/components/layouts/NoDataMessage.vue";
// import { useAuthStore } from "@/stores/authStore";
import TeamsToolbar from "../components/teams/TeamsToolbar.vue";
import { useHead } from "@unhead/vue";

defineComponent({
  name: "TeamsPage",
});

const leagueStore = useLeagueStore();

const pageTitle = computed(() => {
  return `Teams - ${leagueStore.leagueName} - ${leagueStore.leagueType}`
})

// Compute the page description
const pageDescription = computed(() => {
  return `View all teams participating in ${leagueStore.leagueName}, a ${leagueStore.leagueType} event.`
})

// Use useHead to set the metadata
useHead({
  title: pageTitle,
  meta: [
    { name: 'description', content: pageDescription },
    { property: 'og:title', content: pageTitle },
    { property: 'og:description', content: pageDescription },
    { name: 'twitter:title', content: pageTitle },
    { name: 'twitter:description', content: pageDescription },
  ],
})
// const authStore = useAuthStore();

const teams = computed(() => {
  var divisions = leagueStore.divisions;

  var i = 0;
  // calculate points
  for (i; i < divisions.length; i++) {
    var division = divisions[i];

    var teams = division.teams;

    var o = 0;

    for (o; o < teams.length; o++) {
      teams[o].points =
        parseInt(teams[o].wins) * parseInt(leagueStore.league.points_for_win) +
        parseInt(teams[o].losses) *
          parseInt(leagueStore.league.points_for_loss) +
        parseInt(teams[o].ties) * parseInt(leagueStore.league.points_for_tie);
      teams[o].plus_minus =
        parseInt(teams[o].runs_for) - parseInt(teams[o].runs_against);
    }

    divisions[i].teams = orderBy(
      division.teams,
      ["points", "wins", "losses", "plus_minus"],
      ["desc", "desc", "asc", "desc"]
    );
  }

  return divisions;
});
</script>
