<template>
  <ion-page>
    <ion-content>
      <sitewide-notice></sitewide-notice>
      <ion-header style="background: #333" v-if="!authStore.isLoggedIn">
        <div class="nav-container">
          <a @click="openLoginModal" class="nav-link">Login</a>
          <a @click="openRegisterModal" class="nav-link">Create Account</a>
        </div>
      </ion-header>
      <ion-header style="background: #333" v-if="authStore.isLoggedIn">
        <div class="nav-container">
          <a class="nav-link">Welcome, {{ authStore.user?.full_name }}</a>
          <router-link to="/account" class="nav-link">My Account</router-link>
          <!-- <router-link
            class="nav-link"
            :to="`/${event.leagueType}/${event.leagueSlug}`"
            v-for="event in recentEvents"
            :key="event.leagueId"
          >
            {{ event.leagueName.toUpperCase() }}
          </router-link> -->
        </div>
      </ion-header>
      <ion-header style="background: #7335dc">
        <div class="nav-container">
          <a href="#home" class="nav-link">Home</a>
          <a
            href=""
            class="nav-link"
            v-if="authStore.isLoggedIn"
            @click.prevent="openNewEventModal"
            >Create new League/Tournament</a
          >
          <a href="#find" class="nav-link">Find an League/Tournament</a>
          <a href="#featured" class="nav-link">Featured League/Tournament</a>
          <!-- <router-link to="/ballparks" class="nav-link"
            >Ballpark Directory</router-link
          > -->
          <!-- <router-link to="/posts" class="nav-link">Blog</router-link> -->
          <router-link to="/demos" class="nav-link">Demos</router-link>
          <!-- <router-link to="#faq" class="nav-link">FAQ</router-link> -->
          <a href="#faq" class="nav-link">FAQ</a>
          <a href="#contact" class="nav-link">Contact Us</a>
        </div>
        <div class="nav-mobile">
          <ion-toolbar class="header-toolbar">
            <ion-buttons slot="start">
              <ion-menu-button color="dark"></ion-menu-button>
            </ion-buttons>
            <ion-buttons slot="end">
              <a
                @click="openLoginModal"
                class="nav-link"
                v-if="!authStore.isLoggedIn"
                >Login</a
              >
              <a
                @click="openRegisterModal"
                class="nav-link"
                v-if="!authStore.isLoggedIn"
                >Create Account</a
              >
              <router-link
                to="/account"
                class="nav-link"
                v-if="authStore.isLoggedIn"
                >My Account</router-link
              >
            </ion-buttons>
          </ion-toolbar>
        </div>
      </ion-header>

      <div class="header-area" id="home">
        <div class="header-image">
          <img
            src="https://cdn.blacktiecollab.com/slopitchcentral/images/slopitchcentral.png"
            style="max-width: 300px; width: 70%"
            alt="Slo-Pitch Central Logo"
          />
        </div>
        <div class="header-text text-center">
          <h4>Track your schedule, teams, scores and standings with ease.</h4>
          <h4>THE RECOMMENDED PLATFORM FOR:</h4>
            <ion-row style="max-width: 500px; margin: 0 auto;">
              <ion-col>
                <a href="https://slo-pitch.com" target="_blank"
                  ><img
                    src="https://slo-pitch.com/static/media/SPNLogo2019-White.7f71accc.png"
                    style="height: 100px"
                    alt=""
                /></a>
              </ion-col>
              <ion-col>
                <a href="https://playslopitch.com" target="_blank"
                  ><img
                    src="https://cdn.blacktiecollab.com/playslopitch.com/PLAYSLOPITCH%20Grunge%20hoodie%20logo%20with%20paint%20Left%20Chest%20copy.png"
                    style="width: 100%; margin-top: 20px;"
                    alt=""
                /></a>
              </ion-col>
              <ion-col>
                <a href="https://playslopitch.com" target="_blank"
                  ><img
                    src="https://cdn.blacktiecollab.com/playslopitch.com/spo-logo.png"
                    style="height: 100px"
                    alt=""
                /></a>
              </ion-col>
            </ion-row>
          <div class="text-center">
            <card-wrapper
              cardTitle="Events with Games Upcoming"
              id="featured"
              cardSubtitle="Some of our most popularly viewed events."
            >
              <featured-events />
            </card-wrapper>
          </div>
          <card-wrapper
            cardTitle="Unleash your league or tournament here for free."
            id="featured"
            cardSubtitle="Get started for free. No credit card required."
          >
            <ion-button expand="full" style="font-size: 20px; text-transform: uppercase; margin-top: 20px;" @click.prevent="openNewEventModal"
                  >Create New League/Tournament</ion-button
                >
          </card-wrapper> 
        </div>
        
        <div class="header-text">
          <div class="text-center">
            <h1 style="text-transform: uppercase">
              
            </h1>
            
            <!-- add logos here -->
            <!-- <ion-row> -->
              <!-- <ion-col>
                <p><b>PLAYERS 👇🏻👇🏻</b></p>
                <ion-button color="tertiary" @click="openUserEventsModal"
                  >Find Your League/Tournament</ion-button
                >
              </ion-col> -->
              <!-- <ion-col v-if="!authStore.isLoggedIn"> -->
        
                <!-- <span>
                  <ion-button
                    class="register-buttons"
                    @click="openRegisterModal"
                  >
                    Register an Account
                  </ion-button>
                  <ion-button @click="openLoginModal"
                    >Login to Your Account</ion-button
                  >
                </span> -->
             
            <!-- </ion-row> -->

            
            
            <!-- <ion-button size="large" href="https://tally.so/r/mOlkGK">I NEED HELP ONBOARDING!</ion-button> -->
           
          </div>
          
          
          <img
              src="https://cdn.blacktiecollab.com/slopitchcentral/slopitchmock.png"
              alt="What Slo-Pitch Central looks like on desktop and mobile"
              style="max-width: 600px; margin: 0 auto; display: block;"
            />
          <!-- <card-wrapper
            id="find"
            cardTitle="Find an Event"
            cardSubtitle="Enter the shortcode you were given below to access the event."
          >
            <league-search
              :twoColumns="true"
              @eventFound="router.push(`/${$event.type}/${$event.slug}`)"
            />
          </card-wrapper> -->
         
          <card-wrapper
            cardTitle="Our Features"
            id="featured"
            cardSubtitle="What makes us different from the rest."
          >
            <ion-row>
              <ion-col size-md="4" size="12">
                <ion-card color="primary">
                  <ion-card-header>
                    <ion-card-title>Free To Use</ion-card-title>
                  </ion-card-header>
                  <ion-card-content>
                    <p>
                      We don't charge you to use our platform. We make money
                      through advertising and sponsorships.
                    </p>
                  </ion-card-content>
                </ion-card>
                <ion-card color="primary">
                  <ion-card-header>
                    <ion-card-title>Easy to Use</ion-card-title>
                  </ion-card-header>
                  <ion-card-content>
                    <p>
                      We've made it easy to use. You can create your league and
                      schedule in minutes.
                    </p>
                  </ion-card-content>
                </ion-card>
                <ion-card color="primary">
                  <ion-card-header>
                    <ion-card-title>Optimized for Readability</ion-card-title>
                  </ion-card-header>
                  <ion-card-content>
                    <p>
                      Colour coded teams, easy to read schedules, and standings
                      allow for any user of any age to use the platform with
                      ease.
                    </p>
                  </ion-card-content>
                </ion-card>
              </ion-col>
              <ion-col size-md="4" size="12">
                <ion-card color="primary">
                  <ion-card-header>
                    <ion-card-title
                      >Teams Can Report Their Own Scores</ion-card-title
                    >
                  </ion-card-header>
                  <ion-card-content>
                    <p>
                      Your teams can report their own scores. No need for
                      complex passwords or accounts. Set a shortcode for them
                      and they enter it once and it's saved forever.
                    </p>
                  </ion-card-content>
                </ion-card>
                <ion-card color="primary">
                  <ion-card-header>
                    <ion-card-title>Easy to Use Filters</ion-card-title>
                  </ion-card-header>
                  <ion-card-content>
                    <p>
                      Players can filter their schedule by team, division, or
                      date. Your last used filters are saved so you don't have to do it every time you open the app.
                    </p>
                  </ion-card-content>
                </ion-card>
                <ion-card color="primary">
                  <ion-card-header>
                    <ion-card-title>Use It How You Want To</ion-card-title>
                  </ion-card-header>
                  <ion-card-content>
                    <p>
                      We understand everyone uses these programs differently so
                      we've made it easy to use it how you want to.
                    </p>
                  </ion-card-content>
                </ion-card>
              </ion-col>
              <ion-col size-md="4" size="12">
                <ion-card color="primary">
                  <ion-card-header>
                    <ion-card-title>Playoff Mode</ion-card-title>
                  </ion-card-header>
                  <ion-card-content>
                    <p>
                      We have a playoff mode that allows you to set up and track
                      your playoff games.
                    </p>
                  </ion-card-content>
                </ion-card>
                <ion-card color="primary">
                  <ion-card-header>
                    <ion-card-title>Can Upload any CSV Schedule</ion-card-title>
                  </ion-card-header>
                  <ion-card-content>
                    <p>
                      Using a program to schedule your games? No problem. You
                      can upload any CSV schedule and we'll parse it and display
                      it for you.
                    </p>
                  </ion-card-content>
                </ion-card>
                <ion-card color="primary">
                  <ion-card-header>
                    <ion-card-title
                      >Reschedule, Postpone Games &
                      Announcements</ion-card-title
                    >
                  </ion-card-header>
                  <ion-card-content>
                    <p>
                      You can reschedule games, postpone games, and make
                      announcements to your players about these things quickly.
                    </p>
                  </ion-card-content>
                </ion-card>
              </ion-col>
            </ion-row>
          </card-wrapper>

          <card-wrapper
            cardTitle="Our Blog"
            id="featured"
            cardSubtitle="Interesting information on a variety of Slo-Pitch topics."
          >
            <ion-row class="ion-no-padding">
              <ion-col
                :key="key"
                v-for="(post, key) of posts.splice(0, 6)"
                size-sm="4"
                size="12"
              >
                <ion-card class="ion-no-padding ion-no-margin">
                  <img
                    :src="post.jetpack_featured_media_url"
                    :alt="
                      formattedTitle(post?.title?.rendered) + ' Title Image'
                    "
                  />
                  <ion-card-header>
                    <ion-card-title style="text-transform: none">
                      {{ formattedTitle(post?.title?.rendered) }}
                    </ion-card-title>
                  </ion-card-header>
                  <ion-card-content>
                    <div
                      class="text-excerpt"
                      v-html="post.excerpt.rendered"
                    ></div>
                    <br />
                    <ion-button
                      size="small"
                      expand="full"
                      :router-link="`/blog/${post.slug}`"
                    >
                      <!-- router-link="/league/2023-sunday-coed-league/schedule" -->
                      Read Now
                    </ion-button>
                  </ion-card-content>
                </ion-card>
              </ion-col>
            </ion-row>
          </card-wrapper>
        </div>
      </div>
      <div class="main-content">
        <ion-row>
          <!-- <ion-col size="12" size-sm="6">
            <card-wrapper
              style="margin: 1.5rem 0"
              v-if="authStore.isLoggedIn"
              cardTitle="New Event"
              cardSubtitle="Create a new league or tournament"
            >
              <new-event-component @new-event="eventsRefresh++" />
            </card-wrapper>
            <card-wrapper
              v-if="authStore.isLoggedIn"
              style="margin: 1.5rem 0"
              cardTitle="My Account"
              cardSubtitle="View Account Details"
            >
              <ion-button expand="block" @click="router.push('/account')">
                Go to Account
              </ion-button>
            </card-wrapper>
          </ion-col> -->
          <ion-col size="12" size-sm="12">
            <card-wrapper
              style="margin: 1.5rem 0"
              v-if="authStore.isLoggedIn"
              cardTitle="Welcome"
              :cardSubtitle="`${
                authStore.isLoggedIn ? authStore.user.full_name + `'s` : ''
              } Events`"
            >
              <user-events :key="eventsRefresh" />
            </card-wrapper>
          </ion-col>
        </ion-row>

        <card-wrapper id="faq" cardTitle="Frequently Asked Questions">
          <frequently-asked-questions />
        </card-wrapper>
        <card-wrapper
          cardTitle="Contact Us"
          id="contact"
          cardSubtitle="Send us a message and we'll respond ASAP."
        >
          <contact-us />
        </card-wrapper>
        <footer-component> </footer-component>
      </div>
    </ion-content>
  </ion-page>
</template>

<script setup>
import {
  defineComponent,
  ref,
  // computed,
  onBeforeUnmount,
  onMounted,
  watchEffect,
} from "vue";
import { debounce } from "lodash";

import {
  IonToolbar,
  IonHeader,
  onIonViewWillEnter,
  IonButton,
  IonRow,
  IonCol,
  IonPage,
  IonContent,
  // IonChip,
  // IonLabel,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonButtons,
  IonMenuButton,
} from "@ionic/vue";
import SitewideNotice from "@/components/header/SiteNotice.vue";
// import PageWrapper from "@/components/layouts/PageWrapper.vue";
import CardWrapper from "@/components/layouts/CardWrapper.vue";
// import LoginComponent from "@/components/login/LoginComponent.vue";
// import RegisterComponent from "@/components/register/RegisterComponent.vue";
// import NewEventComponent from "@/components/event/NewEventComponent.vue";
// import LeagueSearch from "@/components/landing/LeagueSearch.vue";
import UserEvents from "@/components/landing/UserEvents.vue";
import FrequentlyAskedQuestions from "@/components/landing/FrequentlyAskedQuestions.vue";
import ContactUs from "@/components/landing/ContactUs.vue";
import FeaturedEvents from "@/components/landing/FeaturedEvents.vue";
// import ForgotPasswordModal from "@/components/forgotPassword/ForgotPasswordModal.vue";
import { useAuthStore } from "@/stores/authStore";
import { useGameStore } from "@/stores/gameStore";
// import { useRouter } from "vue-router";
import { openModal } from "@/utils/useIonicComponents.js";
import LoginModal from "@/components/login/LoginModal.vue";
import FooterComponent from "@/components/landing/FooterComponent.vue";
// import RegisterModal from "@/components/register/RegisterModal.vue";
import AuthModal from "@/components/auth/AuthModal.vue";
import axios from "axios";
import he from "he";
// import { usePreferenceStore } from "@/stores/preferenceStore";
// import { useLeagueStore } from "@/stores/leagueStore";
import { useRoute } from "vue-router";
// import LeagueSwitchModal from "@/components/LeagueSwitchModal.vue";
import NewEventModal from "@/components/event/NewEventModal.vue";

const route = useRoute();
// const preferenceStore = usePreferenceStore();
// const leagueStore = useLeagueStore();
const showDesktopMode = ref(window.innerWidth >= 960 ? true : false);
defineComponent({
  name: "LandingPage",
});
const eventsRefresh = ref(0);
const posts = ref([]);
const authStore = useAuthStore();
const gameStore = useGameStore();
// const router = useRouter();
// const preferenceStore = usePreferenceStore();

onIonViewWillEnter(async () => {
  gameStore.resetFilters();
  eventsRefresh.value++;
  await grabBlogPosts();
  if (route.hash) {
    setTimeout(() => {
      const navItem = window.document.getElementById(
        route.hash.substring(1, route.hash.length)
      );
      if (navItem) {
        navItem.scrollIntoView();
      }
    }, 1);
  }
});

onMounted(async () => {
  await grabBlogPosts();
});

watchEffect(() => {
  // if (authStore.isLoggedIn && route.fullPath === "/") {
  //   setTimeout(() => {
  //     openUserEventsModal();
  //   }, 600);
  // }
});
// function openForgotPasswordModal() {
//   openModal(ForgotPasswordModal, "forgotPasswordModal");
// }

async function grabBlogPosts() {
  axios
    .get("https://wii.dnw.mybluehost.me/wp-json/wp/v2/posts")
    .then((results) => {
      // console.log("results", results);
      posts.value = results.data;
    });
}

const formattedTitle = (title) => {
  return he.decode(title);
};

async function openLoginModal() {
  await openModal(LoginModal, "loginModal");
}

async function openRegisterModal() {
  await openModal(AuthModal, "authMOdal");
}

// async function openUserEventsModal() {
//   await openModal(LeagueSwitchModal, "leagueSwitchModal");
// }

async function openNewEventModal() {
  if(!authStore.isLoggedIn) {
    await openModal(AuthModal, "authModal");
    return;
  }
  const response = await openModal(NewEventModal, "newEventModal");
  if (response === true) {
    eventsRefresh.value++;
  }
}

// const recentEvents = computed(() => {
//   if (route.fullPath !== "/") {
//     return preferenceStore.userRecentEvents
//       .filter((event) => event.leagueSlug !== leagueStore.league?.slug)
//       .slice(0, 3);
//   }
//   return preferenceStore.userRecentEvents.slice(0, 3);
// });

window.addEventListener("resize", debounce(resizeHandler, 1000));

function resizeHandler() {
  if (window.innerWidth >= 1100) {
    showDesktopMode.value = true;
  } else {
    showDesktopMode.value = false;
  }
}
onBeforeUnmount(() => {
  window.removeEventListener("resize", resizeHandler);
});
</script>
<style scoped>
/* .landing-content {
  width: 90%;
  margin: 0 auto;
  display: flex;
  align-items: start;
  justify-content: space-around;
}
.landing-content-child {
  min-height: 10rem;
  min-width: 35rem;
} */

.header-area {
  width: 100%;
  display: block;
  position: relative !important;
}

/* .background {
  --background: url(https://cdn.blacktiecollab.com/slopitchcentral/images/background2.png) center center no-repeat;
  --background-size: cover;
} */
.header-image {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    360deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 30%,
    rgba(125, 56, 240, 0.6) 80%,
    rgba(125, 56, 240, 0.9) 100%
  );
  text-align: center;
}
.header-text {
  max-width: 900px;
  margin: 0 auto;
}

.main-content {
  display: block;
  max-width: 800px;
  margin: 0 auto;
}

.text-center {
  text-align: center;
}

ion-card-title {
  font-size: 18px;
}

.nav-container {
  display: flex;
  justify-content: center;

  @media all and (max-width: 1000px) {
    display: none;
  }
}

.nav-mobile {
  display: none;
  @media all and (max-width: 1000px) {
    display: flex;

    width: 100%;
    margin: 0 auto;
  }
}

.nav-link {
  flex-grow: 1;
  text-align: center;
  padding: 10px;
  box-sizing: border-box;
  max-width: 300px;
  color: #fff;
  font-size: 14px;
  text-decoration: none;
  font-weight: 700;
  text-transform: uppercase;
  transition: background-color 0.3s ease;
}

.nav-link:hover {
  background-color: #5a27a8; /* Change this color to the desired hover color */
}

@media only screen and (max-width: 1250px) {
  /* .landing-content {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .landing-content-child {
    width: 90%;
    min-width: 20rem;
    max-width: 35rem;
  } */
}

.text-excerpt {
  height: 60px;
  overflow: hidden;
}

.feature-card {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.feature-card ion-card-header {
  flex-shrink: 0;
}

.feature-card ion-card-content {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.feature-card ion-card-content p {
  margin: 0;
}
</style>
