<template>
    <ion-list class="ion-list">
      <h1 class="title">
        <span class="title-text">All Events</span>
      </h1>
      <span v-if="authStore.isLoggedIn">
        <ion-searchbar v-model="searchTerm" placeholder="Search events" />
        <ion-row class="pagination-controls" v-if="filteredEvents.length > itemsPerPage">
          <ion-col>
            <ion-button fill="clear" @click="changePage('prev')" :disabled="currentPage === 1">
              <ion-icon :icon="chevronBackOutline" />
              Previous
            </ion-button>
          </ion-col>
          <ion-col class="ion-text-center">
            <ion-label>Page {{ currentPage }} of {{ totalPages }}</ion-label>
          </ion-col>
          <ion-col class="ion-text-right">
            <ion-button fill="clear" @click="changePage('next')" :disabled="currentPage === totalPages">
              Next
              <ion-icon :icon="chevronForwardOutline" />
            </ion-button>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col 
            size="12"
            v-for="event in paginatedEvents"
            :key="event.id"
            @click="
              $emit('eventFound', {
                slug: event.slug,
                type: event.type,
              })
            "
          >
            <div class="custom-card">
              <div class="card-content">
                <div class="text-content">
                  <h2>{{ event.name }}</h2>
                  <p>{{ event.city }} - Created on {{ event.created_at }}</p>
                </div>
                <div class="arrow-container">
                  <ion-icon :icon="chevronForward" />
                </div>
              </div>
            </div>
          </ion-col>
        </ion-row>
        <ion-item v-if="searchTerm && filteredEvents.length === 0">
          <ion-label>{{ searchTerm }} not found</ion-label>
        </ion-item>
        <ion-item v-if="!userEvents?.length">
          <ion-label>{{ authStore.user?.full_name }} has no Events</ion-label>
          <ion-button @click="openNewEventModal">Create New League/Tournament</ion-button>
        </ion-item>
        
      </span>
      <ion-list-header v-if="preferenceStore.userShortCodes.length > 0">
        <ion-label>Events from your teams</ion-label>
      </ion-list-header>
      <IonItem
        button
        v-for="shortcode in preferenceStore.userShortCodes"
        :key="shortcode.shortcode"
        @click="
          emit('eventFound', {
            slug: shortcode.leagueSlug,
            type: shortcode.leagueType,
          })
        "
      >
        <ion-label>{{ shortcode.leagueName.toUpperCase() }}</ion-label>
      </IonItem>
    </ion-list>
  </template>
  
  <script setup>
  import { defineComponent, ref, defineEmits, computed, watchEffect, nextTick } from "vue";
  import {
    IonItem,
    IonLabel,
    IonButton,
    IonSearchbar,
    IonList,
    IonListHeader,
    IonIcon,
    IonRow,
    IonCol
  } from "@ionic/vue";
  import { chevronForward, chevronBackOutline, chevronForwardOutline } from 'ionicons/icons';
  import { useAuthStore } from "@/stores/authStore";
  import { api } from "@/utils/axios";
  import { openModal } from "@/utils/useIonicComponents.js";
  import { usePreferenceStore } from "@/stores/preferenceStore";
  import NewEventModal from "@/components/event/NewEventModal.vue";
  
  defineComponent({
    name: "AllEvents",
  });
  const emit = defineEmits(["userNoEvent", "eventFound"]);
  
  const authStore = useAuthStore();
  const preferenceStore = usePreferenceStore();
  
  const userEvents = ref([]);
  const loading = ref(false);
  const searchTerm = ref('');
  const currentPage = ref(1);
  const itemsPerPage = 5;
  
  watchEffect(() => {
    if (authStore.isLoggedIn) {
      getUserEvents();
    }
  });
  
  async function getUserEvents() {
    loading.value = true;
    try {
      const results = await api.get(`/api/every-event`);
      userEvents.value = results.data.events;
      console.log('userEvents', userEvents.value);
      if (!userEvents.value.length) {
        emit("userNoEvent");
      }
    } catch (error) {
      console.error('Error fetching events:', error);
    } finally {
      loading.value = false;
    }
  }
  
  async function openNewEventModal() {
    const response = await openModal(NewEventModal, "newEventModal");
    if (response === true) {
      getUserEvents();
    }
  }
  
  const filteredEvents = computed(() => {
    if (!userEvents.value) return [];
    if (!searchTerm.value) return userEvents.value;
    
    const lowercaseSearchTerm = searchTerm.value.toLowerCase();
    return userEvents.value.filter(event => 
      event.name.toLowerCase().includes(lowercaseSearchTerm) ||
      (event.city && event.city.toLowerCase().includes(lowercaseSearchTerm)) ||
      event.slug.toLowerCase().includes(lowercaseSearchTerm)
    );
  });
  
  const totalPages = computed(() => Math.ceil(filteredEvents.value.length / itemsPerPage));
  
  const paginatedEvents = computed(() => {
    const startIndex = (currentPage.value - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return filteredEvents.value.slice(startIndex, endIndex);
  });
  
  function changePage(direction) {
    const eventList = document.querySelector('.ion-list');
    const currentScrollPosition = eventList.scrollTop;
  
    if (direction === 'next' && currentPage.value < totalPages.value) {
      currentPage.value++;
    } else if (direction === 'prev' && currentPage.value > 1) {
      currentPage.value--;
    }
  
    nextTick(() => {
      eventList.scrollTop = currentScrollPosition;
    });
  }
  
  // Reset to first page when search term changes
  watchEffect(() => {
    currentPage.value = 1;
  });
  </script>
  
  <style scoped>
  ion-item {
    --background: #191919;
  }
  
  .title {
    color: var(--ion-color-dark);
    text-align: center;
    text-transform: uppercase;
  }
  
  .title-text {
    background-color: var(--ion-color-primary);
    padding: 0 10px;
  }
  
  .custom-card {
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
    background-color: #494949;
  }
  
  .custom-card:hover {
    background: #232323;
  }
  
  .card-content {
    display: flex;
    align-items: stretch;
    min-height: 80px;
  }
  
  .text-content {
    flex-grow: 1;
    padding: 12px;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .text-content h2 {
    margin: 0 0 4px 0;
    font-size: 18px;
    font-weight: bold;
    text-align: left;
  }
  
  .text-content p {
    margin: 0;
    font-size: 14px;
    text-align: left;
  }
  
  .arrow-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    flex-shrink: 0;
    background-color: rgba(0, 0, 0, 0.4);
  }
  
  .arrow-container ion-icon {
    font-size: 24px;
    color: white;
  }
  
  .pagination-controls {
    margin-top: 20px;
  }
  
  .ion-list {
    max-height: 70vh;
    overflow-y: auto;
  }
  
  @media (max-width: 576px) {
    .pagination-controls ion-button {
      font-size: 12px;
    }
  }
  </style>