import { defineStore } from "pinia";
import { ref, computed } from "vue";
import jwt_decode from "jwt-decode";
import { api } from "@/utils/axios";
// import router from "@/router/index";
import { showToast, showAlert } from "@/utils/useIonicComponents";
import { useLeagueStore } from "@/stores/leagueStore";

export const useAuthStore = defineStore("auth", () => {
  const user = ref(null);

  const isLoggedIn = computed(() => {
    if (user.value) {
      return user.value.id;
    } else {
      return false;
    }
  });

  const isOwner = computed(() => {
    const leagueStore = useLeagueStore();
    if (
      user.value?.id === leagueStore.league?.convenor_id ||
      user.value?.id === 1
    ) {
      return true;
    }
    return false;
  });
  const isConvenor = computed(() => {
    console.log("isOwner", isOwner.value);
    if (isOwner.value) {
      return true;
    }
    const leagueStore = useLeagueStore();
    if (leagueStore?.league?.convenors.includes(user.value?.id)) {
      return true;
    }

    return false;
  });

  const isAdmin = computed(() => {
    
    if (user.value?.id === 1) {
      return true;
    } else {
      return false;
    }
  });

  async function login({ email, password }) {
    try {
      const result = await api.post(`/auth/attempt`, { email, password });
      const decodedValue = jwt_decode(result.data.access_token);
      user.value = decodedValue.user;
      showToast("Login Successful", "success");
      return true;
    } catch (err) {
      showToast("Login failed. Please Check Your Credentials", "danger", 6000);
      console.log(err);
      return false;
    }
  }

  async function logout() {
    const input = await showAlert({
      header: "Logout",
      message: "Are your sure you want to logout ?",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
        },
        {
          text: "Logout",
          role: "confirm",
          cssClass: "alert-button-logout",
        },
      ],
    });
    if (input.role === "confirm") {
      api
        .post(`/auth/logout`)
        .then(() => {
          user.value = null;
          // router.push("/");
          showToast("Logged Out", "success", 6000);
        })
        .catch(() => {
          showToast("Logging Out Failed", "danger", 4000);
        });
    }
  }

  async function refreshToken() {
    try {
      const result = await api.post(`/auth/refresh`);
      if (result.data) {
        const decodedValue = jwt_decode(result.data.access_token);
        user.value = decodedValue.user;
      } else {
        throw "No Token";
      }
    } catch (err) {
      if (user.value) {
        api
          .post(`/auth/logout`)
          .then(() => {
            user.value = null;
            showToast("Logged Out", "success", 6000);
          })
          .catch(() => {
            showToast("Logging Out Failed", "danger", 4000);
          });
      }
    }
  }

  async function changePassword({ password, newPassword }) {
    try {
      await api.post("/auth/change-password", {
        password,
        newPassword,
      });
      showToast("Password reset successfully, please log in.", "success");
      logout();
    } catch (err) {
      showToast("Password Change Failed. Please try again!", "danger");
    }
  }

  return {
    user,
    isLoggedIn,
    isConvenor,
    isAdmin,
    isOwner,
    login,
    logout,
    refreshToken,
    changePassword,
  };
});
