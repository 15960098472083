<template>
  <base-modal modal-title="Add New Team" @close="close">
    <!-- <template #headerStart>
      <ion-button @click="close"> Close </ion-button>
    </template> -->
    <template #headerEnd>
      <!-- <ion-label style="margin-right: 1rem">Onboard</ion-label>
      <ion-checkbox v-model="team.onboard"></ion-checkbox> -->
    </template>
    <ion-list>
        <ion-item>
          <ion-label position="stacked">Name*</ion-label>
          <ion-input v-model="team.name" @ionChange="setColor"></ion-input>
        </ion-item>
        <ion-item>
          <ion-label position="stacked">Shortcode*</ion-label>
          <ion-input v-model="team.shortcode"></ion-input>
        </ion-item>
        <ion-item>
          <ion-label position="stacked">Division*</ion-label>
          <v-select
            :options="existingDiv"
            :clearable="false"
            append-to-body
            style="width: 100%"
            :calculate-position="withPopper"
            v-model="team.division_name"
          >
            <template #open-indicator="{ attributes }">
              <ion-icon :icon="caretDownOutline" v-bind="attributes"></ion-icon>
            </template>
            <template v-slot:no-options="{ search }">
              <div v-on:click="addToDivision(search)">
                Create <b>{{ search && `${search}?` }}</b>
              </div>
            </template>
          </v-select>
        </ion-item>
        
        <ion-item>
          <ion-label position="stacked">Coach Full Name</ion-label>
          <ion-input v-model="team.coach_full_name"></ion-input>
        </ion-item>
        <ion-item>
          <ion-label position="stacked">Coach Email</ion-label>
          <ion-input v-model="team.coach_email"></ion-input>
        </ion-item>
        <ion-item>
          <ion-label position="stacked">Coach Phone</ion-label>
          <ion-input v-model="team.coach_phone"></ion-input>
        </ion-item>
        
          <VSwatches
            class="color-swatch"
            v-model="team.color"
            swatches="text-advanced"
            show-fallback
            fallback-input-type="color"
            popover-y="top"
          />
      </ion-list>
    <template #footer>
      <ion-row>
        <ion-col>
          <ion-button expand="block" @click="saveTeam()">Save Team</ion-button>
        </ion-col>
        <ion-col>
          <ion-button
            color="success"
            expand="block"
            @click="saveTeamAndAddAnother"
            >Save Team and Add Another</ion-button
          >
        </ion-col>
      </ion-row>
    </template>
  </base-modal>
</template>
<script setup>
import BaseModal from "@/components/layouts/BaseModal.vue";
import {
  IonCol,
  IonRow,
  // IonGrid,
  // IonCheckbox,
  IonLabel,
  // IonCard,
  // IonCardContent,
  IonInput,
  IonButton,
  modalController,
  IonIcon,
} from "@ionic/vue";
import { caretDownOutline } from "ionicons/icons";
import { defineComponent, ref } from "vue";
import { VSwatches } from "vue3-swatches";
import "vue3-swatches/dist/style.css";
import uniqolor from "uniqolor";
import useVuelidate from "@vuelidate/core";
import { required, numeric, email } from "@vuelidate/validators";
import { showToast, showLoading } from "@/utils/useIonicComponents.js";
import { api } from "@/utils/axios";
import { useLeagueStore } from "@/stores/leagueStore";
import { useRouter, useRoute } from "vue-router";
import { watch } from "vue";
import { createPopper } from "@popperjs/core";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

defineComponent({
  name: "NewTeamModal",
});
const team = ref({ onboard: false, color: uniqolor.random().color });
const leagueStore = useLeagueStore();
const router = useRouter();
const route = useRoute();
const existingDiv = ref(
  leagueStore.divisions.map((division) => division.division_name)
);

team.value.division_name = existingDiv.value[0];

router.replace({
  query: {
    ...route.query,
    open_modal: "NewTeam",
  },
});

const rules = {
  name: { required },
  division_name: { required },
  shortcode: { required },
  coach_email: { email },
  coach_phone: { numeric },
  amount_paid: { numeric },
  color: { required },
};

watch(
  () => team.value.name,
  (newName) => {
    team.value.shortcode = newName.toUpperCase().replace(/[\s-]/g, "");
  }
);

const v$ = useVuelidate(rules, team);

async function saveTeam() {
  const isFormValid = await v$.value.$validate();
  if (isFormValid) {
    await saveTeamRequest({ hasAnother: false });
  } else {
    showToast("Invalid Inputs!", "danger", 6000);
  }
}

async function saveTeamRequest({ hasAnother = false }) {
  const loading = await showLoading("Saving Teams. Please Wait...");

  team.value.shortcode = team.value.shortcode.toUpperCase();

  const result = await api.post("/api/store-team", {
    event: leagueStore.league.id,
    team: team.value,
  });
  loading.dismiss();

  if (result.data.success) {
    await leagueStore.reloadEverything(leagueStore.league.slug);
    console.log("hasAnother", hasAnother);
    if (hasAnother) {
      resetForm();
    } else {
      await close();
    }
    showToast("Team successfully Added.", "success", 6000);
  } else {
    showToast(
      "Something went wrong. If this persists, please contact us.",
      "danger",
      6000
    );
  }
}

function addToDivision(div) {
  existingDiv.value.push(div);
  team.value.division_name = div;
}

async function close() {
  router.replace({
    query: {
      ...route.query,
      open_modal: "",
    },
  });
  await modalController.dismiss();
}

async function saveTeamAndAddAnother() {
  const isFormValid = await v$.value.$validate();
  if (isFormValid) {
    await saveTeamRequest({ hasAnother: true });
  } else {
    showToast("Invalid Inputs!", "danger", 6000);
  }
}

function resetForm() {
  team.value = {
    onboard: false,
    color: uniqolor.random().color,
    name: "",
    division_name: "",
    shortcode: "",
    coach_full_name: "",
    coach_email: "",
    coach_phone: "",
    amount_paid: null,
  };
  // If you need to emit to the parent, you can do it here
  // emit('reset');
}

function setColor() {
  team.value.color = uniqolor(team.value.name).color;
}
function withPopper(dropdownList, component) {
  dropdownList.style.width = "30rem";
  const popper = createPopper(component.$refs.toggle, dropdownList, {
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, -1],
        },
      },
      {
        name: "toggleClass",
        enabled: true,
        phase: "write",
        fn({ state }) {
          component.$el.classList.toggle("drop-up", state.placement === "top");
        },
      },
    ],
  });

  return () => popper.destroy();
}
</script>
<style scoped>
.teams-input {
  border-bottom: 3px solid var(--ion-color-medium);
  text-align: center;
}
.color-swatch {
  margin-left: 1rem;

}
@media only screen and (max-width: 600px) {
  .color-swatch {
    margin-left: 2.5rem;
    margin-top: 1rem;
  }
}
</style>
<style scoped>
.v-select {
  --vs-controls-color: var(--ion-color-step-300);
  --vs-border-width: 0;

  --vs-dropdown-bg: var(--ion-background-color);
  --vs-dropdown-option-color: var(--ion-text-color);

  --vs-selected-bg: var(--ion-color-primary);
  --vs-selected-color: var(--ion-text-color);

  --vs-search-input-color: var(--ion-color-step-300);

  --vs-dropdown-option--active-bg: #664cc3;
  --vs-dropdown-option--active-color: #eeeeee;
  --vs-dropdown-min-width: 1rem;
  --vs-dropdown-option--deselect-color: red;

  --vs-disabled-bg: var(--ion-color-light);
  --vs-disabled-color: var(--ion-color-light);
  --vs-disabled-controls-color: var(--ion-color-light);
}
/* .vs__selected-options {
  flex-direction: row-reverse;
}
.vs--open .vs__selected-options {
  flex-direction: row;
}
.vs--open .vs__selected {
  flex-grow: 0;
} */
</style>
