<template>
  <base-modal modal-title="Import your Teams">
    <!-- <template #headerStart>
      <ion-button @click="cancel"> Cancel </ion-button>
    </template> -->
    <ion-nav :root="TeamsUploadComponent"></ion-nav>
  </base-modal>
</template>

<script setup>
import { defineComponent } from "vue";
import { IonNav } from "@ionic/vue";
import BaseModal from "@/components/layouts/BaseModal.vue";
import TeamsUploadComponent from "@/components/teams/import/TeamsUploadComponent.vue";
import { useRoute, useRouter } from "vue-router";

defineComponent({
  name: "TeamsImportModal",
});
const router = useRouter();
const route = useRoute();
router.replace({
  query: {
    ...route.query,
    open_modal: "TeamsImport",
  },
});

// async function cancel() {
//   router.replace({
//     query: {
//       ...route.query,
//       open_modal: "",
//     },
//   });
//   await modalController.dismiss();
// }
</script>
