<template>
  <base-modal modal-title="Set Sort Order for Divisions" @close="close">
    <sort-by-component 
      :items="sortableDivisions" 
      @updateOrder="handleUpdateOrder"
    />
    <template #footer>
      <ion-button expand="full" @click="saveSortOrder">Save</ion-button>
    </template>
  </base-modal>
</template>

<script setup>
import BaseModal from "@/components/layouts/BaseModal.vue";
import SortByComponent from "@/components/schedule/sort-by/SortByComponent.vue";
import { defineComponent, ref, onMounted } from "vue";
import { IonButton, modalController } from "@ionic/vue";
import { useRouter, useRoute } from "vue-router";
import { useLeagueStore } from "@/stores/leagueStore";
import { api } from '@/utils/axios'

defineComponent({
  name: "DivisionSortModal",
});

const leagueStore = useLeagueStore()
const sortableDivisions = ref([]);

const router = useRouter();
const route = useRoute();

onMounted(() => {
  console.log('leagueStore.division_sort:', leagueStore.league.division_sort);
  if (leagueStore.league.division_sort) {
    try {
      const parsedSort = JSON.parse(leagueStore.league.division_sort);
      if (Array.isArray(parsedSort) && parsedSort.length > 0) {
        // Use existing sort order if available and valid
        sortableDivisions.value = parsedSort;
      } else {
        throw new Error('Invalid sort order');
      }
    } catch (error) {
      console.error('Error parsing division_sort:', error);
      // Fallback to default order if parsing fails
      sortableDivisions.value = leagueStore.divisions.map(division => division.division_name);
    }
  } else {
    // Fallback to default order if no existing sort order
    sortableDivisions.value = leagueStore.divisions.map(division => division.division_name);
  }
  
  router.replace({
    query: {
      ...route.query,
      open_modal: "DivisionSort",
    },
  });
});

const handleUpdateOrder = (newOrder) => {
  console.log("New order:", newOrder);
  sortableDivisions.value = newOrder;
};

async function close() {
  router.replace({
    query: {
      ...route.query,
      open_modal: "",
    },
  });
  await modalController.dismiss();
}

async function saveSortOrder() {
  console.log('Saving sort order:', sortableDivisions.value);
  await api.post("/api/set-division-sort-order", {
    eventId: leagueStore.league.id,
    sortOrder: JSON.stringify(sortableDivisions.value),
  });
  close();
}
</script>