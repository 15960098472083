<template>
  <base-modal modal-title="Edit Teams">
    <!-- <template #headerStart>
      <ion-button @click="close"> Close </ion-button>
    </template> -->
    <template #headerEnd>
      <ion-button @click="confirmDelete" color="danger">
        Delete Team
      </ion-button>
    </template>
    <ion-card style="min-width: 20rem; padding: 0; margin: 0">
      <ion-card-content>
        <ion-grid>
          <ion-row>
            <ion-col><ion-label>Name</ion-label></ion-col>
            <ion-col
              ><ion-input
                class="teams-input"
                v-model="team.name"
                @ionChange="setColor"
              ></ion-input
            ></ion-col>
          </ion-row>
          <ion-row>
            <ion-col class="ion-align-self-center label-left-col"
              ><ion-label>Shortcode</ion-label></ion-col
            >
            <ion-col
              ><ion-input
                class="teams-input"
                v-model="team.shortcode"
              ></ion-input
            ></ion-col>
          </ion-row>
          <ion-row>
            <ion-col class="ion-align-self-center label-left-col"
              ><ion-label>Coach Full Name</ion-label></ion-col
            >
            <ion-col
              ><ion-input
                class="teams-input"
                v-model="team.coach_full_name"
              ></ion-input
            ></ion-col>
          </ion-row>
          <ion-row>
            <ion-col class="ion-align-self-center label-left-col"
              ><ion-label>Coach Email</ion-label></ion-col
            >
            <ion-col
              ><ion-input
                class="teams-input"
                v-model="team.coach_email"
              ></ion-input
            ></ion-col>
          </ion-row>
          <ion-row>
            <ion-col class="ion-align-self-center label-left-col"
              ><ion-label>Coach Phone</ion-label></ion-col
            >
            <ion-col
              ><ion-input
                class="teams-input"
                v-model="team.coach_phone"
              ></ion-input
            ></ion-col>
          </ion-row>
          <ion-row>
            <ion-col class="ion-align-self-center label-left-col"
              ><ion-label>Amount Paid</ion-label></ion-col
            >
            <ion-col
              ><ion-input
                class="teams-input"
                v-model="team.amount_paid"
              ></ion-input
            ></ion-col>
          </ion-row>
          <ion-row>
            <ion-col class="ion-align-self-center label-left-col"
              ><ion-label>Team Color</ion-label></ion-col
            >
            <ion-col
              ><VSwatches
                class="color-swatch"
                v-model="team.color"
                swatches="text-advanced"
                show-fallback
                fallback-input-type="color"
                popover-y="top"
            /></ion-col>
          </ion-row>
        </ion-grid>
      </ion-card-content>
    </ion-card>
    <ion-card
      style="min-width: 20rem; padding: 0; margin: 1rem 0"
      v-if="teams.length > 0"
    >
      <ion-card-content>
        <ion-row>
          <ion-col class="ion-align-self-center label-left-col"
            ><ion-label>Merge this team with another</ion-label></ion-col
          >
          <ion-col
            ><ion-select placeholder="Select Team" v-model="mergeTeam">
              <ion-select-option
                v-for="team in teams"
                :key="team.id"
                :value="team.id"
                >{{ team.name }}</ion-select-option
              >
            </ion-select></ion-col
          >
        </ion-row>
      </ion-card-content>
    </ion-card>
    <template #footer>
      <ion-button expand="full" @click="saveTeam">Save</ion-button>
    </template>
  </base-modal>
</template>
<script setup>
import BaseModal from "@/components/layouts/BaseModal.vue";
import {
  IonCol,
  IonRow,
  IonGrid,
  IonLabel,
  IonCard,
  IonCardContent,
  IonInput,
  IonButton,
  modalController,
  IonSelect,
  IonSelectOption,
} from "@ionic/vue";
import { defineComponent, defineProps, ref, computed } from "vue";
import useVuelidate from "@vuelidate/core";
import { required, numeric, email } from "@vuelidate/validators";
import {
  showToast,
  showAlert,
  showLoading,
} from "@/utils/useIonicComponents.js";
import { api } from "@/utils/axios";
import { useRouter, useRoute } from "vue-router";
import { useLeagueStore } from "@/stores/leagueStore";
import { VSwatches } from "vue3-swatches";
import "vue3-swatches/dist/style.css";
import uniqolor from "uniqolor";

defineComponent({
  name: "EditTeamsModal",
});
const props = defineProps({ team: Object, division: String });
console.log("props", props);
const team = ref({ ...props.team });
const leagueStore = useLeagueStore();
const mergeTeam = ref();

const router = useRouter();
const route = useRoute();
router.replace({
  query: {
    ...route.query,
    open_modal: "EditTeam",
  },
});
const rules = {
  name: { required },
  shortcode: { required },
  coach_email: { email },
  coach_phone: { numeric },
  amount_paid: { numeric },
  color: { required },
};

if (!team.value.color) {
  team.value.color = uniqolor(team.value.name).color;
}

function setColor() {
  team.value.color = uniqolor(team.value.name).color;
}

const v$ = useVuelidate(rules, team);

const teams = computed(() => {
  const t = leagueStore.divisions.filter(
    (division) => division.division_name === props.division
  )[0].teams;

  return t.filter((team) => team.id != props.team.id);
});

async function saveTeam() {
  if (mergeTeam.value) {
    await showAlert({
      header: "Teams will be merged irreversibly. Are you sure ?",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          handler: () => {
            console.log("cancel");
          },
        },
        {
          text: "OK",
          role: "confirm",
          handler: async () => {
            await mergeTeams(props.team.id, mergeTeam.value);
          },
        },
      ],
    });
  } else {
    const isFormValid = await v$.value.$validate();
    if (isFormValid) {
      await saveTeamRequest();
    } else {
      showToast("Invalid Input!", "danger", 6000);
    }
  }
}

async function mergeTeams(team, mergeToTeam) {
  const loading = await showLoading("Merging Teams. Please Wait...");
  try {
    await api.post("/api/merge-teams", {
      event: leagueStore.league.id,
      team,
      mergeToTeam,
    });
    loading.dismiss();
    const slug = leagueStore.league.slug;
    await leagueStore.reloadEverything(slug);
    await close();
    showToast("Teams successfully merged.", "success", 6000);
  } catch (err) {
    console.log(err);
    loading.dismiss();
    showToast(
      "Something went wrong. If this persists, please contact us.",
      "danger",
      6000
    );
  }
}

async function saveTeamRequest() {
  const loading = await showLoading("Updating Teams. Please Wait...");
  const result = await api.post("/api/save-team", {
    event: leagueStore.league.id,
    team: team.value,
  });
  loading.dismiss();

  if (result.data.type === "success") {
    const slug = leagueStore.league.slug;

    await leagueStore.reloadEverything(slug);
    await close();
    showToast("Team successfully updated.", "success", 6000);
  } else {
    showToast(
      "Something went wrong. If this persists, please contact us.",
      "danger",
      6000
    );
  }
}

async function confirmDelete() {
  await showAlert({
    header: "Are you sure you want to delete this team ?",
    buttons: [
      {
        text: "Cancel",
        role: "cancel",
        handler: () => {
          console.log("cancel");
        },
      },
      {
        text: "OK",
        role: "confirm",
        handler: async () => {
          const loading = await showLoading(
            "Deleting in progress. Please Wait..."
          );
          await sendDeleteRequest();
          loading.dismiss();
        },
      },
    ],
  });
}

async function sendDeleteRequest() {
  const result = await api.post("/api/delete-team", {
    event: leagueStore.league.id,
    team: team.value,
  });

  if (result.data.success) {
    await leagueStore.reloadEverything(leagueStore.league.slug);
    await close();
    showToast("Team Deleted.", "success", 6000);
  } else {
    showToast(
      "Something went wrong. If this persists, please contact us.",
      "danger",
      6000
    );
  }
}

async function close() {
  router.replace({
    query: {
      ...route.query,
      open_modal: "",
    },
  });
  await modalController.dismiss();
}
</script>
<style scoped>
.teams-input {
  border-bottom: 3px solid var(--ion-color-medium);
  text-align: center;
}
.color-swatch {
  margin-top: 0.5rem;
}
@media only screen and (max-width: 600px) {
  .color-swatch {
    margin-left: 2.5rem;
    margin-top: 1rem;
  }
}
</style>
