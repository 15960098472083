<template>
  <base-modal :modal-title="`Welcome to Slo-Pitch Central Tutorial`">
    <!-- <template #headerStart>
      <ion-button @click="close"> Close </ion-button>
    </template> -->
    <template #footer>
      <ion-button expand="full" @click="close">Next</ion-button>
    </template>
  </base-modal>
</template>

<script setup>
import { defineComponent } from "vue";
import { IonButton, modalController } from "@ionic/vue";
import BaseModal from "@/components/layouts/BaseModal.vue";
//   import { useLeagueStore } from "@/stores/leagueStore";
//   import { usePreferenceStore } from "@/stores/preferenceStore";

defineComponent({
  name: "InitialModal",
});
//   const leagueStore = useLeagueStore();
//   const preferenceStore = usePreferenceStore();

function close() {
  // preferenceStore.setWelcomeModalsDismissed();
  modalController.dismiss();
}
</script>
