<!-- DateTimePicker.vue -->
<template>
    <div>
      <ion-datetime
        ref="datetimeRef"
        :presentation="presentation"
        :value="modelValue"
        @ionChange="onDateTimeChange"
      >
      </ion-datetime>
      <div class="button-container">
        <ion-button size="small" color="danger"  @click="cancel">Cancel</ion-button>
        <ion-button size="small" color="success" @click="confirm" strong>Confirm</ion-button>
      </div>
    </div>
  </template>
  
  <script setup>
  import { IonDatetime, IonButton, modalController } from '@ionic/vue';
  import { ref } from 'vue';
  import { defineProps, defineEmits } from 'vue';
  
  const props = defineProps({
    modelValue: String,
    presentation: String
  });
  
  const emit = defineEmits(['update:modelValue']);
  
  const datetimeRef = ref(null);
  const newValue = ref(props.modelValue);
  
  function onDateTimeChange(event) {
    newValue.value = event.detail.value;
    // console.log('Date/time changed:', newValue.value);
  }
  
  function cancel() {
    // console.log('Cancel clicked');
    modalController.dismiss(null, 'cancel');
  }
  
  function confirm() {
    console.log('Confirm clicked, new value:', newValue.value);
    emit('update:modelValue', newValue.value);
    modalController.dismiss(newValue.value, 'confirm');
  }
  </script>
  
  <style scoped>
  .button-container {
    display: flex;
    justify-content: space-between;
    padding: 10px;
  }
  </style>