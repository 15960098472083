<template>
  <div class="nav-wrapper">
  <ion-toolbar>
     
    <ion-buttons slot="start" v-if="gameStore.isPrevDateAvail">
      <ion-button @click="gameStore.setPreviousDate">
        <ion-icon slot="icon-only" :icon="chevronBackOutline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      <div style="display: flex; justify-content: center">
        <ion-select
          :interface-options="alertOptions"
          v-model="gameStore.currentShownDate"
        >
          <ion-select-option value="all">All</ion-select-option>
          <ion-select-option value="next">Next Event Date</ion-select-option>
          <ion-select-option
            v-for="date in gameStore.availableDates"
            :key="date"
            :value="date"
            >{{
              date !== "null"
                ? format(new Date(date.replace(/-/g, "/")), "EEEE, MMM do")
                : "TBA"
            }}</ion-select-option
          >
        </ion-select>
      </div>
    </ion-title>
    <ion-buttons slot="end" v-if="gameStore.isNextDateAvail">
      <ion-button @click="gameStore.setNextDate">
        <ion-icon slot="icon-only" :icon="chevronForwardOutline"></ion-icon> 
      </ion-button>
    </ion-buttons>
  
  </ion-toolbar>
</div>
</template>

<script setup>
import { defineComponent, watch, computed } from "vue";
import {
  IonToolbar,
  IonButtons,
  IonTitle,
  IonButton,
  IonIcon,
  IonSelect,
  IonSelectOption,
} from "@ionic/vue";
// import { caretBackOutline, caretForwardOutline } from "ionicons/icons";
import { chevronBackOutline, chevronForwardOutline } from "ionicons/icons";
import { useGameStore } from "@/stores/gameStore";
import { usePreferenceStore } from "@/stores/preferenceStore";
import { format } from "date-fns";
import { useAdStore } from '@/stores/adStore';

const adStore = useAdStore();

defineComponent({
  name: "DateNavToolbar",
});
const preferenceStore = usePreferenceStore();
const gameStore = useGameStore();
const alertOptions = {
  header: "Available Dates",
};

watch(
  computed(() => gameStore.currentShownDate),
  (newDate) => {
    if (newDate === "next") {
      gameStore.currentShownDate = gameStore.nearestDate;
    }
    preferenceStore.setCurrentShownDates(newDate);
    adStore.triggerAdRefresh(); // Trigger ad refresh when date changes
  }
);
</script>

<style scoped>
  .nav-wrapper {
    display: flex;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
  }
</style>