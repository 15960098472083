<template>
    <page-wrapper>
        <span style="margin-top: 2.8rem"></span>
        <div
        style="
            display: flex;
            width: 100%;
            height: 100%;
        "
        >
        <div style="flex: 100%">
            <ion-content
            :style="{
                '--keyboard-offset': keyboardHeight + 'px',
                '--background': '#00000000',
            }"
            >
                <player-dashboard></player-dashboard>
            </ion-content>
        </div>
        </div>
    </page-wrapper>
</template>

<script setup>
import {
//   IonPage,
  IonContent,
//   IonSegment,
//   IonSegmentButton,
  useKeyboard,
//   IonCard,
//   IonAccordionGroup,
//   IonAccordion,
//   IonItem,
//   IonIcon,
//   IonLabel,
//   IonCardContent,
//   IonGrid,
//   IonRow,
//   IonCol,
//   IonButton,
//   IonCardHeader,
//   IonCardTitle,
//   IonCardSubtitle,
//   IonInput,
  onIonViewWillEnter,
} from "@ionic/vue";
import PageWrapper from "@/components/layouts/PageWrapper.vue";
// import AdminActions from "../components/info/AdminActions.vue";
import PlayerDashboard from "../components/player-dashboard/PlayerDashboard.vue";
import { useLeagueStore } from "@/stores/leagueStore";
// import { checkboxOutline, squareOutline } from "ionicons/icons";
import { useRoute } from "vue-router";
import { useRouter } from "vue-router";
import { ref } from "vue";

import { useAuthStore } from "@/stores/authStore";
import { showToast } from "@/utils/useIonicComponents.js";

import { api } from "@/utils/axios";
const route = useRoute();
const router = useRouter();
const { keyboardHeight } = useKeyboard();
const authStore = useAuthStore();

const setupComplete = ref(false);
const appearanceComplete = ref(false);
const rulesComplete = ref(false);
const announcementComplete = ref(false);

const leagueSlug = route.params.leagueSlug;
//   const expandedAccordion = ref('setupGuide');


const leagueStore = useLeagueStore();


const fetchAchievements = async () => {
  const leagueSlug = route.params.leagueSlug;
  if (leagueSlug) {
    try {
      const { data } = await api.get(`/api/get-achievements/${leagueSlug}`);
      const achievements = data.achievements;
      setupComplete.value = achievements.games_count > 0;
      appearanceComplete.value =
        achievements.appearance_check.logo &&
        achievements.appearance_check.colours;
      rulesComplete.value = achievements.rules_count > 0;
      announcementComplete.value = achievements.announcements_count > 0;
    } catch (error) {
      console.error("Error fetching achievements:", error);
      showToast("Failed to fetch achievements", "danger", 2000);
    }
  }
};


onIonViewWillEnter(() => {
  fetchAchievements();
});
// watch(() => route.params.leagueSlug, fetchAchievements);

if (leagueStore.league.hide_player_dashboard === 1 && !authStore.isConvenor) {
  // redirect to schedule page
  router.push(`/league/${leagueSlug}/schedule`);
}

</script>

<style scoped>
h5 {
  font-size: 15px;
}
</style>
