<template>
  <ion-row  class="ion-no-margin ion-no-padding">
    <ion-col class="ion-no-margin ion-no-padding" v-if="route.name !== 'home'">
      <span >
        <menu-item-only-component
          title="Home"
          :iosIcon="homeOutline"
          :mdIcon="homeSharp"
          @click="navigateTo('/')"
        />
      </span>
    </ion-col>
    <ion-col class="ion-no-margin ion-no-padding">
      <menu-item-only-component
        title="Share Link"
        :iosIcon="shareSocialOutline"
        :mdIcon="shareSocialSharp"
        @click="copyLink"
        color="primary"
        iconColor="dark"
        v-if="route.params.leagueSlug"
      />
    </ion-col>
  </ion-row>


  <span v-if="!route.params.leagueSlug">
    <menu-item-only-component
      title="Find an Event"
      :iosIcon="searchCircleOutline"
      :mdIcon="searchCircleSharp"
      href="#find"
    />
    <menu-item-only-component
      title="Major Events"
      :iosIcon="starOutline"
      :mdIcon="starSharp"
      href="/major-events"
    />
    <menu-item-only-component
      title="Featured Events"
      :iosIcon="starOutline"
      :mdIcon="starSharp"
      href="#featured"
    />
    <menu-item-only-component
      title="Ballpark Directory"
      :iosIcon="listCircleOutline"
      :mdIcon="listCircleSharp"
      href="/ballparks"
    />
    <menu-item-only-component
      title="Demos"
      :iosIcon="listCircleOutline"
      :mdIcon="listCircleSharp"
      href="/demos"
    />
    <menu-item-only-component
      title="Blog"
      :iosIcon="listCircleOutline"
      :mdIcon="listCircleSharp"
      href="/posts"
    />
    <menu-item-only-component
      title="FAQ"
      :iosIcon="helpCircleOutline"
      :mdIcon="helpCircleSharp"
      href="#faq"
    />
    <menu-item-only-component
      title="Contact"
      :iosIcon="mailOutline"
      :mdIcon="mailSharp"
      href="#contact"
    />
  </span>
  <div class="hr">Games</div>
  <menu-item-only-component
    title="Add Games"
    :iosIcon="addOutline"
    :mdIcon="addSharp"
    @click="addGame"
    v-if="authStore.isConvenor && route.params.leagueSlug"
  />
  <menu-item-only-component
    title="Import Your Schedule"
    :iosIcon="cloudUploadOutline"
    :mdIcon="cloudUploadSharp"
    @click="uploadCSV"
    v-if="authStore.isConvenor && route.params.leagueSlug"
  />
  <menu-item-only-component
    title="Toggle Games Layout"
    :iosIcon="toggleOutline"
    :mdIcon="toggleSharp"
    @click="toggleGamesLayout"
    v-if="!authStore.isConvenor && route.params.leagueSlug"
  />
  <menu-item-only-component
    title="Filter Schedule"
    :iosIcon="filterCircleOutline"
    :mdIcon="filterCircleSharp"
    @click="openFilters"
    v-if="!authStore.isConvenor && route.params.leagueSlug"
  />
  <menu-item-only-component
    title="BETA IMPORTER"
    :iosIcon="cloudUploadOutline"
    :mdIcon="cloudUploadSharp"
    @click="uploadCSVv2"
    v-if="authStore.isAdmin && route.params.leagueSlug"
  />

  <div class="hr" v-if="authStore.isConvenor && route.params.leagueSlug">
    Downloads
  </div>
  <ion-row>
    <ion-col class="ion-no-padding" size="4">
      <ion-button
        expand="full"
        size="small"
        @click="generatePDF"
        :disabled="isGeneratingSchedulePDF"
        v-if="gameStore.games?.length > 0 && route.params.leagueSlug"
      >
        <template v-if="!isGeneratingSchedulePDF">
          <!-- <ion-icon :icon="cloudDownloadOutline" slot="start"></ion-icon> -->
          Schedule PDF
        </template>
        <ion-spinner v-else name="dots"></ion-spinner>
      </ion-button>
    </ion-col>
    <ion-col class="ion-no-padding" size="4">
      <ion-button
        expand="full"
        size="small"
        @click="downloadCSV"
        v-if="gameStore.games?.length > 0 && route.params.leagueSlug"
      >
        <!-- <ion-icon :icon="cloudDownloadOutline" slot="start"></ion-icon> -->
        Schedule CSV
      </ion-button>
    </ion-col>
    <ion-col class="ion-no-padding" size="4">
      <ion-button
        size="small"
        expand="full"
        @click="generateResultsPDF"
        :disabled="isGeneratingResultsPDF"
        v-if="gameStore.games?.length > 0 && route.params.leagueSlug"
      >
        <template v-if="!isGeneratingResultsPDF">
          <!-- <ion-icon :icon="cloudDownloadOutline" slot="start"></ion-icon> -->
          Results PDF
        </template>
        <ion-spinner v-else name="dots"></ion-spinner>
      </ion-button>
    </ion-col>
  </ion-row>
  
</template>
<script setup>
import { defineComponent, ref, nextTick } from "vue";
import MenuItemOnlyComponent from "@/components/drawer/MenuItemOnlyComponent.vue";
import {
  addOutline,
  addSharp,
  // cloudDownloadOutline,
  cloudUploadOutline,
  cloudUploadSharp,
  shareSocialOutline,
  shareSocialSharp,
  homeOutline,
  homeSharp,
  toggleOutline,
  toggleSharp,
  searchCircleOutline,
  searchCircleSharp,
  starOutline,
  starSharp,
  listCircleOutline,
  listCircleSharp,
  helpCircleOutline,
  helpCircleSharp,
  mailOutline,
  mailSharp,
  // syncCircleOutline,
  filterCircleSharp,
  filterCircleOutline
} from "ionicons/icons";
import { useGameStore } from "@/stores/gameStore";
import { useAuthStore } from "@/stores/authStore";
import { useRoute, useRouter } from "vue-router";
import { openModal, showToast } from "@/utils/useIonicComponents.js";
import AddGameModal from "@/components/schedule/new-game/AddGameModal.vue";
import UploadScheduleModal from "@/components/schedule/upload-csv/UploadScheduleModal.vue";
import UploadScheduleModalv2 from "@/components/schedule/upload-csv/v2/UploadScheduleModalv2.vue";
import FilterModal from "@/components/schedule/filters/FilterModal.vue";
import { IonButton, IonSpinner, IonRow, IonCol } from "@ionic/vue";

import { usePreferenceStore } from "@/stores/preferenceStore";

defineComponent({
  name: "SideMenu",
});
const router = useRouter();
const route = useRoute();
const preferenceStore = usePreferenceStore();

const authStore = useAuthStore();
const gameStore = useGameStore();

async function navigateTo(location) {
  // await menuController.close();
  router.push(location);
}

async function addGame() {
  await openModal(AddGameModal, "addGameModal");
}

async function openFilters() {
  await openModal(FilterModal, "filterModal");
}

const isGeneratingSchedulePDF = ref(false);
const isGeneratingResultsPDF = ref(false);

async function generatePDF() {
  console.log('generatePDF started');
  isGeneratingSchedulePDF.value = true;
  console.log('isGeneratingSchedulePDF set to true');
  
  await nextTick();
  
  try {
    console.log('Starting PDF generation');
    
    // Add a forced delay to simulate a longer process
    await new Promise(resolve => setTimeout(resolve, 2000));
    
    console.log('Calling gameStore.downloadPDF()');
    await gameStore.downloadPDF();
    console.log('gameStore.downloadPDF() completed');
  } catch (error) {
    console.error('Error generating schedule PDF:', error);
  } finally {
    console.log('Entering finally block');
    await new Promise(resolve => setTimeout(resolve, 1000)); // Add a delay before setting to false
    isGeneratingSchedulePDF.value = false;
    console.log('isGeneratingSchedulePDF set to false');
  }
}

async function generateResultsPDF() {
  console.log('generateResultsPDF started');
  isGeneratingResultsPDF.value = true;
  console.log('isGeneratingResultsPDF set to true');
  
  await nextTick();
  
  try {
    console.log('Starting Results PDF generation');
    
    // Add a forced delay to simulate a longer process
    await new Promise(resolve => setTimeout(resolve, 2000));
    
    console.log('Calling gameStore.downloadResultsPDF()');
    await gameStore.downloadResultsPDF();
    console.log('gameStore.downloadResultsPDF() completed');
  } catch (error) {
    console.error('Error generating results PDF:', error);
  } finally {
    console.log('Entering finally block');
    await new Promise(resolve => setTimeout(resolve, 1000)); // Add a delay before setting to false
    isGeneratingResultsPDF.value = false;
    console.log('isGeneratingResultsPDF set to false');
  }
}


function downloadCSV() {
  gameStore.downloadCSV();
}

async function uploadCSV() {
  await openModal(UploadScheduleModal, "uploadScheduleModal");
}

async function uploadCSVv2() {
  await openModal(UploadScheduleModalv2, "uploadScheduleModalv2");
}

async function copyLink() {
  await navigator.clipboard.writeText(window.location.href);
  showToast("Link Copied", "primary", 1000);
}

function toggleGamesLayout() {
  const currentLayout = preferenceStore.getGamesLayout;
  const nextLayout = currentLayout === "grid" ? "table" : (currentLayout === "table" ? "brackets" : "grid");
  preferenceStore.setGamesLayout(nextLayout);
}


</script>

<style scoped>
.hr {
  padding: 5px;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  opacity: 0.4;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  animation: spin 1s linear infinite;
}

ion-spinner {
  width: 20px;
  height: 20px;
}

</style>
