<template>
  <base-modal modal-title="Add New Announcement" @close="close(false)">
    <!-- <template #headerStart>
      <ion-button @click="close(false)"> Close </ion-button>
    </template> -->
    <ion-item>
      <ion-label position="stacked">Title</ion-label>
      <ion-input v-model="announcement.title"></ion-input>
    </ion-item>
    <QuillEditor
      theme="snow"
      toolbar="full"
      :modules="modules"
      v-model:content="announcement.description"
      @ready="setContent($event)"
    />
    <template #footer>
      <ion-button expand="full" @click="saveAnnouncement"
        >Add Announcement</ion-button
      >
    </template>
  </base-modal>
</template>
<script setup>
import BaseModal from "@/components/layouts/BaseModal.vue";
import {
  IonInput,
  IonItem,
  IonLabel,
  IonButton,
  modalController,
} from "@ionic/vue";
import { defineComponent, ref, onMounted } from "vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { showToast, showLoading } from "@/utils/useIonicComponents.js";
import { api } from "@/utils/axios";
import { useLeagueStore } from "@/stores/leagueStore";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import BlotFormatter from "quill-blot-formatter";
import ImageUploader from "quill-image-uploader";
import { useRoute, useRouter } from "vue-router";
import { usePreferenceStore } from "@/stores/preferenceStore";

defineComponent({
  name: "NewAnnouncementModal",
});
const announcement = ref({});
const leagueStore = useLeagueStore();
const preferenceStore = usePreferenceStore();
const saveInterval = ref();

onMounted(async () => {
  announcement.value.title = preferenceStore.getUserAnnouncement.title;
  saveInterval.value = setInterval(localSave, 2500);
});
function localSave() {
  // console.log("autosaved", announcement.value);
  preferenceStore.setNewAnnouncement(announcement.value);
}
function setContent(event) {
  event.setContents(preferenceStore.getUserAnnouncement.description, "api");
}

const modules = [
  {
    name: "blotFormatter",
    module: BlotFormatter,
  },
  {
    name: "imageUploader",
    module: ImageUploader,
    options: {
      upload: (file) => {
        return new Promise((resolve, reject) => {
          const access_key = "6720c8ce-1e73-4f38-be0c52815ece-593f-4260";
          const base_url = "https://la.storage.bunnycdn.com";
          const storageName = "blacktiecollab-la";
          const path = "/slopitchcentral/Announcement_Media";
          const file_name = `${leagueStore.league.id}-${file.name}`;
          const cdn_url = "cdn.blacktiecollab.com";

          fetch(`${base_url}/${storageName}${path}/${file_name}`, {
            method: "PUT",
            headers: {
              AccessKey: `${access_key}`,
            },
            body: file,
          })
            .then(() => {
              resolve(`https://${cdn_url}${path}/${file_name}`);
            })
            .catch((err) => {
              reject("Upload failed");
              console.log(err);
            });
        });
      },
    },
  },
];

const rules = {
  title: { required },
  description: { required },
};

const v$ = useVuelidate(rules, announcement);

const router = useRouter();
const route = useRoute();
router.replace({
  query: {
    ...route.query,
    open_modal: "NewAnnouncements",
  },
});

async function saveAnnouncement() {
  const isFormValid = await v$.value.$validate();
  if (isFormValid) {
    await saveAnnouncementRequest();
  } else {
    showToast("Invalid Inputs!", "danger", 6000);
  }
}

async function saveAnnouncementRequest() {
  const loading = await showLoading("Saving Announcements. Please Wait...");
  const announce = {};
  announce.title = announcement.value.title;
  announce.description = JSON.stringify(announcement.value.description);
  const result = await api
    .post(`/api/events/${leagueStore.league.id}/announcements`, {
      announcement: announce,
    })
    .catch((err) => {
      console.log(err);
      showToast(
        "Something went wrong. If this persists, please contact us.",
        "danger",
        6000
      );
      loading.dismiss();
    });
  loading.dismiss();

  if (result.data.type === "success") {
    await leagueStore.reloadEverything(leagueStore.league.slug);
    announcement.value = {};
    localSave();
    await close(true);
    showToast("Announcement successfully Added.", "success", 6000);
  } else {
    showToast(
      "Something went wrong. If this persists, please contact us.",
      "danger",
      6000
    );
  }
}

async function close(success = false) {
  clearInterval(saveInterval.value);
  localSave();
  router.replace({
    query: {
      ...route.query,
      open_modal: "",
    },
  });
  await modalController.dismiss(success);
}
</script>
<style scoped>
.description {
  height: 20rem;
  width: 100%;
  margin-top: 1rem;
  padding: 1rem;
}
</style>
