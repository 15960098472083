<template>
  <page-wrapper>
    <div
      style="
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 0=;
        opacity: 15%;
        background-position: center;
        background-size: cover;
      "
      :style="{
        'background-image': `url('${leagueStore.leagueBackground}')`,
      }"
    ></div>
    <ion-segment
      :scrollable="true"
      style="margin-top: 4rem; height: 3rem"
      v-if="sortedStandings.length !== 0"
      @ion-change="segmentSelected"
      v-model="segment"
      mode="md"
    >
      <ion-segment-button
        class="division-title"
        :style="[
          {
            '--indicator-color': uniqolor(segment, {
              lightness: [25, 30],
            }).color,
            color:
              division.division_name === segment
                ? uniqolor(segment, {
                    lightness: [25, 30],
                  }).color
                : '',
          },
        ]"
        v-for="(division, key) in sortedStandings"
        :value="division.division_name"
        :key="key"
      >
        <ion-label>{{ division.division_name }}</ion-label>
      </ion-segment-button>
    </ion-segment>
    <div id="standingsPage1"></div>
    <standings-table
      v-if="divisionToShow"
      :division="divisionToShow"
    ></standings-table>
    <div id="standingsPage2"></div>
    <!-- <reset-standings-f-a-b v-if="authStore.isConvenor"></reset-standings-f-a-b> -->
    <no-data-message v-if="standings.length === 0">
      No standings available... yet!
    </no-data-message>
    <!-- <new-ad-component :key="divisionToShow" /> -->
    <div class="in-content"></div>
  </page-wrapper>
</template>

<script setup>
import { defineComponent, computed, ref } from "vue";
import { IonLabel, IonSegment, IonSegmentButton } from "@ionic/vue";
import PageWrapper from "@/components/layouts/PageWrapper.vue";
import { useLeagueStore } from "@/stores/leagueStore";
import { orderBy } from "lodash";
import StandingsTable from "@/components/standings/StandingsTable.vue";
import NoDataMessage from "@/components/layouts/NoDataMessage.vue";
import { usePreferenceStore } from "@/stores/preferenceStore";
import uniqolor from "uniqolor";
import { useHead } from "@unhead/vue";

defineComponent({
  name: "StandingsPage",
});

const leagueStore = useLeagueStore();
const preferenceStore = usePreferenceStore();

// Compute the page title
const pageTitle = computed(() => {
  return `Standings - ${leagueStore.leagueName} - ${leagueStore.leagueType}`
})

// Compute the page description
const pageDescription = computed(() => {
  return `View the current standings for ${leagueStore.leagueName}, a ${leagueStore.leagueType} event.`
})

// Use useHead to set the metadata
useHead({
  title: pageTitle,
  meta: [
    { name: 'description', content: pageDescription },
    { property: 'og:title', content: pageTitle },
    { property: 'og:description', content: pageDescription },
    { name: 'twitter:title', content: pageTitle },
    { name: 'twitter:description', content: pageDescription },
  ],
})

const standings = computed(() => {
  var divisions = leagueStore.divisions;

  // Function to calculate points and plus_minus
  function calculatePointsAndPlusMinus(team) {
    return {
      points:
        parseInt(team.wins) * parseInt(leagueStore.league.points_for_win) +
        parseInt(team.losses) * parseInt(leagueStore.league.points_for_loss) +
        parseInt(team.ties) * parseInt(leagueStore.league.points_for_tie),
      plus_minus: parseInt(team.runs_for) - parseInt(team.runs_against) + (team.max_run_diff_adjustments ? parseInt(team.max_run_diff_adjustments) : 0),
    };
  }

  // Function to update standings with calculated points and plus_minus
  function updateStandings(teamStandings, team) {
    return teamStandings.map((standing) => ({
      ...standing,
      name: team.name, // Get the name from the team object
      color: team.color, // Get the color from the team object
      ...calculatePointsAndPlusMinus(standing),
    }));
  }

  for (let i = 0; i < divisions.length; i++) {
    let division = divisions[i];
    let teams = division.teams;

    let practice_standings = [];
    let season_standings = [];
    let playoffs_standings = [];

    for (let t = 0; t < teams.length; t++) {
      const team = teams[t];
      const team_standings = team.standings;
      const team_practice_standings = team_standings.filter(
        (standing) => standing.mode === "Practice"
      );
      const team_season_standings = team_standings.filter(
        (standing) => standing.mode === "Season"
      );
      const team_playoffs_standings = team_standings.filter(
        (standing) => standing.mode === "Playoffs"
      );

      practice_standings = [
        ...practice_standings,
        ...updateStandings(team_practice_standings, team),
      ];
      season_standings = [
        ...season_standings,
        ...updateStandings(team_season_standings, team),
      ];
      playoffs_standings = [
        ...playoffs_standings,
        ...updateStandings(team_playoffs_standings, team),
      ];
    }

    // Sort the standings if needed
    division.practice_standings = orderBy(
      practice_standings,
      ["points", "wins", "losses", "plus_minus"],
      ["desc", "desc", "asc", "desc"]
    );
    division.season_standings = orderBy(
      season_standings,
      ["points", "wins", "losses", "plus_minus"],
      ["desc", "desc", "asc", "desc"]
    );
    division.playoffs_standings = orderBy(
      playoffs_standings,
      ["points", "wins", "losses", "plus_minus"],
      ["desc", "desc", "asc", "desc"]
    );
  }

  return divisions;
});

const sortedStandings = computed(() => {
  if (leagueStore.league.division_sort) {
    try {
      const sortOrder = JSON.parse(leagueStore.league.division_sort);
      if (Array.isArray(sortOrder) && sortOrder.length > 0) {
        // Create a map for quick lookup
        const orderMap = new Map(sortOrder.map((name, index) => [name, index]));
        
        // Sort the standings based on the division_sort order
        return [...standings.value].sort((a, b) => {
          const orderA = orderMap.has(a.division_name) ? orderMap.get(a.division_name) : Infinity;
          const orderB = orderMap.has(b.division_name) ? orderMap.get(b.division_name) : Infinity;
          return orderA - orderB;
        });
      }
    } catch (error) {
      console.error('Error parsing division_sort:', error);
    }
  }
  // If no valid division_sort, return the original standings order
  return standings.value;
});

const segment = ref(
  preferenceStore.userStandingsSegement || sortedStandings.value[0]?.division_name
);

const divisionToShow = computed(() => {
  return sortedStandings.value.find((div) => div.division_name === segment.value);
});

function segmentSelected(ev) {
  preferenceStore.setStandingsSegement(ev.detail.value);
}
</script>

<style scoped>
.division-title {
  font-weight: 500;
  text-transform: uppercase;
  font-size: large;
  max-width: unset;
}
@media only screen and (max-width: 960px) {
  .division-title {
    font-size: medium;
  }
}
</style>
